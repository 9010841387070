.pager {
    position: -webkit-sticky;
    position: sticky;
    top: 45px;
    bottom: 0;
    z-index: 10;
    align-self: flex-start;

    &__container {
        width: 320px;
        border: 1px solid rgba($color1, $alpha: 0.2);
        padding: 40px 0 40px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 1024px) { width: 275px; }
    }

    &__return, &__next {  
        font-size: 18px;
        font-family: $font1;
        font-weight: 500;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        margin-bottom: 40px;

        &:hover { color: $color3; }
    }

    &__next { padding-right: 15px; }

    &__left-arrow, &__right-arrow {
        fill: none;
        stroke: $color1;
        transition: stroke 300ms $easeInOutCubic;
        stroke-width: 5px;
        width: 15px;
        height: 12px;
    }

    &__return:not(.no-more-page):hover &__left-arrow { stroke: $color3; }
    &__next:not(.no-more-page):hover &__right-arrow { stroke: $color3; }

    &__left-arrow { 
        transform: rotate(180deg);
        margin-right: 10px;
    }

    &__right-arrow { margin-left: 10px; }

    &__btn { margin-left: 0!important; }

    .no-more-page { 
        color: #acacac; 
        svg { stroke: #acacac; }
        pointer-events: none;
    }
}