.overlay-calendar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	visibility: hidden;
    pointer-events: none;
    z-index: 20;

    &::-webkit-scrollbar { display: none; }

    &__close {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 100px;
        right: 50px;
        cursor: pointer;

        &:hover { &:before, &:after { background: #fff; }}

        &:before, &:after {
            content: '';
            position: absolute;
            width: 25px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: #fff;
            transition: background 300ms;
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg) ; }
        &:after { transform: translate(-50%, -50%) rotate(-45deg) ; }

        @media screen and (max-width: 768px) { right: 30px; }
        @media screen and (max-width: 600px) { right: 25px; }
    }

	&__container {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0.4);
        opacity: 0;
        text-align: center;
        z-index: 5;
        pointer-events: none;
        transition: opacity 800ms $easeOutQuart;
    }

    &__content {
        width: 100%;
        margin: 0 auto;
        opacity: 0;
        transform: translateY(100px);
        transition: transform 800ms $easeOutQuart, opacity 800ms $easeOutQuart;
        position: absolute;
        // top: 350px;
        top: 100px;

        // @media screen and (max-width: 500px) { top: 330px; }
    }
    
    &__calendar {
        padding: 30px 50px;

        @media screen and (max-width: 768px) { padding: 30px; }
        @media screen and (max-width: 600px) { padding: 30px 25px; }

        .day { height: 50px; }
    }
}
