.left-block {
    @media screen and (max-width: 768px) { display: none; }

    &__btn-container {  
        position: absolute;
        top: calc(var(--banner-height) - var(--header-height) - var(--alert-height));
        left: 80px;

        @media screen and (max-width: 1024px) { left: 50px; }
        @media screen and (max-width: 768px) { display: none; }
    }

    &__btn {
        background: #fff;
        color: $color2;
        transition: background 300ms $easeInOutCubic, color 300ms $easeInOutCubic;

        &:hover {
            background: $color2;
            color: #fff;
        }
    }

    &__btn-svg {
        fill: none;
        stroke: $color2;
        transition: stroke 300ms $easeInOutCubic;
        stroke-width: 5px;
        width: 15px;
        height: 12px;
        margin-left: 20px;
        transform: rotate(90deg);

        transition: transform $easeInOutCubic;

        animation-name: bounce;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }

    @keyframes bounce {
        0%  { transform: translateY(0) rotate(90deg); }
        50% { transform: translateY(25%) rotate(90deg); }
        100% { transform: translateY(0) rotate(90deg); }
    }

    &__btn:hover &__btn-svg { stroke: #fff; }

    &__container {
        width: var(--x-padding-400);
        padding-top: 45px;
        padding-left: 80px;
        display: flex;
        align-items: flex-start;
        position: absolute;
        top: var(--banner-height);
        bottom: 0;
        left: 0;

        @media screen and (max-width: 1024px) { padding-left: 25px; }
    }

    &__container--no-banner { 
        padding-top: 0;
        top: var(--y-padding-90); 
    }
}

.no-similar {
    min-height: 400px
}