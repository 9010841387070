.pagination {
    padding-left: var(--x-padding-400);

    &__container {
        @extend .x-padding-160;
        margin-bottom: var(--y-margin-110);
        display: flex;
        align-items: center;

        @media screen and (max-width: 900px) {  justify-content: center; }
        @media screen and (max-width: 768px) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }

    &__content { 
        display: flex;
        position: relative;
    }

    &__left-arrow, &__right-arrow {
        fill: none;
        stroke: #acacac;
        stroke-width: 4px;
        position: absolute;
        top: 50%;
        width: 30px;
        height: 20px;
    }

    &__left-arrow {
        left: 0;
        transform: translateY(-50%) rotate(180deg);
    }

    &__right-arrow {
        transform: translateY(-50%);
        right: 0;
    }

    &__numbers { 
        padding: 0 65px;
        display: flex;
    }

    &__number {
        font-size: 18px;
        font-family: $font1;
        font-weight: 700;
        color: #acacac;
        margin-right: 30px;
    }

    &__number--active { color: $color3; }

    &__number:last-child { margin-right: 0; }
}

.pagination-event {
    display: none;

    @media screen and (max-width: 768px) { display: block; }

    &__container {  
        margin-top: 50px;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width: 600px) { padding: 0 25px; }
    }

    &__return, &__next {  
        font-size: 18px;
        font-family: $font1;
        font-weight: 500;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        margin-bottom: 40px;

        &:hover { color: $color3; }
    }

    &__next { padding-right: 15px; }

    &__left-arrow, &__right-arrow {
        fill: none;
        stroke: $color1;
        transition: stroke 300ms $easeInOutCubic;
        stroke-width: 5px;
        width: 15px;
        height: 12px;
    }

    &__return:not(.no-more-page):hover &__left-arrow { stroke: $color3; }
    &__next:not(.no-more-page):hover &__right-arrow { stroke: $color3; }

    &__left-arrow { 
        transform: rotate(180deg);
        margin-right: 10px;
    }

    &__right-arrow { margin-left: 10px; }

    .no-more-page { 
        color: #acacac; 
        svg { stroke: #acacac; }
        pointer-events: none;
    }

    .larger { @media screen and (max-width: 500px) { display: none; } }
    .shorter { display: none; @media screen and (max-width: 500px) { display: block; } }

    &__btn-container { 
        display: flex;
        justify-content: center; 
        margin-bottom: 80px;
    }

    &__btn {
        display: none;

        @media screen and (max-width: 768px) { display: block; }
    }
}