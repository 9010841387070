:root {
    --text-block-height: 425px;

    @media screen and (max-height: 800px) { --text-block-height: 375px; }
    @media screen and (max-height: 700px) { --text-block-height: 275px; }
    @media screen and (max-height: 600px) { --text-block-height: 250px; }
}


.home-banner {
    &__container {
        position: relative;
        height: calc((var(--vh, 1vh) * 100) - var(--header-height));

        @media screen and (max-width: 900px) { height: auto; }
    }

    &__slider-container {
        padding-right: 360px;
        position: relative;

        @media screen and (max-width: 900px) { padding-right: 0; }
    }

    &__slider {
        margin-bottom: 0!important;
        height: 100%;

        .slick-slide { height: 0; }

        .slick-active { height: auto; }
    }

    &__slider-slide {
        height: calc((var(--vh, 1vh) * 100) - var(--header-height));
        min-height: calc(500px - var(--header-height));

        @media screen and (max-width: 900px) { height: calc((var(--vh, 1vh) * 100) - var(--header-height) - 200px); }
    }

    &__content {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        @media screen and (max-width: 900px) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }

    &__filter {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: rgba($color: #2b2b2b, $alpha: 0.4);
    }

    &__picture {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
    }

    &__text {
        padding-left: 90px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1024px) { padding-left: 50px; }

        @media screen and (max-width: 768px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @media screen and (max-width: 600px) {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &__title {
        @extend .huge-title;
        color: #fff;
        margin-bottom: 60px;
        padding-right: 50px;

        @media screen and (max-width: 768px) { padding-right: 0; margin-bottom: 30px; }
        @media screen and (max-width: 600px) { margin-bottom: 30px; }
        @media screen and (max-height: 768px) { margin-bottom: 30px;  }
    }

    &__btn {
        background: #fff;
        color: $color2;
        transition: background 300ms $easeInOutCubic, color 300ms $easeInOutCubic;

        &:hover {
            background: $color2;
            color: #fff;
        }
    }

    &__dots {
        position: absolute;
        bottom: 65px;
        left: 90px;

        @media screen and (max-width: 1024px) { left: 50px; }
        @media screen and (max-width: 768px) { left: 30px; }
        @media screen and (max-width: 600px) { left: 25px; }

        ul {
            display: flex;
            align-items: center;
            position: relative;
            bottom: auto;
        }

        li {
            width: 10px;
            height: 10px;
            background: #fff;
            border-radius: 50%;
            margin-right: 25px;

            button {
                &::before { display: none; }
            }

            @media screen and (max-width: 500px) {
                width: 6px;
                height: 6px;
            }
        }

        li:last-child() { margin-right: 0; }

        .slick-active {
            width: 20px;
            height: 20px;

            @media screen and (max-width: 500px) {
                width: 10px;
                height: 10px;
            }
        }
    }

    &__btn-fast-links {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
        margin-left: 0!important;
        position: absolute;
        right: 405px;
        bottom: 50px;

        @media screen and (max-width: 900px) { right: 50px; }
        @media screen and (max-width: 768px) { right: 30px; }
        @media screen and (max-width: 600px) { right: 25px; }
    }

    &__right-block {
        width: 360px;
        height: 100%;
        min-height: calc(500px - var(--header-height));
        position: absolute;
        top: 0;
        right: 0;

        @media screen and (max-width: 900px) {
            width: 100%;
            min-height: 0;
            position: relative;
            top: auto;
            right: auto;
        }
    }

    &__showcase-image-slider {

        .slick-list,
        .slick-track,
        .slick-slide {
            height: 100%!important;
        }

        .slick-slide > div {
            height: 100%!important;
        }
    }

    &__showcase-text-slider-container {
        width: 100%;
        position: relative;
    }

    &__showcase-text-slider {
        height: 100%;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 0!important;
    }

    &__showcase-image-slider {
        height: calc(100% - var(--text-block-height));

        @media screen and (max-width: 900px) { display: none; }
    }

    &__showcase-text-slide,
    &__showcase-image-slide {
        height: 100%;
        width: 100%;
    }

    &__link {
        width: 100%;
        height: 100%;
        display: block;
    }

    &__text-block {
        height: var(--text-block-height);
        padding: 80px 50px;
        display: flex;
        align-items: center;

        &--green-background { background: $color2; }
        &--light-blue-background { background: $color8; }
        &--marine-background { background: $color9; }
        &--orange-background { background: $color10; }

        @media screen and (max-height: 800px) { padding: 40px; }
        @media screen and (max-height: 700px) { padding: 35px 40px; }

        @media screen and (max-width: 900px) {
            height: 200px;
            padding: 35px 50px 40px;
        }
        @media screen and (max-width: 768px) { padding: 35px 30px 40px; }
        @media screen and (max-width: 600px) { padding: 35px 25px 40px; }
        @media screen and (max-width: 400px) { padding: 25px 25px 40px; }
    }

    &__text-block-content,
    .slickAnimate .home-banner__text-block-content {
        opacity: 0;
        transform: translateX(-50px);
        transition: opacity 700ms 450ms, transform 700ms 450ms;
    }

    .slick-active,
    .slickAnimate {
        .home-banner__text-block-content {
            opacity: 1;
            transform: none;
        }
    }

    &__medium-title {
        @extend .medium-title;
        color: #fff;
        margin-bottom: 25px;

        @media screen and (max-height: 800px) { margin-bottom: 15px; }
        @media screen and (max-height: 700px) { margin-bottom: 10px; }

        @media screen and (max-width: 900px) { margin-bottom: 15px; }
        @media screen and (max-width: 500px) { margin-bottom: 10px; }
    }

    &__btn-simple {
        color: #fff;

        &::after { background: #fff; }

        pointer-events: none;
    }

    &__right-block:hover &__btn-simple { &::after { width: 50%; } }

    &__right-block-picture {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__right-block-img { transition: transform 600ms $easeOut; }

    &__right-block:hover &__right-block-img { transform: scale(1.1); }

    &__showcase-dots {
        position: absolute;
        bottom: 30px;
        left: 50px;
        z-index: 9;

        &::after { // Prevent click box
            content: "";
            width: calc(100% + 40px);
            height: 50px;
            position: absolute;
            top: -18px;
            left: -17px;
            z-index: -1;

            @media screen and (max-width: 500px) {
                height: 30px;
                top: -10px;
            }
        }

        @media screen and (max-height: 800px) { bottom: 20px; }
        @media screen and (max-width: 900px) {
            right: 50px;
            left: auto;
        }
        @media screen and (max-width: 768px) { right: 30px; }
        @media screen and (max-width: 600px) { right: 25px; }

        ul {
            display: flex;
            align-items: center;
            position: relative;
            bottom: auto;
        }

        li {
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
            margin-right: 25px;

            button {
                &::before { display: none; }
            }

            @media screen and (max-width: 500px) {
                width: 6px;
                height: 6px;
            }
        }

        li:last-child() { margin-right: 0; }

        .slick-active {
            width: 14px;
            height: 14px;
            pointer-events: none;

            @media screen and (max-width: 500px) {
                width: 10px;
                height: 10px;
            }
        }

        .slick-dots li button:before { color: #fff; }
    }

    .slick-dots li button:before { color: #fff; }
}

.show-alerts {
    .home-banner__container { height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--alert-height)) }
    .home-banner__slider-slide { height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--alert-height)); }

    @media screen and (max-width: 900px) { .home-banner__container { height: auto; } }
    @media screen and (max-width: 900px) { .home-banner__slider-slide { height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--alert-height) - 200px); } }
}
