.fast-links {
    &__container { 
        @extend .x-padding-200; 
        @extend .top-padding-150;
        @extend .bottom-padding-180;
    }

    &__title { 
        width: 100%; 
        @extend .large-title;
        color: $color1;
        margin-bottom: 15px;
    }

    &__text {
        @include paragraph();
        margin-bottom: 80px;    

        @media screen and (max-width: 768px) { margin-bottom: 30px; }
    }

    &__links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: initial;
            flex-wrap: initial;
        }
    }

    &__link-container { 
        width: 500px;
        margin-bottom: 30px;

        @media screen and (max-width: 1400px) { width: 400px; }
        @media screen and (max-width: 1100px) { width: 350px; }
        @media screen and (max-width: 900px) { width: 325px; }
        @media screen and (max-width: 768px) { width: 100%; }
    }

    &__link-container:last-child(), &__link-container:nth-last-child(2) { 
        margin-bottom: 0; 

        @media screen and (max-width: 768px) { margin-bottom: 30px; }
    }

    &__link { 
        display: flex;
        align-items: center;
    }

    &__circle {
        width: 90px;
        height: 90px;
        min-width: 90px;
        min-height: 90px;
        border-radius: 50%;
        background: $color4;
        margin-right: 30px;
        transition: background 300ms $easeInOutCubic, margin-right 300ms $easeInOutCubic;
        position: relative;

        @media screen and (max-width: 1100px) {
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
        }

        @media screen and (max-width: 900px) {
            width: 70px;
            height: 70px;
            min-width: 70px;
            min-height: 70px;
        }

        @media screen and (max-width: 500px) {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
        }
    }

    &__link:hover &__circle { 
        background: $color2; 
        margin-right: 50px;
    }

    &__icon {
        width: 28px;
        height: 28px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: none;
        stroke: $color1;
        transition: stroke 300ms $easeInOutCubic;
        stroke-width: 1.5px;

        @media screen and (max-width: 500px) {
            width: 22px;
            height: 22px;
        }
    }

    &__link:hover &__icon { 
        stroke: #fff;
    }

    &__link-text {
        @extend .medium-title;
        color: $color1;
        font-weight: normal;
    }
}