.vitrine {
    position: relative;
    &__container {  
        display: block;
        padding-right: 200px; 
        margin: 140px 0 120px 0;
        
        @media screen and (max-width: 1000px) { padding-right: 100px; }
        @media screen and (max-width: 900px) { 
            padding-right: 0;
            display: flex;
            flex-direction: column;
        }
        @media screen and (max-width: 768px) { margin-top: 120px; }
    }

    &__left {
        padding: 130px 0 130px 130px;
        border: 1px solid $color7;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1200px) { padding: 100px 0 100px 100px; }
        @media screen and (max-width: 1000px) { padding: 50px 0 50px 50px; }
        @media screen and (max-width: 900px) { padding: 50px; }
        @media screen and (max-width: 768px) { padding: 40px; }
        @media screen and (max-width: 600px) { padding: 30px; }
        @media screen and (max-width: 500px) { padding: 25px; }
    }

    &__title {
        @extend .large-title;
        color: $color1;
        margin-bottom: 10px;
        max-width: 280px;

        @media screen and (max-width: 900px) { max-width: 100%; }
    }

    &__text {
        font-family: $font1;
        font-size: 18px;
        line-height: 1.5;
        color: $color1;
        margin-bottom: 45px;
        max-width: 280px;

        @media screen and (max-width: 900px) { max-width: 100%; }
    }

    &__btn { 
        margin-left: 0!important;
        align-self: flex-start;
        pointer-events: none;
    }

    &__container:hover &__btn { background: #bd7747; }

    &__right {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        @media screen and (max-width: 900px) { 
            position: relative;
            top: auto;
            right: auto;
            transform: translateY(0);
        }
    }

    &__picture {
        width: 640px;
        height: auto;
        overflow: hidden;

        @media screen and (max-width: 1300px) { width: 540px; }
        @media screen and (max-width: 1200px) { width: 440px; }
        @media screen and (max-width: 900px) { 
            width: 100%;
            height: 400px; 
        }
        @media screen and (max-width: 768px) { height: 300px; }
        @media screen and (max-width: 500px) { height: auto; }
    }

    &__img { transition: transform 600ms $easeOut; }

    &__container:hover &__img { transform: scale(1.1); }
}