// Documents normal et dans une rubrique
.documents {
    margin-top: 50px;
    margin-bottom: 65px;
    //& > :first-child() > a { padding-top: 0; }

    &__document-link {
        width: 100%;
        max-width: 920px;
        padding: 25px 90px 25px 20px;
        transition: padding 300ms $easeInOutCubic;
        border-bottom: 1px solid $color1;
        position: relative;

        @media screen and (max-width: 1024px) { padding-right: 80px; }
        @media screen and (max-width: 768px) { padding-right: 70px; }
        @media screen and (max-width: 600px) { padding-left: 0; }

        &:hover { padding-left: 40px; }
    }

    &__document-link--plus {
        display: flex;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__document-picture {  
        width: 60px;
        height: 60px;
        min-width: 60px;
        min-height: 60px;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__document-text { 
        padding-left: 30px;

        @media screen and (max-width: 600px) { padding-left: 0; }
    }

    &__document-surtitle {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.3;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    &__document-link:hover &__document-surtitle { color: $color2; }

    &__document-title { 
        @include paragraph();
        line-height: 1.2;
        transition: color 300ms $easeInOutCubic;
    }

    &__document-link:hover &__document-title { color: $color2; }

    &__document-icon {
        width: 14px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: var(--x-padding-40);
        transform: translateY(-50%);
    }

    &__document-svg {
        width: 100%;
        height: 100%;
        fill: $color1;
        transition: fill 300ms $easeInOutCubic;
    }

    &__document-link:hover &__document-svg { fill: $color2; }
}

// Galerie de documents
.document-gallery {
    @extend .bottom-margin-110; 
    & > :last-child() { border-bottom: 1px solid $color1; }

    &__document {
        display: block;
        position: relative;
        border-top: 1px solid $color1;
    }

    &__link {
        display: block;
        padding-top: 60px;
        padding-bottom: 60px;

        @media screen and (max-width: 1200px) { padding-top: 50px; padding-bottom: 50px; }
        @media screen and (max-width: 1024px) { padding-top: 40px; padding-bottom: 40px; }
        @media screen and (max-width: 600px)  { padding-top: 30px; padding-bottom: 30px; }
    }

    &__link--plus {
        display: flex;
        align-items: center;
        padding-top: 35px;
        padding-bottom: 35px;

        @media screen and (max-width: 600px) { padding-left: 0; }

        .document-gallery__title { padding-left: 0; }
    }

    &__picture {  
        width: 90px;
        height: 90px;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__text { 
        padding-left: 30px;
        padding-right: 90px;

        @media screen and (max-width: 1024px) { padding-right: 80px; }
        @media screen and (max-width: 768px) { padding-right: 70px; }
        @media screen and (max-width: 600px) { padding-left: 0; }
    }

    &__surtitle {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.3;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    &__link:hover &__surtitle { color: $color2; }

    &__title {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.2;
        color: $color1;
        transition: color 300ms $easeInOutCubic, padding 300ms $easeInOutCubic;
        @include responsiveFontSize(30, 20)
    }

    &__link:hover &__title { color: $color2; }

    &__icon {
        width: 18px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: var(--x-padding-40);
        transform: translateY(-50%);
    }

    &__icon--extern { width: 14px; }

    &__svg {
        width: 100%;
        height: 100%;
        fill: $color1;
        transition: fill 300ms $easeInOutCubic;
    }

    &__link:hover &__svg { fill: $color2; }
} 
