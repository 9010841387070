.field {
    position: relative;
    margin-bottom: 60px;

    @media screen and (max-width: 1024px) { margin-bottom: 50px; }
    @media screen and (max-width: 768px) { margin-bottom: 40px; }

    &--filter { margin-bottom: 0; }

    // Erreur
    &__error {
        margin-top: 5px;
        font: rem(13)/1 $font1;
        color: $color3;
    }

    // Input textarea et newsletter
    &--input &__input, &--textarea &__input, &--newsletter &__input {
        width: 100%;
        padding: 25px 20px;
        font: rem(17)/1.3 $font1;
        color: $color1;
        background: transparent;
        border: 1px solid $color7;
        transition: border 300ms;
    }

    &--input &__input {
        @media screen and (max-width: 768px) { padding: 20px 30px; }
        @media screen and (max-width: 500px) { padding: 15px 25px; }
    }

    &--textarea &__input {
        min-height: 150px;
    }

    .errorInput { 
        border: 1px solid $color3;
        color: $color3;
    } // Classe à ajouter s'il y a une erreur

    &--input &__label, &--textarea &__label, &--newsletter &__label {
        position: absolute;
        top: -20px;
        left: 0;
        transform: translateY(-50%);
        pointer-events: none;
        font: rem(14)/1.3 $font1;
        color: $color5;
        // transition: font-size 300ms, top 300ms $easeOutCubic;

        ::-webkit-input-placeholder {
            font-size: 17px;
            color: $color5;
        }
        ::-moz-placeholder {
          font-size: 17px;
          color: $color5;
        }
        :-ms-input-placeholder {
          font-size: 17px;
          color: $color5;
        }
        :-moz-placeholder {
          font-size: 17px;
          color: $color5;
        }
    }

    .errorTrue {
        // top: calc(50% - 9px);
        color: $color3;
    }

    // &--input &__input:focus ~ &__label, &--input[data-inputvalue]:not([data-inputvalue=""]) &__label,
    // &--textarea &__input:focus ~ &__label, &--textarea[data-textareavalue]:not([data-textareavalue=""]) &__label, 
    // &--newsletter &__input:focus ~ &__label, &--newsletter[data-inputvalue]:not([data-inputvalue=""]) &__label {
    //     font-size: rem(14);
    //     top: -20px;
    // }

    // Textarea
    &--textarea &__input {
        box-shadow: none;
        outline: none;
        resize: none;
        overflow: hidden;
    }


    // Checkbox et radio
    &--checkbox &__label, &--radio &__label {
        display: block;
		position: relative;
		padding-left: 35px;
        margin-bottom: 20px;
		cursor: pointer;
    }

    &--checkbox-inline &__label, &--radio-inline &__label {
        display: inline-block;
        margin: 0 30px 20px 0;
    }

    &--checkbox &__text, &--radio &__text {
        font: rem(17)/1.3 $font1;
        color: $color1;
        user-select: none;
    }

    &--checkbox &__input, &--radio &__input { position: absolute; opacity: 0; cursor: pointer; }
    &--checkbox &__input:checked ~ &__box, &--radio &__input:checked ~ &__box { background: $color2; }
    &--checkbox &__input:checked ~ &__box:before, &--radio &__input:checked ~ &__box:before { transform: translate(-50%, -50%) scale(1); }

    &--checkbox &__box, &--radio &__box {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 2px;
        left: 0;
        background: #fff;
        transition: background 225ms;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms $easeOutCubic;
        }
    }

    // Checkbox
    &--checkbox &__box {
        border: 1px solid $color2;
        &:before {
            width: 12px;
            height: 12px;
            background: url('../medias/images/icons/check.svg') no-repeat center / contain;
        }
    }


    // Radio
    &--radio &__box {
        border-radius: 50%;
        border: 1px solid $color2;

        &:before {
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
        }
    }


    // File
    &--file &__input { width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1; }

    &--file &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: 0 60px 20px 0;
        color: $color1;
        line-height: 1.6;
        font-size: 17px;
        font-family: $font1;
        border: none;
        border-bottom: 1px solid $color1;
        cursor: pointer;
        transition: color 300ms, background 300ms;

        &:hover {
            color: $color2;

            .field__svg { fill: $color2; }
        }
    }

    &--file &__svg {
        position: absolute;
        width: 14px;
        height: 16px;
        top: 2px;
        right: 5px;
        fill: $color1;
        transition: fill 300ms, opacity 300ms;
    }

    &--file &__x {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 2px;
        right: 5px;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transition: opacity 300ms, visibility 0ms ease 300ms;

        &:hover {
            &:before, &:after { background: $color2; }
        }
        
        &:before, &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: $color1;
            transition: background 300ms;

            @media screen and (max-width: 600px) { width: 20px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    }

    &--file[data-file]:not([data-file=""]) &__label { pointer-events: none; }
    &--file[data-file]:not([data-file=""]) &__svg   { opacity: 0; }
    &--file[data-file]:not([data-file=""]) &__x     { opacity: 1; visibility: visible; pointer-events: all; }


    // Select

    &--select &__label {
        display: block;
        font: rem(17)/1.3 $font1;
        font-weight: normal;
        color: $color1;
        padding-bottom: 10px;
    }

    &--select .tail-select {
        position: relative;
        z-index: 1;
    }

    &--select .select-label {
        border: 1px solid rgba($color1, $alpha: 0.2);
        padding: 25px 40px 25px 20px;
        transition: background 500ms $easeInOutCubic, border-color 500ms $easeInOutCubic, padding 500ms;
        cursor: pointer;
        
        &:hover { background: #fff; }

        @media screen and (max-width: 768px) { padding: 20px 30px; }
        @media screen and (max-width: 500px) { padding: 15px 25px; }
    }

    &--select .label-inner {
        display: block;
        position: relative;
        font: rem(17)/1.3 $font1;
        color: $color1;

        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 15px;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(90deg);
            background: url('../medias/images/icons/chevron-select.svg') no-repeat center / contain;
            transition: transform 500ms $easeInOutCubic, background 500ms;
        }
    }

    &--select .select-dropdown {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        opacity: 0;
        background: #fff;
        border: 1px solid $color7;
        border-top: none;
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition: top 500ms $easeInOutCubic, opacity 500ms $easeInOutCubic, visibility 0ms ease 500ms;
    }

    &--select .dropdown-inner {
        overflow: auto;
        max-height: 195px; 
    }

    &--select .dropdown-option {
        font: rem(15)/1.3 $font1;
        font-weight: 500;
        color: $color1;
        padding: 20px 50px 20px 20px;
        background: #fff;
        transition: color 500ms $easeInOutCubic, background 500ms $easeInOutCubic;
        cursor: pointer;

        &:hover { background: $color6; }

        &.selected { 
            position: relative;
            color: $color5;
        }
    }

    &--select .dropdown-option:last-child() { padding-bottom: 40px; }

    // Select natif
    &--select select {
        width: 100%; 
        padding: 25px 40px;
        font: rem(17)/1.3 $font1;
        color: $color1;
        border: 1px solid $color1;
        background: transparent;
        outline: none;
        -webkit-appearance: none;
        position: relative;
        background: url('../medias/images/icons/chevron-select-natif.svg') no-repeat calc(100% - 30px) 60% / 25px 15px;

        @media screen and (max-width: 768px) { padding: 20px 30px; }
        @media screen and (max-width: 500px) { padding: 15px 25px; }
    }

    select::-ms-expand { display: none; }


    // Recherche

    &--search {
        margin-bottom: 0;

        ::-webkit-input-placeholder { color: rgba($color1, 1); }
        ::-moz-placeholder { color: rgba($color1, 1); }
        :-ms-input-placeholder {  color: rgba($color1, 1); }
        :-moz-placeholder { color: rgba($color1, 1); }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus
        input:-webkit-autofill {
            -webkit-text-fill-color: $color1 !important;
            -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
            transition: background-color 5000s ease-in-out 0s;
        } 

        input:focus::placeholder { color: transparent; }
    }

    &--search-desktop { 
        width: 450px;
        position: relative;
        @media screen and (max-width: 900px) { display: none; } 
    }

    &--search-result { width: 100%; }

    &--search &__input {
        width: 100%;
        padding: 20px 0;
        border: none;
        border-bottom: 1px solid $color1;
        font-family: $font1;
        font-size: 18px;
        line-height: 1.5;
        color: $color1;
    }

    &--search-desktop &__input { padding: 20px 0 10px; }

    &--search[data-inputvalue]:not([data-inputvalue=""]) &__x {
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms;
    }
    
    &--search &__x {
        position: absolute;
        width: 15px;
        height: 15px;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        transition: opacity 300ms, visibility 0ms ease 300ms;

        @media screen and (max-width: 600px) {
            top: 25px;
            right: 50px;
            transform: none;
        }
        
        &:before, &:after {
            content: '';
            position: absolute;
            width: 15px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: $color1;

            @media screen and (max-width: 600px) { width: 20px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    }

    &--search-desktop &__x { top: 60%; }

    &--search &__search-btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: opacity 300ms $easeInOutCubic;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:hover { opacity: 0.5; }
        &:focus { outline:0; }
    }

    &--search-desktop &__search-btn { top: 60%; }
    
    &--search &__svg {
        width: 25px;
        height: 20px;
        fill: none;
        stroke: $color1;
        stroke-width: 2px;
    }

    // Newsletter
    &--newsletter {
        width: 50%;
        background: $color2;
        padding: 75px 95px;
        margin-bottom: 0;

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus
        input:-webkit-autofill {
            -webkit-text-fill-color: #fff !important;
            -webkit-box-shadow: 0 0 0px 1000px $color2 inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        input:focus::placeholder { color: transparent; }

        @media screen and (max-width: 1300px) {
            padding: 75px 60px;
        }

        @media screen and (max-width: 1100px) {
            padding: 75px 40px;
        }

        @media screen and (max-width: 1000px) {
            padding: 75px 25px;
        }

        @media screen and (max-width: 900px) {
            width: 100%;
            padding: 50px 120px;
        }

        @media screen and (max-width: 768px) {
            padding: 50px 30px;
        }

        @media screen and (max-width: 768px) {
            padding: 30px 25px;
        }
    }

    &--newsletter-title {
        @extend .medium-title;
        color: #fff;
        margin-bottom: 20px;
    }

    &--newsletter-form {
        position: relative;
        margin-bottom: 0;

        ::-webkit-input-placeholder { color: rgba(#fff, 1); }
        ::-moz-placeholder { color: rgba(#fff, 1); }
        :-ms-input-placeholder {  color: rgba(#fff, 1); }
        :-moz-placeholder { color: rgba(#fff, 1); }
    }

    &--newsletter-input {
        width: 100%;
        padding: 20px 0;
        background: transparent;
        border: none;
        border-bottom: 1px solid #fff;
        font-family: $font1;
        font-size: 18px;
        line-height: 1.5;
        color: #fff;
    }

    &--newsletter-icon {
        width: 10px;
        height: 14px;
        fill: none;
        stroke: #fff;
        stroke-width: 3px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        cursor: pointer;
    }
}