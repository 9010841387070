.page-title {
    &__container {
        padding-left: var(--x-padding-400);
        margin-top: -300px;

        @media screen and (max-width: 768px) { margin-top: 0; }
    }

    &__container--no-banner { margin-top: 0; }

    &__container--style {
        background: transparent;
        padding-left: var(--x-padding-260);
        padding-right: var(--x-padding-260);
        margin-top: 0;
        display: flex;
        flex-direction: column;

        @extend .top-padding-150;
    }

    &__content {
        display: flex;
        flex-direction: column;
        background: #fff;
        position: relative;
        z-index: -1;

        @extend .x-padding-160;
        @extend .top-padding-90;

        &::after {  
            content: "";
            width: 100%;
            height: 100px;
            background: #fff;
            position: absolute;
            bottom: -60px;
            left: 0;
        }
    }

    &__content--no-banner {
        &::after { display: none; }
    }

    &__eventinfos {
        padding-top: 10px;
        z-index: 2;
    }

    &__surtitle { 
        @include large-title();

        @include responsiveFontSize(50, 30);
    }

    &__title {
        color: $color1;

        @include huge-title();
    }

    &__title--news { margin-top: -15px; }

    &__title--green { color: $color2; }

    &__separator {
        width: 115px;
        height: 8px;
        background: $color2;
        margin: 40px 0;
    }
}