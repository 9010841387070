/*
|--------------------------------------------------------------------------
| PADDING X
|--------------------------------------------------------------------------
*/
:root {
    --x-padding-400: 400px;
    --x-padding-260: 15vw;
    --x-padding-240: 15vw;
    --x-padding-200: 15vw;
    --x-padding-180: 15vw;
    --x-padding-160: 15vw;
    --x-padding-120: 15vw;
    --x-padding-100: 15vw;
    --x-padding-80: 80px;
    --x-padding-70: 70px;
    --x-padding-50: 50px;
    --x-padding-40: 40px;
    
    @media screen and (max-width: 1600px) {
        --x-padding-260: 260px;
        --x-padding-240: 240px;
        --x-padding-200: 200px;
        --x-padding-180: 180px;
        --x-padding-160: 160px;
        --x-padding-120: 120px;
        --x-padding-100: 100px;
    }
    @media screen and (max-width: 1400px) {
        --x-padding-260: 220px;
        --x-padding-240: 200px;
        --x-padding-200: 180px;
        --x-padding-180: 160px;
        --x-padding-160: 130px;
    }
    @media screen and (max-width: 1200px) {
        --x-padding-260: 180px;
        --x-padding-240: 160px;
        --x-padding-200: 120px;
        --x-padding-180: 120px;
        --x-padding-160: 100px;
        --x-padding-120: 100px;
        --x-padding-100: 80px;
    }
    @media screen and (max-width: 1024px) {
        --x-padding-400: 300px;
        --x-padding-260: 50px;
        --x-padding-240: 50px;
        --x-padding-200: 50px;
        --x-padding-180: 50px;
        --x-padding-160: 50px;
        --x-padding-120: 50px;
        --x-padding-100: 50px;
        --x-padding-80: 50px;
        --x-padding-70: 50px;
    }
    @media screen and (max-width: 768px) {
        --x-padding-400: 0;
        --x-padding-260: 30px;
        --x-padding-240: 30px;
        --x-padding-200: 30px;
        --x-padding-180: 30px;
        --x-padding-160: 30px;
        --x-padding-120: 30px;
        --x-padding-100: 30px;
        --x-padding-80: 30px;
        --x-padding-70: 30px;
        --x-padding-50: 30px;
        --x-padding-40: 30px;
    }
    @media screen and (max-width: 600px) {
        --x-padding-260: 25px;
        --x-padding-240: 25px;
        --x-padding-200: 25px;
        --x-padding-180: 25px;
        --x-padding-160: 25px;
        --x-padding-120: 25px;
        --x-padding-100: 25px;
        --x-padding-80: 25px;
        --x-padding-70: 25px;
        --x-padding-50: 25px;
        --x-padding-40: 25px;
    }
    @media screen and (max-width: 320px) {
        --x-padding-50: 20px;
    }
}

.x-padding-400 { @extend .right-padding-400; @extend .left-padding-400; }
.right-padding-400 { padding-right: var(--x-padding-400); }
.left-padding-400 { padding-left:  var(--x-padding-400); }

.x-padding-260 { @extend .right-padding-260; @extend .left-padding-260; }
.right-padding-260 { padding-right: var(--x-padding-260); }
.left-padding-260 { padding-left:  var(--x-padding-260); }

.x-padding-240 { @extend .right-padding-240; @extend .left-padding-240; }
.right-padding-240 { padding-right: var(--x-padding-240); }
.left-padding-240 { padding-left:  var(--x-padding-240); }

.x-padding-200 { @extend .right-padding-200; @extend .left-padding-200; }
.right-padding-200 { padding-right: var(--x-padding-200); }
.left-padding-200 { padding-left:  var(--x-padding-200); }

.x-padding-180 { @extend .right-padding-180; @extend .left-padding-180; }
.right-padding-180 { padding-right: var(--x-padding-180); }
.left-padding-180 { padding-left:  var(--x-padding-180); }

.x-padding-160 { @extend .right-padding-160; @extend .left-padding-160; }
.right-padding-160 { padding-right: var(--x-padding-160); }
.left-padding-160 { padding-left:  var(--x-padding-160); }

.x-padding-120 { @extend .right-padding-120; @extend .left-padding-120; }
.right-padding-120 { padding-right: var(--x-padding-120); }
.left-padding-120 { padding-left:  var(--x-padding-120); }

.x-padding-100 { @extend .right-padding-100; @extend .left-padding-100; }
.right-padding-100 { padding-right: var(--x-padding-100); }
.left-padding-100 { padding-left:  var(--x-padding-100); }

.x-padding-80 { @extend .right-padding-80; @extend .left-padding-80; }
.right-padding-80 { padding-right: var(--x-padding-80); }
.left-padding-80 { padding-left:  var(--x-padding-80); }

.x-padding-70 { @extend .right-padding-70; @extend .left-padding-70; }
.right-padding-70 { padding-right: var(--x-padding-70); }
.left-padding-70 { padding-left:  var(--x-padding-70); }

.x-padding-50 { @extend .right-padding-50; @extend .left-padding-50; }
.right-padding-50 { padding-right: var(--x-padding-50); }
.left-padding-50 { padding-left:  var(--x-padding-50); }

.x-padding-40 { @extend .right-padding-40; @extend .left-padding-40; }
.right-padding-40 { padding-right: var(--x-padding-40); }
.left-padding-40 { padding-left:  var(--x-padding-40); }


/*
|--------------------------------------------------------------------------
| PADDING Y
|--------------------------------------------------------------------------
*/
:root {
    --y-padding-300: 300px;
    --y-padding-180: 180px;
    --y-padding-160: 160px;
    --y-padding-150: 150px;
    --y-padding-130: 130px;
    --y-padding-120: 120px;
    --y-padding-100: 100px;
    --y-padding-90: 90px;
    --y-padding-80: 80px;
    --y-padding-70: 70px;
    --y-padding-50: 50px;
    --y-padding-40: 40px;
    --y-padding-30: 30px;
    --y-padding-20: 20px;
    
    @media screen and (max-width: 1400px) {
        --y-padding-300: 240px;
        --y-padding-180: 160px;
        --y-padding-160: 140px;
        --y-padding-150: 130px;
        --y-padding-130: 120px;
        --y-padding-120: 110px;
    }
    @media screen and (max-width: 1200px) {
        --y-padding-300: 220px;
        --y-padding-180: 140px;
        --y-padding-160: 130px;
        --y-padding-150: 120px;
        --y-padding-130: 110px;
        --y-padding-120: 110px;
    }
    @media screen and (max-width: 1024px) {
        --y-padding-300: 200px;
        --y-padding-180: 130px;
        --y-padding-160: 120px;
        --y-padding-150: 110px;
        --y-padding-130: 100px;
        --y-padding-120: 100px;
        --y-padding-100: 90px;
        --y-padding-90:  80px;
        --y-padding-80:  70px;
        --y-padding-50:  40px;
    }
    @media screen and (max-width: 900px) {
        --y-padding-300: 0;
    }
    @media screen and (max-width: 768px)  {
        --y-padding-180: 100px;
        --y-padding-160: 100px;
        --y-padding-150: 90px;
        --y-padding-130: 80px;
        --y-padding-120: 80px;
        --y-padding-100: 80px;
        --y-padding-90:  70px;
        --y-padding-80:  60px;
        --y-padding-70:  40px;
        --y-padding-50:  30px;
        --y-padding-40:  30px;
    }
    @media screen and (max-width: 500px)  {
        --y-padding-180: 50px;
        --y-padding-160: 50px;
        --y-padding-150: 50px;
        --y-padding-130: 50px;
        --y-padding-120: 50px;
        --y-padding-100: 50px;
        --y-padding-90:  50px;
        --y-padding-80:  50px;
        --y-padding-70:  30px;
    }
}

.y-padding-300 { @extend .top-padding-300; @extend .bottom-padding-300; }
.top-padding-300 { padding-top: var(--y-padding-300); }
.bottom-padding-300 { padding-bottom: var(--y-padding-300); } 

.y-padding-180 { @extend .top-padding-180; @extend .bottom-padding-180; }
.top-padding-180 { padding-top: var(--y-padding-180); }
.bottom-padding-180 { padding-bottom: var(--y-padding-180); } 

.y-padding-160 { @extend .top-padding-160; @extend .bottom-padding-160; }
.top-padding-160 { padding-top: var(--y-padding-160); }
.bottom-padding-160 { padding-bottom: var(--y-padding-160); } 

.y-padding-150 { @extend .top-padding-150; @extend .bottom-padding-150; }
.top-padding-150 { padding-top: var(--y-padding-150); }
.bottom-padding-150 { padding-bottom: var(--y-padding-150); }

.y-padding-130 { @extend .top-padding-130; @extend .bottom-padding-130; }
.top-padding-130 { padding-top: var(--y-padding-130); }
.bottom-padding-130 { padding-bottom: var(--y-padding-130); }

.y-padding-120 { @extend .top-padding-120; @extend .bottom-padding-120; }
.top-padding-120 { padding-top: var(--y-padding-120); }
.bottom-padding-120 { padding-bottom: var(--y-padding-120); }

.y-padding-100 { @extend .top-padding-100; @extend .bottom-padding-100; }
.top-padding-100 { padding-top: var(--y-padding-100); }
.bottom-padding-100 { padding-bottom: var(--y-padding-100); }

.y-padding-90 { @extend .top-padding-90; @extend .bottom-padding-90; }
.top-padding-90 { padding-top: var(--y-padding-90); }
.bottom-padding-90 { padding-bottom: var(--y-padding-90); }

.y-padding-80 { @extend .top-padding-80; @extend .bottom-padding-80; }
.top-padding-80 { padding-top: var(--y-padding-80); }
.bottom-padding-80 { padding-bottom: var(--y-padding-80); }

.y-padding-70 { @extend .top-padding-70; @extend .bottom-padding-70; }
.top-padding-70 { padding-top: var(--y-padding-70); }
.bottom-padding-70 { padding-bottom: var(--y-padding-70); }

.y-padding-50 { @extend .top-padding-50; @extend .bottom-padding-50; }
.top-padding-50 { padding-top: var(--y-padding-50); }
.bottom-padding-50 { padding-bottom: var(--y-padding-50); }

.y-padding-40 { @extend .top-padding-40; @extend .bottom-padding-40; }
.top-padding-40 { padding-top: var(--y-padding-40); }
.bottom-padding-40 { padding-bottom: var(--y-padding-40); }

.y-padding-30 { @extend .top-padding-30; @extend .bottom-padding-30; }
.top-padding-30 { padding-top: var(--y-padding-30); }
.bottom-padding-30 { padding-bottom: var(--y-padding-30); }

.y-padding-20 { @extend .top-padding-20; @extend .bottom-padding-20; }
.top-padding-20 { padding-top: var(--y-padding-20); }
.bottom-padding-20 { padding-bottom: var(--y-padding-20); }




/*
|--------------------------------------------------------------------------
| margin Y
|--------------------------------------------------------------------------
*/
:root {
    --y-margin-210: 210px;
    --y-margin-190: 190px;
    --y-margin-160: 160px;
    --y-margin-110: 110px;
    --y-margin-90: 90px;
    --y-margin-70: 70px;
    --y-margin-65: 65px;
    --y-margin-60: 60px;
    --y-margin-50: 50px;
    --y-margin-40: 40px;
    --y-margin-30: 30px;
    --y-margin-20: 20px;

    @media screen and (max-width: 1400px) {
        --y-margin-210: 180px;
        --y-margin-190: 170px;
        --y-margin-160: 120px;
        --y-margin-110: 90px;
        --y-margin-90: 80px;
    }
    @media screen and (max-width: 1200px) {
        --y-margin-210: 120px;
        --y-margin-190: 100px;
        --y-margin-160: 90px;
        --y-margin-110: 80px;
        --y-margin-90: 70px;
        --y-margin-70: 60px;
        --y-margin-65: 60px;
        --y-margin-60: 50px;
    }
    @media screen and (max-width: 1024px) {
        --y-margin-210: 60px;
        --y-margin-190: 60px;
        --y-margin-160: 60px;
        --y-margin-110: 50px;
        --y-margin-90: 50px;
        --y-margin-70: 50px;
        --y-margin-65: 50px;
        --y-margin-60: 40px;
        --y-margin-50: 40px;
        --y-margin-40: 30px;
    }
    @media screen and (max-width: 768px)  {
        --y-margin-210: 50px;
        --y-margin-190: 50px;
        --y-margin-160: 50px;
        --y-margin-110: 40px;
        --y-margin-90: 40px;
        --y-margin-70: 40px;
        --y-margin-65: 40px;
        --y-margin-60: 30px;
        --y-margin-50: 30px;
        --y-margin-30: 25px;
        --y-margin-20: 15px;
    }
    @media screen and (max-width: 500px)  {
        --y-margin-210: 40px;
        --y-margin-190: 40px;
        --y-margin-160: 40px;
        --y-margin-110: 30px;
        --y-margin-90: 30px;
        --y-margin-70: 30px;
        --y-margin-65: 30px;
        --y-margin-60: 25px;
        --y-margin-50: 25px;
        --y-margin-40: 20px;
        --y-margin-30: 15px;
        --y-margin-20: 10px;
    }
}

.y-margin-210 { @extend .top-margin-210; @extend .bottom-margin-210; }
.top-margin-210 { margin-top: var(--y-margin-210); }
.bottom-margin-210 { margin-bottom: var(--y-margin-210); }

.y-margin-190 { @extend .top-margin-190; @extend .bottom-margin-190; }
.top-margin-190 { margin-top: var(--y-margin-190); }
.bottom-margin-190 { margin-bottom: var(--y-margin-190); }

.y-margin-160 { @extend .top-margin-160; @extend .bottom-margin-160; }
.top-margin-160 { margin-top: var(--y-margin-160); }
.bottom-margin-160 { margin-bottom: var(--y-margin-160); }

.y-margin-110 { @extend .top-margin-110; @extend .bottom-margin-110; }
.top-margin-110 { margin-top: var(--y-margin-110); }
.bottom-margin-110 { margin-bottom: var(--y-margin-110); }

.y-margin-90 { @extend .top-margin-90; @extend .bottom-margin-90; }
.top-margin-90 { margin-top: var(--y-margin-90); }
.bottom-margin-90 { margin-bottom: var(--y-margin-90); }

.y-margin-70 { @extend .top-margin-70; @extend .bottom-margin-70; }
.top-margin-70 { margin-top: var(--y-margin-70); }
.bottom-margin-70 { margin-bottom: var(--y-margin-70); }

.y-margin-65 { @extend .top-margin-65; @extend .bottom-margin-65; }
.top-margin-65 { margin-top: var(--y-margin-65); }
.bottom-margin-65 { margin-bottom: var(--y-margin-65); }

.y-margin-60 { @extend .top-margin-60; @extend .bottom-margin-60; }
.top-margin-60 { margin-top: var(--y-margin-60); }
.bottom-margin-60 { margin-bottom: var(--y-margin-60); }

.y-margin-50 { @extend .top-margin-50; @extend .bottom-margin-50; }
.top-margin-50 { margin-top: var(--y-margin-50); }
.bottom-margin-50 { margin-bottom: var(--y-margin-50); }

.y-margin-40 { @extend .top-margin-40; @extend .bottom-margin-40; }
.top-margin-40 { margin-top: var(--y-margin-40); }
.bottom-margin-40 { margin-bottom: var(--y-margin-40); }

.y-margin-30 { @extend .top-margin-30; @extend .bottom-margin-30; }
.top-margin-30 { margin-top: var(--y-margin-30); }
.bottom-margin-30 { margin-bottom: var(--y-margin-30); }

.y-margin-20 { @extend .top-margin-20; @extend .bottom-margin-20; }
.top-margin-20 { margin-top: var(--y-margin-20); }
.bottom-margin-20 { margin-bottom: var(--y-margin-20); }


/*
|--------------------------------------------------------------------------
| margin Y - init theme
|--------------------------------------------------------------------------
*/
:root{
    --y-100: 100px;
    --y-90: 90px;
    --y-65: 65px;
    --y-60: 60px;
    --y-50: 50px;
    --y-45: 45px;
    --y-40: 40px;
    --y-35: 35px;
    --y-30: 30px;
    --y-25: 25px;
    --y-20: 20px;

    @media screen and (max-width: 1440px){
        --y-100: calc(55px + (100 - 55) * ((100vw - 320px) / (1440 - 320)));
        --y-90: calc(50px + (90 - 50) * ((100vw - 600px) / (1440 - 600)));
        --y-65: calc(40px + (65 - 40) * ((100vw - 600px) / (1440 - 600)));
        --y-60: calc(40px + (60 - 40) * ((100vw - 600px) / (1440 - 600)));
        --y-50: calc(33px + (50 - 33) * ((100vw - 600px) / (1440 - 600)));
        --y-45: calc(29px + (45 - 29) * ((100vw - 600px) / (1440 - 600)));
        --y-40: calc(26px + (40 - 26) * ((100vw - 600px) / (1440 - 600)));
        --y-35: calc(23px + (35 - 23) * ((100vw - 600px) / (1440 - 600)));
        --y-30: calc(20px + (30 - 20) * ((100vw - 600px) / (1440 - 600)));
        --y-25: calc(17px + (25 - 17) * ((100vw - 600px) / (1440 - 600)));
        --y-20: calc(15px + (20 - 15) * ((100vw - 600px) / (1440 - 600)));
    }

    @media screen and (max-width: 600px){
        --y-100: 55px;
        --y-90: 50px;
        --y-65: 40px;
        --y-60: 40px;
        --y-50: 33px;
        --y-45: 29px;
        --y-40: 26px;
        --y-35: 23px;
        --y-30: 20px;
        --y-25: 17px;
        --y-20: 15px;
    }
}


/*
|--------------------------------------------------------------------------
| margin X - init theme
|--------------------------------------------------------------------------
*/
:root{
    --x-180: 180px;
    --x-40: 40px;

    @media screen and (max-width: 1440px){
        --x-180: calc(25px + (180 - 25) * ((100vw - 600px) / (1440 - 600)));
        --x-40: calc(25px + (40 - 25) * ((100vw - 600px) / (1440 - 600)));
    }

    @media screen and (max-width: 600px){
        --x-180: 25px;
        --x-40: 25px;
    }

    @media screen and (max-width: 320px){
        --x-180: 22px;
        --x-40: 22px;
    }
}

/*
|--------------------------------------------------------------------------
| IMAGES
|--------------------------------------------------------------------------
*/
picture {
    display: block;
    font-size: 0;

    img { width: 100%; height: 100%; }

    [data-object-fit='cover'] {
        object-fit: cover;
        font-family: 'object-fit: cover;';
    }
}