.overlay-menu {
    position: fixed;
    top: calc(var(--header-height));
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 10;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#000, 0);
        transition: background 800ms;
        z-index: -1;
    }

    &__container {
        position: absolute;
        width: 520px;
        padding: 60px 100px;
        top: 0;
        right: -500px;
        bottom: 0;
        background: #fff;
        overflow-y: auto;
		overflow-x: hidden;
        transition: right 800ms $easeInOutQuart;

        @media screen and (max-width: 600px) { 
            width: 100%; 
            right: -100%; 
            padding-right: var(--x-padding-70); 
            padding-left: var(--x-padding-70); 
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
            height: 5px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb{
            background-color: rgba($color: #000000, $alpha: 0.5);
            border-radius: 25px;
        }
    }

    &__main-level {
        opacity: 0;
        transform: translateX(200px);
        transition: opacity 1000ms $easeInOutQuart, transform 1000ms $easeInOutQuart; 
    }

    &__main-level-li {
        padding-bottom: 50px;
    }

    &__main-level-button { 
        padding-right: 30px;
        display: block;
        position: relative;
        pointer-events: none; 
    }
    
    &__main-level-title {
        font: rem(30)/1 $font1;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        
        @media screen and (max-width: 600px) { font-size: rem(26); }
        @media screen and (max-width: 425px) { font-size: rem(24); }
    }

    &__main-level-button:hover &__main-level-title { color: $color3; }

    &__main-level-svg, &__second-level-svg  {
        fill: none;
        stroke: $color1;
        transition: stroke 300ms $easeInOutCubic;
        stroke-width: 5px;
        position: absolute;
        top: 50%;
        right: 0;
        width: 10px;
        height: 15px;
        transform: translateY(-50%) rotate(90deg);
        transition: transform 700ms $easeInOutQuart;
    }

    &__main-level-svg {
        display: none;
    }

    &__second-level-svg { width: 14px; }

    &__main-level-button:hover &__main-level-svg { stroke: $color3; }

    &__second-level {
        height: 100%;
        overflow: hidden;
        opacity: 1;
        margin-top: 30px;
        transition: opacity 700ms $easeInOutQuart, margin 700ms $easeInOutQuart;
    }

    &__second-level-li { 
        padding: 15px 0;
        transition: padding 700ms $easeInOutQuart;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 1px;
            opacity: 1;
            transition: opacity 1200ms $easeInOutCubic;
            background: $color1;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    &__second-level-li:first-child { padding-top: 0; }

    &__second-level-button {
        display: block;
        font: rem(20)/1.5 $font1;
        color: $color1;
        padding-right: 30px;
        opacity: 1;
        transition: opacity 300ms $easeInOutCubic;
        position: relative;

        &:hover { opacity: 0.5; }
    }
    
    &__third-level {
        height: 0;
        overflow: hidden;
        opacity: 0;
        margin-top: 0px;
        transition: opacity 700ms $easeInOutQuart, margin 700ms $easeInOutQuart;
    }

    &__third-level-li { 
        padding-right: 30px;
        padding-bottom: 15px;
    }

    &__third-level-li:last-child { padding-bottom: 20px; }
    
    &__third-level-link {
        display: block;
        font: rem(16)/1.5 $font1;
        color: $color1;
        transition: padding 300ms $easeInOutCubic;

        @media screen and (max-width: 600px) { font-size: rem(16); }
        @media screen and (max-width: 425px) { font-size: rem(15); }

        &:hover { padding-left: 20px; }
    }
}

.show-alerts {
    .overlay-menu { top: calc(var(--header-height) + var(--alert-height)); }
}