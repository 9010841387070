/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
|
| Déclaration de tous les titres, textes, boutons et liens réutilisables
| du projet.
|
*/

%note {
  font-family: $font1;
  font-size: 14px;
  line-height: 1.7em;
  color: $color5;
  white-space: normal;
}

.note {
  @extend %note;
}

%quote {
  padding-left: 30px;
  border-left: 10px solid $color2;

  @media screen and (max-width: 1024px) {
    padding-left: 25px;
    border-width: 8px;
  }
  @media screen and (max-width: 768px)  {
    padding-left: 20px;
    border-width: 6px;
  }
  @media screen and (max-width: 500px)  {
    padding-left: 15px;
    border-width: 4px;
  }

  &, p {
    margin: 0;
    font-family: $font1;
    @include responsiveFontSize(22, 18);
    line-height: 1.5em;
  }
}

.quote {
  @extend %quote;
}

%list {
  li {
    @include paragraph();
    --paragraph-line-height: 1.6em;

    &:not(:first-child) {
      margin-top: var(--y-25);
    }

    &:first-child{
      > p:first-child{ margin-top: 0;}
    }

    &:last-child{
      > p:last-child{ margin-bottom: 0;}
    }

    ol,
    ul {
      width: 100%;
      margin-top: var(--y-25);
      margin-bottom: 0;

      li {
        &:not(:first-child) {
          margin-top: var(--y-20);
        }
      }
    }
  }
}

%unordered-list {
  @extend %list;

  & > li {
    position: relative;
    padding-left: 25px;

    @media screen and (max-width: 500px) { padding-left: 15px; }

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      top: calc(var(--paragraph-line-height) / 2);
      left: 0;
      background-color: $color2;
      border: 1px solid $color2;
      border-radius: 50%;
      box-sizing: border-box;
      transform: translate(0, -50%);
    }

    & > ul li {
      &::before {
        background-color: transparent;
      }

      & > ul li::before {
        background-color: $color2;
      }
    }
  }
}

.unordered-list {
  @extend %unordered-list;
}

%ordered-list {
  @extend %list;
  list-style: none;
  counter-reset: li;

  & > li {
    position: relative;
    padding-left: 45px;

    @media screen and (max-width: 768px) { padding-left: 25px; }
    @media screen and (max-width: 500px) { padding-left: 20px; }

    &::before {
      content: counter(li) ".";
      counter-increment: li;
      display: inline-block;
      position: absolute;
      top: calc(var(--paragraph-line-height) / 2);
      left: 0;
      font-size: 17px;
      font-weight: 700;
      color: $color2;
      transform: translate(0, -50%);

      @media screen and (max-width: 768px){ font-size: 16px; }
    }

    ol > li {
      &::before {
        content: counter(li, lower-alpha) ".";
      }

      ol > li {
        @media screen and (min-width: 769px) { padding-left: 40px; }

        &::before {
          content: counter(li, lower-roman) ".";
        }
      }
    }
  }
}

.ordered-list {
  @extend %ordered-list;
}

%table {
  table {
    width: auto;
    white-space: nowrap;
    overflow-x: hidden;
    table-layout: fixed;

    thead {
      tr th, tr td {
        border: 1px solid $color7;
        border-bottom: none;
        padding: 17px 30px;
        color: #fff;
        background: $color2;
        white-space: normal;
        text-align: left;
        vertical-align: middle !important;

        @media screen and (max-width: 768px) { font-size: rem(16) }

        &, p, h4, h3, h2 {
          font-family: $font1;
          font-weight: normal;
          font-size: 17px;
        }
      }
    }

    tbody:first-child() { border-top: 1px solid $color7; }

    tbody {
      tr{
        td,th{
          &.style1{ background: $color4;}
          &.header{
            border: 1px solid $color7;
            border-bottom: none;
            padding: 10px 30px;
            color: #fff;
            background: $color2;
            white-space: normal;
            text-align: left;
          }
        }
      }
      tr td:first-child, tr th:first-child { border-left: 1px solid $color7; }
      tr:last-child td, tr:last-child th { border-bottom: 1px solid $color7; }
      tr td, tr th {
        padding: 17px 30px;
        margin: 0;
        font: rem(17)/1.5 $font1;
        font-weight: normal;
        color: $color1;
        white-space: normal;
        border-right: 1px solid $color7;
        border-bottom: 1px solid $color7;
        vertical-align: middle !important;

        @media screen and (max-width: 768px) { font-size: rem(16) }

        > :first-child { margin-top: 0 !important; }
        > :last-child { margin-bottom: 0 !important; }

        p {
          font: rem(17)/1.5 $font1;
          color: $color1;

          @media screen and (max-width: 768px) { font-size: rem(16); }
        }
      }

      td {
        // Suprimer les styles en lien avec une liste dans un tableau = redondant
      }
    }
  }
}

.table {
  @extend %table;
}

