:root { --bouton-calendar-width: 0; }


.filter {
    position: -webkit-sticky;
    position: sticky;
    top: 45px;
    bottom: 0;
    z-index: 10;
    align-self: flex-start;

    @media screen and (max-width: 1024px) { display: none; }
    &__container {
        width: 320px;
        border: 1px solid rgba($color1, $alpha: 0.2);
        padding: 40px 0 40px;
    }

    &__title {
        font: rem(22)/1.3 $font1;
        font-weight: 700;
        color: $color1;
        padding: 0 50px 30px;
    }

    sup {  
        font-size: 10px;
        vertical-align:super;
    }

    &__items {
        margin-bottom: 20px;
    }

    &__items:last-child() { margin-bottom: 0; }

    &__item {
        font: rem(15)/1.3 $font1;
        font-weight: 500;
        color: $color5;
        transition: color 500ms $easeInOutCubic;
        padding-left: 50px;
        position: relative;
        &:hover { color: $color1; }
    }

    .active-category { 
        color: $color3;

        &::before {
            content:"";
            width: 20px;
            height: 1px;
            background: $color3;
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
        }
    }
}

.filter-calendar {
    &__container-calendar { margin-top: 30px; }
}

.filter-form {
    display: none;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 45px;
    bottom: 0;
    align-self: flex-start;

    @media screen and (max-width: 1024px) { display: block; }
}

.filter-form--mobile {
    width: calc(100% - var(--bouton-calendar-width));
    position: relative;
    top: auto;
    bottom: auto;
    align-self: auto;
    display: none;

    @media screen and (max-width: 768px) { display: block; }
    @media screen and (max-width: 400px) { width: 100%; margin-bottom: 30px; }

    &__search-results { margin-top: 40px; }
}

.filter-form-calendar { .form { margin-top: 30px; } }