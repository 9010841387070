.footer {
    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__background {
        width: 100%;
        height: 445px;

        @media screen and (max-width: 900px) { height: 225px; }
    }

    &__content {
        display: flex;
        position: relative;

        @media screen and (max-width: 900px) { flex-direction: column; }
    }

    &__btn {
        background: #fff;
        color: $color2;
        transition: background 300ms $easeInOutCubic, color 300ms $easeInOutCubic;
        margin-left: 0 !important;

        &:hover {
            color: #fff;
        }
    }

    // Côté droit----------->

    &__contact {
        width: 50%;
        display: flex;
        flex-direction: column;
        background: #e6eaf2;
        padding: 80px 120px 30px;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;

        @media screen and (max-width: 1300px) {
            padding: 80px 80px 30px;
        }

        @media screen and (max-width: 1100px) {
            padding: 80px 60px 30px;
        }

        @media screen and (max-width: 1000px) {
            padding: 80px 40px 30px;
        }

        @media screen and (max-width: 900px) {
            width: 100%;
            padding: 80px 120px 30px;
            position: relative;
            right: auto;
            bottom: auto;
            z-index: auto;
        }

        @media screen and (max-width: 768px) {
            padding: 80px 30px 30px;
        }

        @media screen and (max-width: 600px) {
            padding: 80px 25px 30px;
        }
    }

    &__text {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;

        @media screen and (max-width: 440px) {
            flex-direction: column;
            justify-content: initial;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 500px) { display: none; }
    }

    &__link {
        font-family: $font1;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.4;
        opacity: 1;
        transition: opacity 300ms $easeInOutCubic;
        margin-bottom: 25px;

        &:hover { opacity: 0.5; }
    }

    &__link:last-child() { margin-bottom: 0; }

    &__contact-infos {
        display: flex;
        flex-direction: column;
    }

    &__location {
        font-family: $font1;
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    &__infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px 0;
        margin-bottom: 30px;
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__info-label {
        font-family: $font1;
        font-size: 17px;
        line-height: 1.5;
        color: #373737;
    }

    &__info-value {
        font-family: $font1;
        font-size: 17px;
        line-height: 1.5;
        color: #373737;
        box-shadow: inset 0px -3px 0 rgba($color2, $alpha: 1.0);
        transition: box-shadow 300ms;

        &:hover { box-shadow: inset 0px -25px 0 rgba($color2, $alpha: 0.5); }
    }

    &__social {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 60px;
    }

    &__facebook, &__youtube, &__instagram, &__seao {
        opacity: 1;
        transition: opacity 300ms $easeInOutCubic;

        &:hover { opacity: 0.5; }
    }

    &__facebook, &__youtube { margin-right: 20px; }

    &__facebook {
        width: 10px;
        height: 18px;
    }

    &__youtube {
        width: 22px;
        height: 14px;
    }

    &__instagram {
        width: 17px;
        height: 17px;
        margin-right: 35px;
    }

    &__picture-seao {
        width: 71px;
        height: 20px;
    }

    &__social-icon {
        width: 100%;
        height: 100%;
        fill: $color1;
    }

    &__copyright {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 440px) { flex-direction: column; }
    }

    &__copyright-item {
        font-family: $font1;
        font-size: 11px;

        @media screen and (max-width: 440px) { &:first-child { margin-bottom: 10px; } }
    }
}
