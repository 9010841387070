/*** ----- TECHNICAL ----- ***/
$browser-context: 16; // La taille initiale de la typo
$colnb: 12; // Nombre de colonnes pour la grille
$gaps: 1%;  // Padding de chaque côté d'une colonne

/*** ----- COLORS ----- ***/
$color1: #373737;
$color2: #7f8b5d;
$color3: #e18545;
$color4: #e6eaf2;
$color5: #898989;
$color6: #f2f4f8;
$color7: #b2b2b2;
$color8: #7ba3b4;
$color9: #1c2b49;
$color10: #d3854f;

/*** ----- FONTS ----- ***/
$font1: 'Roboto', sans-serif; 

/*** ----- EASINGS ----- ***/
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$easeInOutQuart: cubic-bezier(0.65, 0, 0.35, 1);
$easeInOutCubic: cubic-bezier(0.76, 0, 0.24, 1);
$easeIn: cubic-bezier(0.5, 0, 0.75, 0);
$easeOut: cubic-bezier(0.25, 1, 0.5, 1);