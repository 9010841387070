.featured-events {  

    &__container {  
        padding-left: 200px;
        position: relative;
        
        @media screen and (max-width: 1000px) { padding-left: 100px; }
        @media screen and (max-width: 900px) { 
            padding-left: 0;
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__link { width: 100%; }

    &__right {
        max-width: 1120px;
        min-height: 515px;
        padding: 0 215px 0 500px;
        background: $color4;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media screen and (max-width: 1700px) { padding: 0 115px 0 500px; }
        @media screen and (max-width: 1600px) { padding: 0 215px 0 500px; }
        @media screen and (max-width: 1400px) { padding: 0 115px 0 500px; }
        @media screen and (max-width: 1300px) { padding: 0 115px 0 400px; }
        @media screen and (max-width: 1200px) {
            min-height: 415px; 
            padding: 0 115px 0 300px; 
        }
        @media screen and (max-width: 1100px) { padding: 0 75px 0 300px; }
        @media screen and (max-width: 1000px) { padding: 0 25px 0 400px; }
        @media screen and (max-width: 900px) { 
            min-height: 0;
            padding: 50px;
            justify-content: initial;
            align-items: initial;
        }
        @media screen and (max-width: 500px) { padding: 25px; }
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__category {
        font-family: $font1;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        color: $color3;
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    &__title {
        @extend .large-title;
        font-weight: normal;
        margin-bottom: 30px;

        @media screen and (max-width: 900px) { max-width: 100%; }
    }

    &__date, &__hours, &__place {
        font-family: $font1;
        font-size: 17px;
        line-height: 1.5;
        color: $color1;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
    }

    &__infos > :last-child() { margin-bottom: 0; }

    &__svg-calendar, &__svg-clock, &__svg-marker {
        width: 15px;
        height: 18px;
        min-width: 15px;
        min-height: 18px;
        fill: none;
        stroke: $color1;
        stroke-width: 2px;
        margin-right: 20px;
        transform: translateY(3px);
    }

    &__left {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @media screen and (max-width: 900px) { 
            position: relative;
            top: auto;
            right: auto;
            transform: translateY(0);
        }
    }

    &__picture {
        width: 640px;
        overflow: hidden;
        position: relative;
        padding-top: 48%;

        @media screen and (max-width: 1300px) { width: 540px; }
        @media screen and (max-width: 1200px) { width: 440px; }
        @media screen and (max-width: 900px) { width: 100%; }
    }

    &__img { 
        transition: transform 600ms $easeOut;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; 
    }

    &__link:hover &__img { transform: scale(1.1); }

    &__background { 
        background: rgba($color1, $alpha: 0.5);
        opacity: 0;
        transition: opacity 300ms $easeInOutCubic;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    &__link:hover &__background { opacity: 1; }

    &__background-text {
        font-family: $font1;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &__btn-container {
        display: flex;
        justify-content: center;
    }

    &__btn {
        margin-top: 60px;
        margin-left: 0!important;
    }
}

.featured-events-list {  

    --bouton-calendar-width: 95px;

    &__container {  
        margin-bottom: 45px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__button-and-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        @media screen and (max-width: 900px) { flex-direction: column; }
        @media screen and (max-width: 768px) { display: none; }
    }

    &__filter-and-calendar {
        display: none;
        margin-bottom: 30px;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 768px) { display: flex; }
        @media screen and (max-width: 400px) { flex-direction: column; align-items: flex-start; }
    }

    &__date-title { 
        @include medium-title();

        @media screen and (max-width: 900px) { margin-bottom: 30px; }
        @media screen and (max-width: 768px) { margin-bottom: 0; }
        @media screen and (max-width: 500px) { margin-bottom: 30px; }
    }

    &__btn { margin-left: 0!important; }

    &__link { width: 100%; }

    &__right {
        padding: 50px 120px 70px 100px;
        background: $color4;

        @media screen and (max-width: 900px) { padding: 50px; }
        @media screen and (max-width: 500px) { padding: 25px; }
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__category {
        font-family: $font1;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        color: $color3;
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    &__title {
        @extend .large-title;
        margin-bottom: 30px;
        font-weight: normal;

        @media screen and (max-width: 900px) { max-width: 100%; }
    }

    &__date, &__hours, &__place {
        font-family: $font1;
        font-size: 17px;
        line-height: 1.5;
        color: $color1;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
    }

    &__infos > :last-child() { margin-bottom: 0; }

    &__svg-calendar, &__svg-clock, &__svg-marker {
        width: 15px;
        height: 18px;
        min-width: 15px;
        min-height: 18px;
        fill: none;
        stroke: $color1;
        stroke-width: 2px;
        margin-right: 20px;
        transform: translateY(3px);
    }

    &__picture {
        width: 100%;
        overflow: hidden;
        position: relative;
        padding-top: 48%;
    }

    &__background { 
        background: rgba($color1 , $alpha: 0.5);
        opacity: 0;
        transition: opacity 300ms $easeInOutCubic;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    &__img { 
        transition: transform 600ms $easeOut;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__link:hover &__img { transform: scale(1.1); }

    &__link:hover &__background { opacity: 1; }

    &__background-text {
        font-family: $font1;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.featured-events-list:last-child { margin-bottom: var(--y-margin-90); }