@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Italic.ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}