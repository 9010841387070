:root {
    --theadHeight: 68px;
}

.datepicker {
    background: #fff;
    position: relative;
    width: 100%;
    direction: ltr;
}

.datepicker-rtl {
    direction: rtl;
}

.datepicker-rtl.dropdown-menu {
    left: auto;
}

.datepicker-rtl table tr td span {
    float: right;
}

.datepicker-dropdown {
    top: 0;
    left: 0;
}

.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.datepicker {
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: var(--theadHeight);
        background: $color2;
        position: absolute;
        top: 1px;
        left: 0;
    }

    // GLOBAL --------
    table {
        width: 100%;
        table-layout: fixed;
        word-wrap: break-word;
        border: 1px solid $color4;
        border-collapse: initial;
        padding: 0 20px 20px 20px;
        position: relative;
        z-index: 2;
    }
    // ---------------

    // HEAD ----------
    thead > tr:nth-child(2) { 
        height: var(--theadHeight);
        background: $color2; 
    }

    .datepicker-switch, .prev, .next {
        transition: opacity 300ms;
    }

    .prev, .next {
        width: 25px;
        font-size: 0;
        background-image: url('../medias/images/icons/chevron-calendar.svg');
        background-repeat: no-repeat;
        background-size: 15px 12px;
        cursor: pointer;

        &.disabled {
            visibility: hidden;
        }
    }

    .datepicker-switch {
        width: calc(100% - 50px);
        font: 700 rem(16)/1 $font1;
        color: #fff;
        padding: 25px 0;

        @media screen and (max-width: 425px) { font-size: rem(18); }
    }

    .prev {
        transform: rotate(180deg);
        background-position: 50% 50%;
        transition: background 300ms $easeOutCubic;

        &:hover {
            background-position: 60% 50%;
        }
    }

    .next {
        background-position: 50% 50%;
        transition: background 300ms $easeOutCubic;

        &:hover {
            background-position: 60% 50%;
        }
    }
    // ---------------

    // DAYS ----------
    .dow {
        width: 38px;
        padding-top: 30px;
        padding-bottom: 10px;
        font: 700 rem(12)/1 $font1;
        font-weight: 700;
        color: $color1;
        text-align: center;
        cursor: default;

        @media screen and (max-width: 1024px) { padding-top: 30px; }
        @media screen and (max-width: 1024px) { width: 34px; }
    }
    // ---------------

    // DATES ---------
    .day {
        position: relative;
        width: 35px;
        height: 39px;
        font: 500 rem(12)/1 $font1;
        color: $color1;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;
        transition: background 300ms $easeInOutCubic;

        // @media screen and (max-width: 1024px) { width: 34px; height: 34px; }

        // TODO: À ajouter à la classe voulu dans le calendrier lorsqu'il sera dynamisé
        // &:nth-child(5):after {
        //     content: '';
        //     position: absolute;
        //     width: 5px;
        //     height: 5px;
        //     top: 10px;
        //     right: 6px;
        //     transform: translate(-50%, -50%);
        //     background-color: $color3;
        //     z-index: 1;
        //     border-radius: 50%;

        //     @media screen and (max-width: 1024px) { width: 4px; height: 4px; right: 6px; }
        // }

        &.disabled, &.disabled:hover {
            background: none;
            cursor: default;

            &:after{
                display: none;
            }
        }

        &:not(.disabled){

            &:after{
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                top: 10px;
                right: 6px;
                transform: translate(-50%, -50%);
                background-color: $color3;
                z-index: 1;
                border-radius: 50%;

                @media screen and (max-width: 1024px) { width: 4px; height: 4px; right: 6px; }
            }

            &:hover { background: #e5e8df; }
        }
    }

    .old, .new {
        color: #96a3a5;

        &:nth-child(5):after {
            background-color: $color3;
        }
        &:nth-child(1):after {
            background-color: $color3;
        }

        &.day.active {
            
            &:before {
                background: #fff !important;
            }
        }
        &.day.today {
            
            &:before {
                background: #fff !important;
            }
        }
    }

    .day.today { background: $color4; }

    .day.active {
        color: #fff;
        background: $color2;
        &::after { background: #fff; }
        cursor: default;
    }
    // ---------------

    // MONTHS --------
    .month, .year, .decade, .century {
        float: left;
        width: 25%;
        height: 30px;
        font: rem(12)/30px $font1;
        color: $color2;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;

        &:hover {
            background: #e9eaeb;
        }

        &.focused {
            color: #fff;
            background: $color1;
        }
    }
    // ---------------
}
