/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {
    background: #fff;
    border-bottom: 5px solid $color2;
    transition: border 700ms $easeInOutQuart;

    .rubric__title { color: $color2; }
    .rubric__icon { transform: translateY(-50%) rotate(-45deg); will-change: transform; }
    .rubric__icon-lines { background: $color2; }

    .rubric__transition {
        opacity: 1;
        transform: none;
        transition: opacity 700ms $easeInOutQuart 100ms, transform 700ms $easeInOutQuart 100ms;
    }

    .rubric--plus__surtitle { color: $color2; }
    .rubric--plus__text--no-image { padding: 13px 0 0 0; }
}

/*
|--------------------------------------------------------------------------
| RUBRIQUE FERMÉ
|--------------------------------------------------------------------------
*/
.rubric:not(.js-accordion-opened) {
    iframe {
        pointer-events: none;
    }
}

/*
|--------------------------------------------------------------------------
| SELECT DROPDOWN ACTIF
|--------------------------------------------------------------------------
*/
.field--select .tail-select.active {
    .select-label { 
        background: $color1; 
        border-bottom: #fff; 
    }
    .label-inner { color: $color5; }
    .label-inner:before { 
        transform: translateY(-50%) rotate(-90deg);
        background: url('../medias/images/icons/chevron-select-actif.svg') no-repeat center / contain; 
    }
    .select-dropdown {
        opacity: 1;
        top: 100%;
        visibility: visible;
        pointer-events: all;
        transition: top 500ms $easeInOutCubic, opacity 500ms $easeInOutCubic;
    }
}

.rubric {
    .field--select .tail-select.active {
        .select-label { background: $color3; border-color: $color3; }
    }
}