//Bouton normal
.btn-impact {
    padding: 25px 40px;
    background: $color3;
    transition: background 300ms $easeInOutCubic;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    position: relative;

    &:hover { background: #bd7747; }
}

.btn-impact:not(:first-child) { margin-left: 30px; }
.btn-impact:not(.left-block__btn):not(.banner__btn):not(.btn-fast-links)  { 
    
    svg {
        width: 11px;
        height: 15px;
        fill: #fff;
        margin-left: 15px;
        transform: translateY(2px);
    }
}

.stylesheet {
    margin-top: 30px;
    margin-bottom: 70px;
}

.btn-simple {
    padding: 0; 
    padding-bottom: 5px;
    background: transparent;
    color: $color3;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    position: relative;

    &::after {
        content: "";
        width: 100%;
        transition: width 300ms $easeInOutCubic;
        height: 3px;
        background: $color3;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &:hover { &::after { width: 50%; } }

    svg {
        width: 10px;
        height: 15px;
        fill: $color3;
        margin-left: 15px;
        transform: translateY(2px);
    }
}

.btn-simple:not(:first-child) { margin-left: 30px; }

// Bouton Menu
.btn-menu {
    position: relative;
    width: 55px;
    height: 55px;
    transform: translateY(12px);
    border-radius: 50%;
    background: $color4;
    margin-left: 40px;
    cursor: pointer;

    &::after {
        content: "";
        width: 55px;
        height: 55px;
        transition: transform 300ms $easeInOutCubic;
        border-radius: 50%;
        background: $color4;
        position: absolute;
    }

    &:hover { &::after { transform: scale(1.2); } }

    @media screen and (max-width: 900px) { margin-left: 0; }

    &__lines {
        position: absolute;
        width: 25px;
        height: 18px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    &__line {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: $color1;
        opacity: 1;
        left: 0%;
        transform: rotate(0deg);
        transition: 250ms;
    }

    &__line1 { top: 0px; }
    &__line2, &__line3 { top: calc(50% - 1px); }
    &__line4 { bottom: 0px; }
}

// Bouton de recherche
.btn-search {
    display: none;

    @media screen and (max-width: 900px) { display: block; }

    &__svg-search {
        width: 25px;
        height: 25px;
        fill: none;
        stroke: $color1;
        stroke-width: 2px;
        position: absolute;
        top: 50%;
        right: 145px;
        transform: translateY(-50%);
        cursor: pointer;

        @media screen and (max-width: 768px) { right: 125px; }
        @media screen and (max-width: 600px) { right: 120px; }
        @media screen and (max-width: 400px) { right: 100px; }
    }
}

// Bouton accès rapide
.btn-fast-links {
    padding-right: 95px;
    transition: color 300ms $easeInOutCubic, background 300ms $easeInOutCubic;
    overflow-y: hidden;

    @media screen and (max-width: 500px) { 
        padding: 15px 65px 15px 15px;
        font-size: 13px;
    }

    &:hover {
        background: #fff;
        color: $color2;

        svg { stroke: $color2; }
    }

    &__slider {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);

        @media screen and (max-width: 500px) { right: 20px; }
    }

    svg {
        width: 32px;
        height: 32px;
        fill: none;
        stroke: #fff;
        transition: stroke 300ms $easeInOutCubic;
        stroke-width: 1px;

        @media screen and (max-width: 500px) {
            width: 25px;
            height: 25px;
        }
    }

    .slick-list {
        width: 32px;
        height: 32px;

        @media screen and (max-width: 500px) {
            width: 25px;
            height: 25px;
        }
    }
}

.btn-calendar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: $color1;
    position: relative;

    svg {
        width: 20px;
        height: 20px;
        stroke: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.btn-return {
    width: 65px;
    height: 65px;
    background: #fff;
    border: 1px solid $color4;
    position: fixed;
    transform: rotate(-90deg);
    right: 25px;
    bottom: 25px;
    z-index: 10;

    @media screen and (max-width: 768px) {
        width: 45px;
        height: 45px;
    }

    @media screen and (max-width: 500px) {
        width: 35px;
        height: 35px;
    }

    &__arrow {
        width: 15px;
        height: 15px;
        stroke-width: 5px;
        stroke: $color3;
        fill: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.disable { display: none; }
