/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-calendar {
    .overlay-calendar {
        pointer-events: all;
        visibility: visible;

        &__container {
            opacity: 1;
            pointer-events: all;
        }

        &__content {
            opacity: 1;
            transform: none;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-calendar.closing-overlay-calendar {
    .overlay-calendar {
        &__container {
            opacity: 0;
        }
        
        &__content {
            opacity: 0;
            transform: translateY(100px);
        }
    }
}