.huge-title {
    @include huge-title();
    color: $color1;
}

.smaller { font-size: 30px; }

.large-title {
    @include large-title(); 
    color: $color1;
}

.medium-title {
    @include medium-title();
    color: $color1;
}

.small-title {
    @include small-title();
    color: $color1;
}

p {
    @include paragraph();
    color: $color1;
}

a {
	display: inline-block;
	text-decoration: none;
    cursor: pointer;
    font-family: $font1;
    color: $color1;
}