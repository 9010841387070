:root {
    --alert-height: 45px;

    --alerts-side-padding: 40px;
    --alerts-inside-padding: 25px;

    --alerts-left-side-width: 200px;
    
    --alerts-right-side-width: calc(var(--alerts-side-padding) + var(--alerts-inside-padding) + var(--alerts-close-width));
    --alerts-close-width: 110px;
    --alert-x-dimensions: 14px;
    
    
    @media screen and (max-width: 1200px) {
        --alerts-inside-padding: 20px;
        --alerts-left-side-width: 170px;
    }
    @media screen and (max-width: 1024px) {
        --alerts-side-padding: 30px;
    }
    @media screen and (max-width: 768px) {
        --alerts-side-padding: var(--alerts-inside-padding);
        --alert-x-dimensions: 20px;
        --alerts-left-side-width: 115px;
        --alerts-right-side-width: calc(var(--alert-x-dimensions) + (var(--alerts-inside-padding) * 2));
    }
    @media screen and (max-width: 500px) {
        --alerts-left-side-width: 50px;
        --alerts-inside-padding: 10px;
    }
}

.alerts {
    position: absolute;
    width: 100%;
    top: calc(0px - var(--alert-height));
    left: 0;
    transition: top 300ms $easeOutCubic;
    z-index: 1;

    &__container {
        display: flex;
        height: var(--alert-height);
        background: $color3;
    }

    // Left side
    &__left {
        display: flex;
        align-items: center;
        padding-right: var(--alerts-inside-padding);
        padding-left: var(--alerts-side-padding);
        width: var(--alerts-left-side-width);
        height: 100%;
        font: rem(13)/1 $font1;
        color: #fff;
        border-right: 1px solid rgba(#fff, 0.2);

        @media screen and (max-width: 500px) { font-size: rem(12); }
    }

    &__icon {
        width: 18px;
        height: 18px;
        fill: #fff;

        @media screen and (max-width: 768px) { display: none;}
    }

    &__counter {
        flex: 1;
        text-align: center;

        @media screen and (max-width: 768px) and (min-width: 501px) { text-align: left; }
    }

    &__prev-next {
        height: 100%;
        display: flex;
        justify-content: space-between;
        width: 30px;
        position: relative;

        @media screen and (max-width: 500px) { display: none; }
    }

    &__prev-next-link {
        font-size: 0;
    }

    &__arrow {
        width: 12px;
        height: 12px;
        stroke-width: 2;
        stroke: #fff;
        fill: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    &__arrow-prev {
        transform: translateY(-45%) rotate(180deg);
        margin-right: 20px;
    }
    
    // Center
    &__slider {
        display: flex;
        align-items: center;
        width: calc(100% - var(--alerts-left-side-width) - var(--alerts-right-side-width));
        padding: 0 var(--alerts-inside-padding);
    }

    &__slider .slick-track, &__slider .slick-slide { font-size: 0; }

    &__text {
        display: block;
        margin-bottom: 0;
        width: 100%;
        font: rem(16)/1 $font1;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media screen and (max-width: 500px) { font-size: rem(13); }
    }

    &__link {
        opacity: 1;
        transition: opacity 300ms;
        height: var(--alert-height);
        display: flex;
        align-items: center;
    }

    &__link:hover {
        opacity: 0.6;
    }

    // Right side
    &__right {
        display: flex;
        align-items: center;
        width: var(--alerts-right-side-width);
        height: 100%;
        padding-right: var(--alerts-side-padding);
        padding-left: var(--alerts-inside-padding);
        border-left: 1px solid rgba(#fff, 0.2);
    }

    &__close {
        display: flex;
        align-items: center;
        width: var(--alerts-close-width);
    }

    &__close-text {
        white-space: nowrap;
        position: relative;
        margin-right: 20px;
        font: 700 rem(13)/1 $font1;
        color: #fff;

        @media screen and (max-width: 768px) { display: none; }
    }

    &__close-text:after {
        content: '';
        position: absolute;
        height: 1px;
        right: 0;
        bottom: -2px;
        left: 0;
        background: #fff;
        opacity: 0;
        transition: opacity 300ms;
    }
    
    &__close-x {
        position: relative;
        width: var(--alert-x-dimensions);
        height: var(--alert-x-dimensions);
        transition: opacity 300ms;


    }
    
    &__close-x:before, &__close-x:after {
        content: '';
        position: absolute;
        width: 2px;
        height: var(--alert-x-dimensions);
        top: 50%;
        left: 50%;
        background: #fff; 
    }
    
    &__close-x:before { transform: translate(-50%, -50%) rotate(45deg);  }
    &__close-x:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    &__close:hover &__close-text:after { opacity: 1; }
    &__close:hover &__close-x {
        @media screen and (max-width: 768px) {
            opacity: 0.6;
        }
    }
}