:root { --breadcrumb-height: 45px; }

.breadcrumb {
    &__container {
        width: 100%;
        height: var(--breadcrumb-height);
        background: #fff;
        display: flex;
        align-items: center;
        border-top: 1px solid $color4;
        border-bottom: 1px solid $color4; 
        position: relative;

        @extend .left-padding-70;
        padding-right: 213px;

        @media screen and (max-width: 1024px) { padding-right: 193px; }
        @media screen and (max-width: 768px) { padding-right: 173px; }
        @media screen and (max-width: 768px) { padding-right: 168px; }
        @media screen and (max-width: 600px) { padding-right: var(--x-padding-70); }
    }

    &__link-home, &__link-share {
        font-size: 14px;

        @media screen and (max-width: 500px) { font-size: 12px; }
    }

    &__text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
    }

    &__item {
        @include paragraph();
        font-size: 14px;
        padding: 20px 0 20px 50px;
        position: relative;

        &:before {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $color1;
            position: absolute;
            top: 50%;
            left: 23px;
            transform: translateY(-50%);
        }

        @media screen and (max-width: 500px) { 
            font-size: 12px;
            padding: 15px 0 15px 50px; 
        }
    }

    &__link-share {
        position: absolute;
        top: 50%;
        right: 70px;
        transform: translateY(-50%);

        @media screen and (max-width: 1024px) { right: 50px; }
        @media screen and (max-width: 768px) { right: 30px; }
        @media screen and (max-width: 600px) { display: none; }
    }

    &__svg-share {
        width: 14px;
        height: 14px;
        fill: $color1;
        stroke: $color1;
        stroke-width: 3px;
        margin-left: 15px;
        transform: translateY(3px);

        @media screen and (max-width: 500px) {
            width: 12px;
            height: 12px;
        }
    }
}