.jobs {
    @extend .bottom-margin-110; 
    & > :last-child() { border-bottom: 1px solid $color1; }

    &__job {
        display: block;
        position: relative;
        padding-top: 60px;
        padding-bottom: 60px;
        border-top: 1px solid $color1;
        // cursor: pointer;

        @media screen and (max-width: 1200px) { padding-top: 50px; padding-bottom: 50px; }
        @media screen and (max-width: 1024px) { padding-top: 40px; padding-bottom: 40px; }
        @media screen and (max-width: 600px)  { padding-top: 30px; padding-bottom: 30px; }
    }

    &__job--plus {
        display: flex;
        align-items: center;
    }

    &__text{
        padding-right: 90px;

        @media screen and (max-width: 1024px) { padding-right: 80px; }
        @media screen and (max-width: 768px) { padding-right: 70px; }
    }

    &__surtitle {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.3;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    &__job:hover &__surtitle { color: $color2; }

    &__title {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.2;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        @include responsiveFontSize(30, 20)
    }

    &__job:hover &__title { color: $color2; }

    &__icon {
        width: 14px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: var(--x-padding-40);
        transform: translateY(-50%) rotate(45deg);
    }

    &__svg {
        width: 100%;
        height: 100%;
        fill: $color1;
        transition: fill 300ms $easeInOutCubic;
    }

    &__job:hover &__svg { 
        fill: $color2; 
    }
} 