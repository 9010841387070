.contact {
    &__container { padding-left: var(--x-padding-400); }

    &__content {
        background: #fff;

        @extend .x-padding-160;
        @extend .y-padding-50;
    }

    &__infos { margin-bottom: 75px; }

    &__phone {  margin-bottom: 40px; }

    &__phone-link {
        @include large-title();
        color: $color2;
    }

    &__address-part-1, &__address-part-2, &__fax, &__email {
        @include paragraph();
        color: $color1;
    }

    &__link {
        display: inline;
        padding-bottom: 3px;
        box-shadow: inset 0px -3px 0 rgba($color2, $alpha: 1.0);
        transition: box-shadow 300ms;

        &:hover { box-shadow: inset 0px -20px 0 rgba($color2, $alpha: 0.5); }
    }

    &__address-part-2 { margin-bottom: 30px; }

    &__title, h3 {
        @include large-title();
        color: $color1;
        margin-bottom: 30px;
    }

    &__schedule-box { margin-bottom: 45px; }
    &__schedule-box:last-child { margin-bottom: 0; }

    &__place, h4 {  
        font-size: 25px;
        font-family: $font1;
        font-weight: 500;
        margin-bottom: 20px;
    }

    &__hours {
        @include paragraph();
        margin-bottom: 15px;
    }

    &__hours:last-child { margin-bottom: 0; }
}