:root { 
    --banner-height: calc((var(--vh, 1vh) * 100) - var(--header-height));

    @media screen and (max-width: 768px) { --banner-height: 300px; }
    @media screen and (max-width: 400px) { --banner-height: 200px; }
}

.banner {
    position: relative;
    z-index: -1;

    @media screen and (max-width: 768px) { z-index: auto; }

    &__btn {
        background: #fff;
        color: $color2;
        transition: background 300ms $easeInOutCubic, color 300ms $easeInOutCubic;
        position: absolute;
        bottom: 50px;
        left: 80px;
        display: none;

        &:hover {
            background: $color2;
            color: #fff;
        }

        @media screen and (max-width: 768px) { 
            left: 30px; 
            display: block; 
        }
        @media screen and (max-width: 600px) { 
            left: 25px; 
            bottom: 25px;
        }
        @media screen and (max-width: 500px) { padding: 20px; }
        @media screen and (max-width: 400px) { padding: 15px; }
    }

    &__btn-svg {
        fill: none;
        stroke: $color2;
        transition: stroke 300ms $easeInOutCubic;
        stroke-width: 5px;
        width: 15px;
        height: 12px;
        margin-left: 20px;
        transform: rotate(90deg);
    }

    &__btn:hover &__btn-svg { stroke: #fff; }

    &__copyright {
        font-family: $font1;
        font-size: 13px;
        color: #fff;
        background: rgba($color: #000000, $alpha: 0.5);
        padding: 10px 20px;
        display: flex;
        position: absolute;
        top: 0;
        right: 0;

        @media screen and (max-width: 768px) { font-size: 12px; }
        @media screen and (max-width: 600px) { font-size: 10px; }
        @media screen and (max-width: 500px) { font-size: 8px; }
    }

    &__picture {
        width: 100%;
        height: var(--banner-height);
    }

    &__img { 
        min-height: 200px;

        @media screen and (max-height: 1000px) { object-position: 50% 0%; } 
    }
}