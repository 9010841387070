:root {
    --header-height: 100px;

    @media screen and (max-width: 600px) { --header-height: 80px; }
}


.header {
    position: relative;
    margin-top: 0;
    transition: margin 300ms $easeOutCubic;

    // Côté gauche----------->

    &__container {
        height: var(--header-height);
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        @extend .x-padding-70;
    }

    &__logo-link {
        width: 130px;
        height: auto;
        position: relative;

        @media screen and (max-width: 500px) { width: 120px; }
    }

    &__logo-svg {
        width: 100%;
        height: 100%;
    }

    &__logo-text {
        font-family: $font1;
        font-size: 8px;
        font-weight: 700;
        color: #659c62;
        white-space: nowrap;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 500px) {
            font-size: 6px;
            top: 50px;
        }
    }

    // Côté droit----------->

    &__right {
        padding-bottom: 25px;
        display: flex;
        align-items: center;
    }
}
