/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-menu {
    .header-mobile { box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15); }
    
    .btn-menu {
        &__line1 { width: 0%; top: 16px; left: 50%; opacity: 0; }
        &__line2 { transform: rotate(45deg); width: 100%; }
        &__line3 { transform: rotate(-45deg); width: 100%; }
        &__line4 { width: 0%; top: 16px; left: 50%; opacity: 0; }
    }
    
    .overlay-menu {
        visibility: visible;
        pointer-events: all;
        
        &__background { background: rgba(#000, 0.4); }
        &__container {
            right: 0px;

            @media screen and (max-width: 600px) { right: 0%; }
        }
        &__main-level {
            opacity: 1;
            transform: none;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-menu.closing-overlay-menu {
    .btn-menu {
        &__line1 { width: 100%; top: 0px; left: 0%; opacity: 1; }
        &__line2, &__line3 { top: calc(50% - 1px); transform: none; }
        &__line4 { width: 100%; bottom: 0px; left: 0%; opacity: 1; }
    }

    .overlay-menu {
        &__background { background: rgba(#000, 0); }
        &__container {
            right: -500px;

            @media screen and (max-width: 600px) { right: -100%; }
        }
        &__main-level {
            opacity: 0;
            transform: translateX(200px);
        }
    }
}


/*
|--------------------------------------------------------------------------
| SOUS-MENU OUVERT
|--------------------------------------------------------------------------
*/
.overlay-menu {
    .js-accordion-opened-main-level {  .overlay-menu__main-level-svg { transform: translateY(-50%) rotate(-90deg); } }
    .js-accordion-opened-main-level {  .overlay-menu__main-level-svg { stroke: $color3; } }
    .js-accordion-opened-main-level {  .overlay-menu__main-level-title { color: $color3; } }
    // .js-accordion-opened-main-level &__second-level { opacity: 1; }
    // .js-accordion-opened-main-level &__second-level { margin-top: 30px; }
    // .js-accordion-opened-main-level &__second-level li { &::after { opacity: 1; } }

    .js-accordion-opened-second-level {  .overlay-menu__second-level-svg { transform: translateY(-50%) rotate(-90deg); } }
    .js-accordion-opened-second-level &__third-level { opacity: 1; }
    .js-accordion-opened-second-level &__third-level:nth-child(2) { margin-top: 20px; }
}