.sopfeu-widget {
    width: 100%;
    position: relative;

    &__alert {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__link {
        font-family: $font1;
        font-size: 18px;
        line-height: 1.5;
        padding-left: 35px;
    }

    &__low { background: #294D9D; }
    &__medium { background: #58B14A; }
    &__high { background: #FFCB0F; }
    &__veryhigh { background: #F18825; }
    &__extreme { background: #E5272B; }

    &__taux { font-weight: 700; }
}