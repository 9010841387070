.rubrics { 
    margin-top: 30px;
    @extend .bottom-margin-110; 
}

.rubric {
    position: relative;
    border-bottom: 1px solid $color1;
    background: transparent;
    overflow: hidden;
    transition: background 700ms;

    &:first-child { border-top: 1px solid $color1; }
    
    &__transition { & > :last-child { margin-bottom: 0; } }
    &__transition--no-share { padding-bottom: 90px; }

    &__header {
        display: block;
        position: relative;
        padding-top: 60px;
        padding-bottom: 60px;
        cursor: pointer;

        @media screen and (max-width: 1200px) { padding-top: 50px; padding-bottom: 50px; }
        @media screen and (max-width: 1024px) { padding-top: 40px; padding-bottom: 40px; }
        @media screen and (max-width: 600px)  { padding-top: 30px; padding-bottom: 30px; }
    }

    &__title {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.2;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        @include responsiveFontSize(30, 20)
    }

    &__header:hover &__title { color: $color2; }

    &__icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        right: var(--x-padding-40);
        transform: translateY(-50%);
        transition: transform 700ms $easeInOutQuart;

        @media screen and (max-width: 600px) {
            width: 19px;
            height: 19px;
        }
    }

    &__icon-lines { 
        background: $color1;
        transition: background 300ms $easeInOutCubic;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &__header:hover &__icon-lines { background: $color2; }

    &__icon-line-1 { 
        width: 100%;
        height: 2px;
        
    }
    &__icon-line-2 { 
        width: 2px;
        height: 100%;
    }

    &__content {
        height: 0;
        will-change: height;
        overflow: hidden;
    }

    &__transition {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 700ms $easeInOutQuart, transform 700ms $easeInOutQuart;
    }
    
    &__transition > :first-child { margin-top: 0; }

    &__dynamic {
        h3 {  
            margin-top: 50px;
            margin-bottom: 25px;
            line-height: 1.45;
            font-weight: 500;
            @include responsiveFontSize(22, 20);
        }
        h3 + ul { margin-bottom: 50px; }
        h3 + h4 { margin-top: 25px; }
        h4 { 
            margin-bottom: 25px; 
            font-size: 18px;
            line-height: 1.5;
            font-weight: 700;
        }
        p { margin: 17.5px 0; }
        p + h4 { margin-top: 50px; }

        ul { padding-left: 17px; }
    }

    &__share {
        margin-top: 35px;
        padding: 25px 0;
    }

    &__share-link {
        font-size: 15px;
        font-weight: 700;
    }

    &__share-icon {
        width: 17px;
        height: 17px;
        fill: $color1;
        stroke: $color1;
        stroke-width: 3px;
        margin-left: 15px;
        transform: translateY(3px);
    }

    &__gallery{
      margin: 65px 0;
    }
}

.rubric--plus {
    &__header { 
        display: flex; 
        align-items: center;
        padding-top: 35px;
        padding-bottom: 35px;
    }

    &__picture {  
        width: 90px;
        height: 90px;
        min-width: 90px;
        min-height: 90px;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__text { 
        padding: 13px 90px 13px 30px;
        transition: padding 700ms $easeInOutCubic;

        @media screen and (max-width: 1024px) { padding-right: 80px; }
        @media screen and (max-width: 768px) { padding-right: 70px; }
        @media screen and (max-width: 600px) { padding-left: 0; }
    }

    &__text--no-image { padding-left: 0; }

    &__surtitle {
        font-family: $font1;
        font-weight: 700;
        line-height: 1.3;
        color: $color1;
        transition: color 300ms $easeInOutCubic;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    &__header:hover &__surtitle { color: $color2; }
}