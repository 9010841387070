.grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    flex-flow: row wrap;
    width: 100%;

    &.gaps {
        width: calc(100% + #{$gaps} + #{$gaps});
        margin-left: -$gaps;
        margin-right: -$gaps;

        .column {
            padding-right: $gaps;
            padding-left: $gaps;
        }
    }
}



// Création des colonnes de base
@for $i from 1 through $colnb {
	.col#{$i} { width: ($i / $colnb * 100%); }
}

// Media query 1800
@media screen and (max-width: 1800px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1800 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1700
@media screen and (max-width: 1700px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1700 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1600
@media screen and (max-width: 1600px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1600 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1500
@media screen and (max-width: 1500px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1500 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1400
@media screen and (max-width: 1400px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1400 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1300
@media screen and (max-width: 1300px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1300 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1200
@media screen and (max-width: 1200px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1200 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1100
@media screen and (max-width: 1100px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1100 { width: ($i / $colnb * 100%); }
	}
}

// Media query 1024
@media screen and (max-width: 1024px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1024 { width: ($i / $colnb * 100%); }
	}
}

// Media query 900
@media screen and (max-width: 900px) {
	@for $i from 1 through $colnb {
		.col#{$i}_900 { width: ($i / $colnb * 100%); }
	}
}

// Media query 768
@media screen and (max-width: 768px) {
	@for $i from 1 through $colnb {
		.col#{$i}_768 { width: ($i / $colnb * 100%); }
	}
}

// Media query 768
@media screen and (max-width: 700px) {
	@for $i from 1 through $colnb {
		.col#{$i}_700 { width: ($i / $colnb * 100%); }
	}
}

// Media query 600
@media screen and (max-width: 600px) {
	@for $i from 1 through $colnb {
		.col#{$i}_600 { width: ($i / $colnb * 100%); }
	}
}

// Media query 500
@media screen and (max-width: 500px) {
	@for $i from 1 through $colnb {
		.col#{$i}_500 { width: ($i / $colnb * 100%); }
	}
}

// Media query 425
@media screen and (max-width: 425px) {
	@for $i from 1 through $colnb {
		.col#{$i}_425 { width: ($i / $colnb * 100%); }
	}
}

// Media query 375
@media screen and (max-width: 375px) {
	@for $i from 1 through $colnb {
		.col#{$i}_375 { width: ($i / $colnb * 100%); }
	}
}

// Media query 320
@media screen and (max-width: 320px) {
	@for $i from 1 through $colnb {
		.col#{$i}_320 { width: ($i / $colnb * 100%); }
	}
}