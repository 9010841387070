.intro {
    &__container { padding-left: var(--x-padding-400); }

    &__container--style { 
        background: transparent;
        margin-bottom: 65px;
        padding-left: var(--x-padding-260);
        padding-right: var(--x-padding-260);
    }

    &__content { 
        background: #fff;
        margin-bottom: 65px;

        @extend .x-padding-160;
    }

    &__text {
        font-family: $font1;
        @include responsiveFontSize(28, 20);
        line-height: 1.5;
    }
}