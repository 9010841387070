.result-box {
    &__container {
        padding-bottom: 40px;
        border-bottom: 1px solid $color1;
        margin-bottom: 50px;
    }

    &__surtitle {
        font-size: 12px;
        font-family: $font1;
        font-weight: 700;
        text-transform: uppercase;
        color: $color1;
        margin-bottom: 20px;
    }

    &__title {
        @include medium-title();
        color: $color1;
        margin-bottom: 30px;
    }

    &__text { 
        @include paragraph();
        color: $color1;
        margin-bottom: 40px;
    }

    &__btn { margin-left: 0!important; }
}