/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-search {
    .btn-search {
        &__x   { opacity: 1; }
        &__svg { opacity: 0; }
    }

    .overlay-search {
        width: 100%;
        border-top: solid 1px $color4;
        top: var(--header-height);
        bottom: 0;
        
        &__background { background: rgba(#000, 0.4); }

        &__form {
            opacity: 1;
            transform: none;
        }
    }
}

.show-alerts {
    .overlay-search { top: calc(var(--header-height) + var(--alert-height)); }
}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-search.closing-overlay-search {
    .btn-search {
        &__x   { opacity: 0; }
        &__svg { opacity: 1; }
    }

    .overlay-search {
        &__background { background: rgba(#000, 0); }
        &__form {
            opacity: 0;
            transform: translateY(100px);
        }
    }
}