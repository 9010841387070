.form {
    &__title {
        margin-bottom: 30px;
        @extend .small-title;

        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 768px)  { margin-bottom: 20px; }
    }

    &__grid-gaps{
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
        
        @media screen and (max-width: 1200px) {
            width: calc(100% + 50px);
            margin-right: -25px;
            margin-left: -25px;
        }
        @media screen and (max-width: 1024px) {
            width: calc(100% + 30px);
            margin-right: -15px;
            margin-left: -15px;
        }
        @media screen and (max-width: 900px) {
            width: calc(100% + 10px);
            margin-right: -5px;
            margin-left: -5px;
        }
    }
    
    &__column {
        padding-right: 40px;
        padding-left: 40px;

        @media screen and (max-width: 1200px) {
            padding-right: 25px;
            padding-left: 25px;
        }
        @media screen and (max-width: 1024px) {
            padding-right: 15px;
            padding-left: 15px;
        }
        @media screen and (max-width: 900px) {
            padding-right: 5px;
            padding-left: 5px;
        }
    }

    .oc-loading {
        pointer-events: none;
    }
}

.form-with-filter { @extend .x-padding-160; }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 40px white inset !important;
    -webkit-text-fill-color: black;
}

#form-newsletter {

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 40px #7f8b5d inset !important;
        caret-color: white;
    }
}

#infolettrePage {
    p { color: white; }
}