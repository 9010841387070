.green-block {
    --image-width: 650px;

    @media screen and (max-width: 1400px) { --image-width: 625px; }
    @media screen and (max-width: 1350px) { --image-width: 600px; }
    @media screen and (max-width: 1300px) { --image-width: 575px; }
    @media screen and (max-width: 1250px) { --image-width: 550px; }
    @media screen and (max-width: 1200px) { --image-width: 525px; }
    @media screen and (max-width: 1150px) { --image-width: 500px; }
    @media screen and (max-width: 1100px) { --image-width: 475px; }
    @media screen and (max-width: 1050px) { --image-width: 450px; }
    @media screen and (max-width: 1000px) { --image-width: 425px; }
    @media screen and (max-width: 950px) { --image-width: 400px; }


    &__container {
        @extend .right-padding-160;
        @extend .y-padding-160;
        background: $color2;
        position: relative;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: 900px) { 
            padding-left: var(--x-padding-160); 
            flex-direction: column;
        }
    }

    &__left { 
        padding: 90px 0; 
        position: absolute;
        top: 50%;
        left: var(--x-padding-180);
        transform: translateY(-50%);
        z-index: 4;

        @media screen and (max-width: 900px) { 
            padding: 25px 0 75px;
            position: relative;
            top: auto;
            left: auto;
            transform: translateY(0);
        }
    }

    &__title {
        max-width: 630px;
        @extend .large-title;
        line-height: 1.10;
        @include responsiveFontSize(80, 40); 
        color: #fff;
        margin-bottom: 50px;
    }

    &__btn {
        background: #fff;
        color: $color2;
        transition: background 300ms $easeInOutCubic, color 300ms $easeInOutCubic;

        &:hover {
            color: #fff;
        }
    }

    &__right-content {
        width: 100%;
    }

    &__right-link-container {
        width: var(--image-width);
        position: absolute;
        top: var(--y-padding-160);
        right: var(--x-padding-160);
        bottom: var(--y-padding-160);
        z-index: 10;
        pointer-events: none;

        @media screen and (max-width: 900px) { display: none; }
    }

    &__right-link {
        padding-top: 87%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: all;
    }

    &__right-content { 
        position: relative; 
        overflow: hidden;
    }

    &__picture-container {
        width: var(--image-width);
        position: relative;

        @media screen and (max-width: 900px) { width: 100%; }
    }

    &__picture {
        width: 100%;
        padding-top: 87%;
        position: relative;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__filter {
        background: transparent;
        background: linear-gradient(90deg, rgba(55,55,55,0.7) 0%, rgba(55,55,55,0) 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    &__shape {
        width: 100%;
        height: auto;
        min-height: 150px;
        fill: $color2;
        position: absolute;
        bottom: -55px;
        left: 0;
        z-index: 3;

        @media screen and (max-width: 900px) { width: calc(100% + 1px) }
        @media screen and (max-width: 500px) { 
            min-height: 125px;
            bottom: -50px;
        }
        @media screen and (max-width: 500px) { min-height: 110px; }
    }

    &__play-button {
        width: 185px;
        height: 185px;
        border: 1px solid white;
        border-radius: 50%;
        transition: background 300ms;
        position: absolute;
        top: calc(50% - 30px);
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 3;

        @media screen and (max-width: 1300px) {
            width: 150px;
            height: 150px;
        }
        @media screen and (max-width: 1024px) {
            width: 125px;
            height: 125px;
        }
        @media screen and (max-width: 900px) {
            width: 185px;
            height: 185px;
        }
        @media screen and (max-width: 800px) {
            width: 150px;
            height: 150px;
        }
        @media screen and (max-width: 700px) {
            width: 125px;
            height: 125px;
        }
        @media screen and (max-width: 500px) {
            width: 100px;
            height: 100px;
        }
        @media screen and (max-width: 400px) {
            width: 90px;
            height: 90px;
        }
    }

    &__right:hover &__play-button {
        background: white;
    }

    &__svg {
        fill: white;
        stroke: white;
        transition: fill 300ms, stroke 300ms;

        &--play {
            width: 24px;
            height: 32px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            @media screen and (max-width: 1024px) {
                width: 19px;
                height: 27px;
            }
            @media screen and (max-width: 900px) {
                width: 24px;
                height: 32px;
            }
            @media screen and (max-width: 700px) {
                width: 19px;
                height: 27px;
            }
            @media screen and (max-width: 700px) {
                width: 17px;
                height: 25px;
            }
        }

        &--i {
            width: 14px;
            height: 14px;
            position: absolute;
            top: 4px;
            left: 0;
        }
    }

    &__right:hover &__svg {
        &--play {
            fill: $color2;
            stroke: $color2;
        }
    }


    &__note {
        max-width: var(--image-width);
        font-family: $font1;
        font-size: 14px;
        line-height: 1.7;
        color: white;
        padding-left: 30px;
        position: relative;

        @media screen and (max-width: 900px) { max-width: 100%; }
    }
}