/*
|--------------------------------------------------------------------------
| Dynamic
|--------------------------------------------------------------------------
|
| CSS du output du ckeditor provenant du back-end.
|
*/

.dynamic{
  > :first-child { margin-top: 0 !important; }
  > :last-child { margin-bottom: 0 !important; }

  h2 + h3,
  h3 + h4 {
    margin-top: 0;
  }

  h2 {
    @extend .large-title;
    color: $color1;
    margin-top: var(--y-65);
    margin-bottom: var(--y-30);
  }

  h3 {
    @extend .medium-title;
    color: $color1;
    margin-top: var(--y-65);
    margin-bottom: var(--y-30);
  }

  h4 {
    @extend .small-title;
    color: $color1;
    margin-top: var(--y-65);
    margin-bottom: var(--y-30);
  }

  p {
    @include paragraph();
    color: $color1;
    margin-top: var(--y-25);
    margin-bottom: var(--y-25);

    &.buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 15px;

      a.btn{
        margin: 0;
      }

      &--first { margin-top: var(--y-40); }
      &--last { margin-bottom: var(--y-40); }
    }
  }

  pre {
    @extend %note;
    margin: var(--y-25) 0;
  }

  .gallery + pre {
    margin-top: var(--y-25);
  }

  a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image) {
    display: inline;
    padding-bottom: 2px;
    @include paragraph();
    box-shadow: inset 0px -3px 0 rgba($color2, $alpha: 1.0);
    transition: box-shadow 300ms;

    &:hover { box-shadow: inset 0px -20px 0 rgba($color2, $alpha: 0.5); }

    svg {
      width: 11px;
      height: 11px;
      fill: $color1;
      stroke: $color1;
      stroke-width: 2px;
      margin-left: 8px;
    }

    .download { width: 10px; }

    .extern { width: 9px; }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"] {
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  // Link in others elements
  h2,
  h3,
  h4,
  p,
  pre,
  blockquote {
    a:not(.btn-simple):not(.btn-impact):not(.a-style-document):not(.contact__phone-link) {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
    }
  }

  // Link document
  .a-style-document {
    width: 100%;
    max-width: 920px;
    padding: 25px 90px 25px 20px;
    transition: padding 300ms $easeInOutCubic, color 300ms $easeInOutCubic;
    border-bottom: 1px solid $color1;
    position: relative;
    display: block;
    @include paragraph();
    line-height: 1.2;

    @media screen and (max-width: 1024px) { padding-right: 80px; }
    @media screen and (max-width: 768px) { padding-right: 70px; }
    @media screen and (max-width: 600px) { padding-left: 0; }

    &::before {
      content: "";
      width: 18px;
      height: 18px;
      background: url('../medias/images/icons/download.svg') no-repeat;
      opacity: 1;
      transition: opacity 300ms;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);

      @media screen and (max-width: 600px) { right: 30px; }
      @media screen and (max-width: 500px) { right: 15px; }
    }

    &::after {
      content: "";
      width: 18px;
      height: 18px;
      background: url('../medias/images/icons/download-2.svg') no-repeat;
      opacity: 0;
      transition: opacity 300ms;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);

      @media screen and (max-width: 600px) { right: 30px; }
      @media screen and (max-width: 500px) { right: 15px; }
    }

    &:hover {
      padding-left: 40px;
      color: $color2;

      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  p.link-document {
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn {
    margin: 10px 0;
  }

  ol,
  ul {
    margin: var(--y-30) 0;
  }

  ol {
    @extend %ordered-list;
  }

  ul {
    @extend %unordered-list;
  }

  blockquote {
    @extend %quote;
    margin: var(--y-35) 0 !important;
  }

  .tableWrapper {
    @extend %table;
    width: 100%;
    overflow-x: auto;
    margin-top: var(--y-30);
    margin-bottom: var(--y-65);
  }

  .emphasis{
    width: 100%;
    overflow-x: auto;
    padding: var(--y-45) var(--x-40);
    margin-top: var(--y-30);
    margin-bottom: var(--y-65);
    border: 1px solid $color7;
    background: transparent;

    & > :first-child { margin-top: 0; }
    & > :last-child { margin-bottom: 0 }
  }

  .tableWrapper,
  .emphasis {
    & + pre { // Modifier la marge pour les notes sous les tableaux et bloc d'emphases
      margin-top: calc(0px - var(--y-45));
    }

    & + .table-wrapper,
    & + .emphasis { // Modifier la marge pour les tableaux sous les tableaux
      margin-top: calc(0px - var(--y-35));
    }
  }

  strong,
  b {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  sup {
    position: relative;
    top: -0.3em;
    font-size: 1.2rem;
    vertical-align: baseline;
  }

  sub {
    position: relative;
    top: 0.3em;
    font-size: 1.2rem;
    vertical-align: baseline;
  }

  video {
    max-width: 560px;
    max-height: 315px;
    width: 100%;
  }

  /* ----- Image - With Caption ----- */
  img{
    position: relative;
    max-width: 100%;

    &.fr-dib{
      display: block;
      margin: 30px auto;
      float: none;
      vertical-align: top;

      &.fr-fil {
        margin-left: 0;
      }

      &.fr-fir {
        margin-right: 0;
      }
    }

    &.fr-dii{
      display: inline-block;
      float: none;
      vertical-align: bottom;
      margin: 10px 20px;
      max-width: calc(100% - (2 * 20px));

      &.fr-fil {
        float: left;
        margin: 10px 20px 10px 0;
        max-width: calc(100% - 20px);
      }

      &.fr-fir {
        float: right;
        margin: 10px 0 10px 20px;
        max-width: calc(100% - 20px);
      }
    }
  }

  /* ----- Image - With Caption ----- */
  .fr-img-caption{
    img {
      width: 100%;
    }

    &.fr-dib{
      display: block;
      margin-left: auto;
      margin-right: auto;
      float: none;
      vertical-align: middle;
      text-align: center;

      &.fr-fil {
        margin-left: 0;
        text-align: left;
      }

      &.fr-fir {
        margin-right: 0;
        text-align: right;
      }

      .fr-img-wrap {
        .fr-inner {
          display: block;
          line-height: 1.5;
          white-space: normal;
          font: rem(14)/1.7 $font1;
          color: $color5;
          white-space: normal;
        }
      }
    }

    &.fr-dii{
      display: inline-block;
      float: none;
      vertical-align: bottom;
      margin: 10px 20px;
      max-width: calc(100% - (2 * 20px));
      text-align: center;

      &.fr-fil {
        float: left;
        margin-left: 0;
        text-align: left;
        max-width: calc(100% - 20px);
      }

      &.fr-fir {
        float: right;
        margin-right: 0;
        text-align: right;
        max-width: calc(100% - 20px);
      }
    }
  }

  .green {
    color: #7f8b5d;
  }

  .orange {
    color: #e18545;
  }
}
