:root {
    --featured-left-box-width: 500px;

    @media screen and (max-width: 1000px) { --featured-left-box-width: 400px; }
    @media screen and (max-width: 900px) { --featured-left-box-width: 100%; }
}

.featured-news {
    &__slider-container {
        display: flex;
        overflow: hidden;

        @media screen and (max-width: 900px) { flex-direction: column; }
    }

    &__left-box { 
        width: (var(--featured-left-box-width));
        
        @media screen and (max-width: 900px) { 
            display: flex; 
            align-items: center;
            justify-content: space-between;
            padding-right: var(--x-padding-100);
        }
    }

    &__title {
        @extend .large-title;
        color: $color1;
        margin-bottom: 60px;

        @media screen and (max-width: 900px) { 
            margin-bottom: 0; 
            margin-right: 25px;
        }
    }

    &__btn-desktop { 
        margin-bottom: 115px; 

        @media screen and (max-width: 900px) { display: none; }
    }

    &__slider { 
        width: calc(100% - (var(--featured-left-box-width))); 
        padding-top: 80px;
        margin-bottom: 0!important;

        @media screen and (max-width: 900px) { 
            width: 100%;
            padding-top: 30px; 
        }
    }

    &__link {
        padding-top: 30px;
        max-width: 370px;
        margin-right: 60px;
        display: flex;
        flex-direction: column-reverse;

        @media screen and (max-width: 500px) { 
            max-width: 250px; 
            margin-right: 30px;
        }
    }

    &__text { 
        border-top: 1px solid $color4;
        transition: border 300ms $easeInOutCubic;
        padding-top: 30px; 
        margin-top: 30px;
    }

    &__link:hover &__text { border-top: 1px solid #303030 }

    &__date-and-category {
        font-family: $font1;
        font-size: 12px;
        font-weight: 700;
        color: $color3;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    &__news-title {
        font-family: $font1;
        font-size: 25px;
        line-height: 1.3;

        @media screen and (max-width: 500px) { font-size: 20px; }
    }

    &__picture {
        width: 370px;
        height: 210px;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: 500px) {
            width: 250px;
            height: 140px;
        }
    }

    &__img { 
        transition: transform 600ms $easeOut;
    }

    &__link:hover &__img { transform: scale(1.1); }

    &__background { 
        background: rgba($color1, $alpha: 0.5);
        opacity: 0;
        transition: opacity 300ms $easeInOutCubic;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    &__link:hover &__background { opacity: 1; }

    &__background-text {
        font-family: $font1;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &__btn-mobile {
        margin-top: 60px;
        margin-left: 0!important;
        align-self: flex-start;
        display: none;
        @media screen and (max-width: 900px) { display: block; }
    }
}
.slider-nav {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) { display: none; }

    &__link {
        width: 80px;
        height: 80px;
        border: 1px solid $color4;
        position: relative;
    }

    &__prev { 
        transform: rotate(180deg);
        margin-right: 25px;
    }

    &__svg {
        fill: none;
        stroke: $color3;
        stroke-width: 5px;
        width: 10px;
        height: 14px;
        position: absolute;
        top: 49%;
        left: 50%;
        transition: left 300ms $easeInOutCubic;
        transform: translate(-50%,-50%);
    }

    &__link:hover &__svg {
        left: 70%;
    }

    .slideNumber {
        margin-right: 40px;

        .current, .total, .slash {
            font-family: $font1;
            color: $color1;
        }

        .current {
            font-size: 25px;
            font-weight: 700;
        }

        .total { font-size: 20px; }
    }
}

.featured-news-list {

    &__link {
        margin-top: 90px;
        display: flex;
        flex-direction: column-reverse;

        @media screen and (max-width: 600px) { margin-top: 50px; }
        @media screen and (max-width: 400px) { margin-top: 40px; }
    }

    &__link:nth-child(2) { 
        margin-top: 40px;
    
        @media screen and (max-width: 600px) { margin-top: 30px; }
        @media screen and (max-width: 400px) { margin-top: 20px; }
    }

    &__link:last-child { 
        margin-bottom: 100px; 

        @media screen and (max-width: 600px) { margin-bottom: 50px; }
        @media screen and (max-width: 400px) { margin-bottom: 40px; }
    }

    &__text { 
        border-top: 1px solid $color4;
        transition: border 300ms $easeInOutCubic;
        padding-top: 30px; 
        margin-top: 30px;
    }

    &__link:hover &__text { border-top: 1px solid #303030 }

    &__date-and-category {
        font-family: $font1;
        font-size: 12px;
        font-weight: 700;
        color: $color3;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    &__news-title {
        font-family: $font1;
        font-size: 25px;
        line-height: 1.3;
    }

    &__picture {
        width: 100%;
        height: auto;
        position: relative;
        padding-top: 56%;
    }

    &__background { 
        background: rgba($color: #000000, $alpha: 0.5);
        opacity: 0;
        transition: opacity 300ms $easeInOutCubic;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    &__img { 
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__link:hover &__background { opacity: 1; }

    &__background-text {
        font-family: $font1;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}