.section {
    &__container { @extend .left-padding-400; }
    &__container--errors { @extend .x-padding-160; }
    &__container--style {
        @extend .x-padding-260;
        > .gallery{ margin: 65px 0; }
    }
    &__container--banner { padding: 0; }
    &__container--component { @extend .x-padding-100; }
    &__container--component-news { @extend .left-padding-100; }
    &__container--component-none { padding-left: 0; }
    &__container--event-detail { 
        @extend .x-padding-100;
        @extend .bottom-margin-160;
    }
    &__container--news-detail { 
        @extend .left-padding-200;
        @extend .y-margin-160;
    }

    &__content { max-width: 320px; }
    &__content--padding { @extend .x-padding-160; }

    &__center-title { text-align: center }

    &__small-title {
        @extend .small-title;
        @extend .top-margin-65;
        margin-bottom: 25px;
        color: $color1;
    }

    &__medium-title {
        @extend .medium-title;
        @extend .top-margin-65;
        margin-bottom: 110px;
        color: $color1;
    }

    &__large-title {
        @extend .large-title;
        margin-top: 65px;
        margin-bottom: 30px;
        color: $color1;
    }

    &__large-title--jobs {
        margin-bottom: var(--y-margin-60);
    }

    &__large-title--event {
        margin-top: var(--y-margin-190);
        margin-bottom: var(--y-margin-90);
    }

    &__large-title--with-filter { @extend .x-padding-160; }

    &__paragraph {
        @include paragraph();
        margin-bottom: 60px;
    }

    &__no-result {
        margin-bottom: 100px;
    }
}