@charset "UTF-8";
/*** ----- IMPORTS  ----- ***/
@import '../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import '../libraries/overlay.min.css';
/*** ----- TECHNICAL ----- ***/
/*** ----- COLORS ----- ***/
/*** ----- FONTS ----- ***/
/*** ----- EASINGS ----- ***/
/*
|--------------------------------------------------------------------------
| COMMON STYLES
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| FUNCTIONS
|--------------------------------------------------------------------------
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: rgba(127, 139, 93, 0.5);
  color: #373737;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*** Blanko ***/
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

input:focus {
  outline: none;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important;
}

.slick-loading .slick-list {
  background: none;
}

.stripe-loading-indicator .stripe, .stripe-loading-indicator .stripe-loaded {
  display: none !important;
}

.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.grid.gaps {
  width: calc(100% + 1% + 1%);
  margin-left: -1%;
  margin-right: -1%;
}
.grid.gaps .column {
  padding-right: 1%;
  padding-left: 1%;
}

.col1 {
  width: 8.3333333333%;
}

.col2 {
  width: 16.6666666667%;
}

.col3 {
  width: 25%;
}

.col4 {
  width: 33.3333333333%;
}

.col5 {
  width: 41.6666666667%;
}

.col6 {
  width: 50%;
}

.col7 {
  width: 58.3333333333%;
}

.col8 {
  width: 66.6666666667%;
}

.col9 {
  width: 75%;
}

.col10 {
  width: 83.3333333333%;
}

.col11 {
  width: 91.6666666667%;
}

.col12 {
  width: 100%;
}

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 8.3333333333%;
  }
  .col2_1800 {
    width: 16.6666666667%;
  }
  .col3_1800 {
    width: 25%;
  }
  .col4_1800 {
    width: 33.3333333333%;
  }
  .col5_1800 {
    width: 41.6666666667%;
  }
  .col6_1800 {
    width: 50%;
  }
  .col7_1800 {
    width: 58.3333333333%;
  }
  .col8_1800 {
    width: 66.6666666667%;
  }
  .col9_1800 {
    width: 75%;
  }
  .col10_1800 {
    width: 83.3333333333%;
  }
  .col11_1800 {
    width: 91.6666666667%;
  }
  .col12_1800 {
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 8.3333333333%;
  }
  .col2_1700 {
    width: 16.6666666667%;
  }
  .col3_1700 {
    width: 25%;
  }
  .col4_1700 {
    width: 33.3333333333%;
  }
  .col5_1700 {
    width: 41.6666666667%;
  }
  .col6_1700 {
    width: 50%;
  }
  .col7_1700 {
    width: 58.3333333333%;
  }
  .col8_1700 {
    width: 66.6666666667%;
  }
  .col9_1700 {
    width: 75%;
  }
  .col10_1700 {
    width: 83.3333333333%;
  }
  .col11_1700 {
    width: 91.6666666667%;
  }
  .col12_1700 {
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 8.3333333333%;
  }
  .col2_1600 {
    width: 16.6666666667%;
  }
  .col3_1600 {
    width: 25%;
  }
  .col4_1600 {
    width: 33.3333333333%;
  }
  .col5_1600 {
    width: 41.6666666667%;
  }
  .col6_1600 {
    width: 50%;
  }
  .col7_1600 {
    width: 58.3333333333%;
  }
  .col8_1600 {
    width: 66.6666666667%;
  }
  .col9_1600 {
    width: 75%;
  }
  .col10_1600 {
    width: 83.3333333333%;
  }
  .col11_1600 {
    width: 91.6666666667%;
  }
  .col12_1600 {
    width: 100%;
  }
}
@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 8.3333333333%;
  }
  .col2_1500 {
    width: 16.6666666667%;
  }
  .col3_1500 {
    width: 25%;
  }
  .col4_1500 {
    width: 33.3333333333%;
  }
  .col5_1500 {
    width: 41.6666666667%;
  }
  .col6_1500 {
    width: 50%;
  }
  .col7_1500 {
    width: 58.3333333333%;
  }
  .col8_1500 {
    width: 66.6666666667%;
  }
  .col9_1500 {
    width: 75%;
  }
  .col10_1500 {
    width: 83.3333333333%;
  }
  .col11_1500 {
    width: 91.6666666667%;
  }
  .col12_1500 {
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 8.3333333333%;
  }
  .col2_1400 {
    width: 16.6666666667%;
  }
  .col3_1400 {
    width: 25%;
  }
  .col4_1400 {
    width: 33.3333333333%;
  }
  .col5_1400 {
    width: 41.6666666667%;
  }
  .col6_1400 {
    width: 50%;
  }
  .col7_1400 {
    width: 58.3333333333%;
  }
  .col8_1400 {
    width: 66.6666666667%;
  }
  .col9_1400 {
    width: 75%;
  }
  .col10_1400 {
    width: 83.3333333333%;
  }
  .col11_1400 {
    width: 91.6666666667%;
  }
  .col12_1400 {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 8.3333333333%;
  }
  .col2_1300 {
    width: 16.6666666667%;
  }
  .col3_1300 {
    width: 25%;
  }
  .col4_1300 {
    width: 33.3333333333%;
  }
  .col5_1300 {
    width: 41.6666666667%;
  }
  .col6_1300 {
    width: 50%;
  }
  .col7_1300 {
    width: 58.3333333333%;
  }
  .col8_1300 {
    width: 66.6666666667%;
  }
  .col9_1300 {
    width: 75%;
  }
  .col10_1300 {
    width: 83.3333333333%;
  }
  .col11_1300 {
    width: 91.6666666667%;
  }
  .col12_1300 {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 8.3333333333%;
  }
  .col2_1200 {
    width: 16.6666666667%;
  }
  .col3_1200 {
    width: 25%;
  }
  .col4_1200 {
    width: 33.3333333333%;
  }
  .col5_1200 {
    width: 41.6666666667%;
  }
  .col6_1200 {
    width: 50%;
  }
  .col7_1200 {
    width: 58.3333333333%;
  }
  .col8_1200 {
    width: 66.6666666667%;
  }
  .col9_1200 {
    width: 75%;
  }
  .col10_1200 {
    width: 83.3333333333%;
  }
  .col11_1200 {
    width: 91.6666666667%;
  }
  .col12_1200 {
    width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 8.3333333333%;
  }
  .col2_1100 {
    width: 16.6666666667%;
  }
  .col3_1100 {
    width: 25%;
  }
  .col4_1100 {
    width: 33.3333333333%;
  }
  .col5_1100 {
    width: 41.6666666667%;
  }
  .col6_1100 {
    width: 50%;
  }
  .col7_1100 {
    width: 58.3333333333%;
  }
  .col8_1100 {
    width: 66.6666666667%;
  }
  .col9_1100 {
    width: 75%;
  }
  .col10_1100 {
    width: 83.3333333333%;
  }
  .col11_1100 {
    width: 91.6666666667%;
  }
  .col12_1100 {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 8.3333333333%;
  }
  .col2_1024 {
    width: 16.6666666667%;
  }
  .col3_1024 {
    width: 25%;
  }
  .col4_1024 {
    width: 33.3333333333%;
  }
  .col5_1024 {
    width: 41.6666666667%;
  }
  .col6_1024 {
    width: 50%;
  }
  .col7_1024 {
    width: 58.3333333333%;
  }
  .col8_1024 {
    width: 66.6666666667%;
  }
  .col9_1024 {
    width: 75%;
  }
  .col10_1024 {
    width: 83.3333333333%;
  }
  .col11_1024 {
    width: 91.6666666667%;
  }
  .col12_1024 {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .col1_900 {
    width: 8.3333333333%;
  }
  .col2_900 {
    width: 16.6666666667%;
  }
  .col3_900 {
    width: 25%;
  }
  .col4_900 {
    width: 33.3333333333%;
  }
  .col5_900 {
    width: 41.6666666667%;
  }
  .col6_900 {
    width: 50%;
  }
  .col7_900 {
    width: 58.3333333333%;
  }
  .col8_900 {
    width: 66.6666666667%;
  }
  .col9_900 {
    width: 75%;
  }
  .col10_900 {
    width: 83.3333333333%;
  }
  .col11_900 {
    width: 91.6666666667%;
  }
  .col12_900 {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .col1_768 {
    width: 8.3333333333%;
  }
  .col2_768 {
    width: 16.6666666667%;
  }
  .col3_768 {
    width: 25%;
  }
  .col4_768 {
    width: 33.3333333333%;
  }
  .col5_768 {
    width: 41.6666666667%;
  }
  .col6_768 {
    width: 50%;
  }
  .col7_768 {
    width: 58.3333333333%;
  }
  .col8_768 {
    width: 66.6666666667%;
  }
  .col9_768 {
    width: 75%;
  }
  .col10_768 {
    width: 83.3333333333%;
  }
  .col11_768 {
    width: 91.6666666667%;
  }
  .col12_768 {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .col1_700 {
    width: 8.3333333333%;
  }
  .col2_700 {
    width: 16.6666666667%;
  }
  .col3_700 {
    width: 25%;
  }
  .col4_700 {
    width: 33.3333333333%;
  }
  .col5_700 {
    width: 41.6666666667%;
  }
  .col6_700 {
    width: 50%;
  }
  .col7_700 {
    width: 58.3333333333%;
  }
  .col8_700 {
    width: 66.6666666667%;
  }
  .col9_700 {
    width: 75%;
  }
  .col10_700 {
    width: 83.3333333333%;
  }
  .col11_700 {
    width: 91.6666666667%;
  }
  .col12_700 {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .col1_600 {
    width: 8.3333333333%;
  }
  .col2_600 {
    width: 16.6666666667%;
  }
  .col3_600 {
    width: 25%;
  }
  .col4_600 {
    width: 33.3333333333%;
  }
  .col5_600 {
    width: 41.6666666667%;
  }
  .col6_600 {
    width: 50%;
  }
  .col7_600 {
    width: 58.3333333333%;
  }
  .col8_600 {
    width: 66.6666666667%;
  }
  .col9_600 {
    width: 75%;
  }
  .col10_600 {
    width: 83.3333333333%;
  }
  .col11_600 {
    width: 91.6666666667%;
  }
  .col12_600 {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .col1_500 {
    width: 8.3333333333%;
  }
  .col2_500 {
    width: 16.6666666667%;
  }
  .col3_500 {
    width: 25%;
  }
  .col4_500 {
    width: 33.3333333333%;
  }
  .col5_500 {
    width: 41.6666666667%;
  }
  .col6_500 {
    width: 50%;
  }
  .col7_500 {
    width: 58.3333333333%;
  }
  .col8_500 {
    width: 66.6666666667%;
  }
  .col9_500 {
    width: 75%;
  }
  .col10_500 {
    width: 83.3333333333%;
  }
  .col11_500 {
    width: 91.6666666667%;
  }
  .col12_500 {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .col1_425 {
    width: 8.3333333333%;
  }
  .col2_425 {
    width: 16.6666666667%;
  }
  .col3_425 {
    width: 25%;
  }
  .col4_425 {
    width: 33.3333333333%;
  }
  .col5_425 {
    width: 41.6666666667%;
  }
  .col6_425 {
    width: 50%;
  }
  .col7_425 {
    width: 58.3333333333%;
  }
  .col8_425 {
    width: 66.6666666667%;
  }
  .col9_425 {
    width: 75%;
  }
  .col10_425 {
    width: 83.3333333333%;
  }
  .col11_425 {
    width: 91.6666666667%;
  }
  .col12_425 {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .col1_375 {
    width: 8.3333333333%;
  }
  .col2_375 {
    width: 16.6666666667%;
  }
  .col3_375 {
    width: 25%;
  }
  .col4_375 {
    width: 33.3333333333%;
  }
  .col5_375 {
    width: 41.6666666667%;
  }
  .col6_375 {
    width: 50%;
  }
  .col7_375 {
    width: 58.3333333333%;
  }
  .col8_375 {
    width: 66.6666666667%;
  }
  .col9_375 {
    width: 75%;
  }
  .col10_375 {
    width: 83.3333333333%;
  }
  .col11_375 {
    width: 91.6666666667%;
  }
  .col12_375 {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .col1_320 {
    width: 8.3333333333%;
  }
  .col2_320 {
    width: 16.6666666667%;
  }
  .col3_320 {
    width: 25%;
  }
  .col4_320 {
    width: 33.3333333333%;
  }
  .col5_320 {
    width: 41.6666666667%;
  }
  .col6_320 {
    width: 50%;
  }
  .col7_320 {
    width: 58.3333333333%;
  }
  .col8_320 {
    width: 66.6666666667%;
  }
  .col9_320 {
    width: 75%;
  }
  .col10_320 {
    width: 83.3333333333%;
  }
  .col11_320 {
    width: 91.6666666667%;
  }
  .col12_320 {
    width: 100%;
  }
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Italic.ttf");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
/*
|--------------------------------------------------------------------------
| PADDING X
|--------------------------------------------------------------------------
*/
:root {
  --x-padding-400: 400px;
  --x-padding-260: 15vw;
  --x-padding-240: 15vw;
  --x-padding-200: 15vw;
  --x-padding-180: 15vw;
  --x-padding-160: 15vw;
  --x-padding-120: 15vw;
  --x-padding-100: 15vw;
  --x-padding-80: 80px;
  --x-padding-70: 70px;
  --x-padding-50: 50px;
  --x-padding-40: 40px;
}
@media screen and (max-width: 1600px) {
  :root {
    --x-padding-260: 260px;
    --x-padding-240: 240px;
    --x-padding-200: 200px;
    --x-padding-180: 180px;
    --x-padding-160: 160px;
    --x-padding-120: 120px;
    --x-padding-100: 100px;
  }
}
@media screen and (max-width: 1400px) {
  :root {
    --x-padding-260: 220px;
    --x-padding-240: 200px;
    --x-padding-200: 180px;
    --x-padding-180: 160px;
    --x-padding-160: 130px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --x-padding-260: 180px;
    --x-padding-240: 160px;
    --x-padding-200: 120px;
    --x-padding-180: 120px;
    --x-padding-160: 100px;
    --x-padding-120: 100px;
    --x-padding-100: 80px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --x-padding-400: 300px;
    --x-padding-260: 50px;
    --x-padding-240: 50px;
    --x-padding-200: 50px;
    --x-padding-180: 50px;
    --x-padding-160: 50px;
    --x-padding-120: 50px;
    --x-padding-100: 50px;
    --x-padding-80: 50px;
    --x-padding-70: 50px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --x-padding-400: 0;
    --x-padding-260: 30px;
    --x-padding-240: 30px;
    --x-padding-200: 30px;
    --x-padding-180: 30px;
    --x-padding-160: 30px;
    --x-padding-120: 30px;
    --x-padding-100: 30px;
    --x-padding-80: 30px;
    --x-padding-70: 30px;
    --x-padding-50: 30px;
    --x-padding-40: 30px;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-padding-260: 25px;
    --x-padding-240: 25px;
    --x-padding-200: 25px;
    --x-padding-180: 25px;
    --x-padding-160: 25px;
    --x-padding-120: 25px;
    --x-padding-100: 25px;
    --x-padding-80: 25px;
    --x-padding-70: 25px;
    --x-padding-50: 25px;
    --x-padding-40: 25px;
  }
}
@media screen and (max-width: 320px) {
  :root {
    --x-padding-50: 20px;
  }
}

.right-padding-400, .x-padding-400 {
  padding-right: var(--x-padding-400);
}

.left-padding-400, .section__container, .x-padding-400 {
  padding-left: var(--x-padding-400);
}

.right-padding-260, .x-padding-260, .section__container--style {
  padding-right: var(--x-padding-260);
}

.left-padding-260, .x-padding-260, .section__container--style {
  padding-left: var(--x-padding-260);
}

.right-padding-240, .x-padding-240 {
  padding-right: var(--x-padding-240);
}

.left-padding-240, .x-padding-240 {
  padding-left: var(--x-padding-240);
}

.right-padding-200, .x-padding-200, .fast-links__container {
  padding-right: var(--x-padding-200);
}

.left-padding-200, .home-news__container, .section__container--news-detail, .x-padding-200, .fast-links__container {
  padding-left: var(--x-padding-200);
}

.right-padding-180, .x-padding-180 {
  padding-right: var(--x-padding-180);
}

.left-padding-180, .x-padding-180 {
  padding-left: var(--x-padding-180);
}

.right-padding-160, .green-block__container, .x-padding-160, .overlay-search__form, .contact__content, .pagination__container, .section__large-title--with-filter, .section__content--padding, .section__container--errors, .intro__content, .page-title__content, .form-with-filter {
  padding-right: var(--x-padding-160);
}

.left-padding-160, .x-padding-160, .overlay-search__form, .contact__content, .pagination__container, .section__large-title--with-filter, .section__content--padding, .section__container--errors, .intro__content, .page-title__content, .form-with-filter {
  padding-left: var(--x-padding-160);
}

.right-padding-120, .x-padding-120 {
  padding-right: var(--x-padding-120);
}

.left-padding-120, .x-padding-120 {
  padding-left: var(--x-padding-120);
}

.right-padding-100, .x-padding-100, .home-events__container, .section__container--event-detail, .section__container--component {
  padding-right: var(--x-padding-100);
}

.left-padding-100, .section__container--component-news, .x-padding-100, .home-events__container, .section__container--event-detail, .section__container--component {
  padding-left: var(--x-padding-100);
}

.right-padding-80, .x-padding-80 {
  padding-right: var(--x-padding-80);
}

.left-padding-80, .x-padding-80 {
  padding-left: var(--x-padding-80);
}

.right-padding-70, .x-padding-70, .header__container {
  padding-right: var(--x-padding-70);
}

.left-padding-70, .breadcrumb__container, .x-padding-70, .header__container {
  padding-left: var(--x-padding-70);
}

.right-padding-50, .x-padding-50 {
  padding-right: var(--x-padding-50);
}

.left-padding-50, .x-padding-50 {
  padding-left: var(--x-padding-50);
}

.right-padding-40, .x-padding-40 {
  padding-right: var(--x-padding-40);
}

.left-padding-40, .x-padding-40 {
  padding-left: var(--x-padding-40);
}

/*
|--------------------------------------------------------------------------
| PADDING Y
|--------------------------------------------------------------------------
*/
:root {
  --y-padding-300: 300px;
  --y-padding-180: 180px;
  --y-padding-160: 160px;
  --y-padding-150: 150px;
  --y-padding-130: 130px;
  --y-padding-120: 120px;
  --y-padding-100: 100px;
  --y-padding-90: 90px;
  --y-padding-80: 80px;
  --y-padding-70: 70px;
  --y-padding-50: 50px;
  --y-padding-40: 40px;
  --y-padding-30: 30px;
  --y-padding-20: 20px;
}
@media screen and (max-width: 1400px) {
  :root {
    --y-padding-300: 240px;
    --y-padding-180: 160px;
    --y-padding-160: 140px;
    --y-padding-150: 130px;
    --y-padding-130: 120px;
    --y-padding-120: 110px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-padding-300: 220px;
    --y-padding-180: 140px;
    --y-padding-160: 130px;
    --y-padding-150: 120px;
    --y-padding-130: 110px;
    --y-padding-120: 110px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-padding-300: 200px;
    --y-padding-180: 130px;
    --y-padding-160: 120px;
    --y-padding-150: 110px;
    --y-padding-130: 100px;
    --y-padding-120: 100px;
    --y-padding-100: 90px;
    --y-padding-90: 80px;
    --y-padding-80: 70px;
    --y-padding-50: 40px;
  }
}
@media screen and (max-width: 900px) {
  :root {
    --y-padding-300: 0;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-padding-180: 100px;
    --y-padding-160: 100px;
    --y-padding-150: 90px;
    --y-padding-130: 80px;
    --y-padding-120: 80px;
    --y-padding-100: 80px;
    --y-padding-90: 70px;
    --y-padding-80: 60px;
    --y-padding-70: 40px;
    --y-padding-50: 30px;
    --y-padding-40: 30px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-padding-180: 50px;
    --y-padding-160: 50px;
    --y-padding-150: 50px;
    --y-padding-130: 50px;
    --y-padding-120: 50px;
    --y-padding-100: 50px;
    --y-padding-90: 50px;
    --y-padding-80: 50px;
    --y-padding-70: 30px;
  }
}

.top-padding-300, .y-padding-300 {
  padding-top: var(--y-padding-300);
}

.bottom-padding-300, .y-padding-300 {
  padding-bottom: var(--y-padding-300);
}

.top-padding-180, .y-padding-180 {
  padding-top: var(--y-padding-180);
}

.bottom-padding-180, .home-news__container, .fast-links__container, .y-padding-180 {
  padding-bottom: var(--y-padding-180);
}

.top-padding-160, .y-padding-160, .green-block__container {
  padding-top: var(--y-padding-160);
}

.bottom-padding-160, .y-padding-160, .green-block__container {
  padding-bottom: var(--y-padding-160);
}

.top-padding-150, .fast-links__container, .page-title__container--style, .y-padding-150 {
  padding-top: var(--y-padding-150);
}

.bottom-padding-150, .y-padding-150 {
  padding-bottom: var(--y-padding-150);
}

.top-padding-130, .y-padding-130, .home-events__container {
  padding-top: var(--y-padding-130);
}

.bottom-padding-130, .y-padding-130, .home-events__container {
  padding-bottom: var(--y-padding-130);
}

.top-padding-120, .y-padding-120 {
  padding-top: var(--y-padding-120);
}

.bottom-padding-120, .y-padding-120 {
  padding-bottom: var(--y-padding-120);
}

.top-padding-100, .y-padding-100 {
  padding-top: var(--y-padding-100);
}

.bottom-padding-100, .y-padding-100 {
  padding-bottom: var(--y-padding-100);
}

.top-padding-90, .page-title__content, .y-padding-90 {
  padding-top: var(--y-padding-90);
}

.bottom-padding-90, .y-padding-90 {
  padding-bottom: var(--y-padding-90);
}

.top-padding-80, .y-padding-80 {
  padding-top: var(--y-padding-80);
}

.bottom-padding-80, .y-padding-80 {
  padding-bottom: var(--y-padding-80);
}

.top-padding-70, .y-padding-70 {
  padding-top: var(--y-padding-70);
}

.bottom-padding-70, .y-padding-70 {
  padding-bottom: var(--y-padding-70);
}

.top-padding-50, .y-padding-50, .contact__content {
  padding-top: var(--y-padding-50);
}

.bottom-padding-50, .y-padding-50, .contact__content {
  padding-bottom: var(--y-padding-50);
}

.top-padding-40, .y-padding-40 {
  padding-top: var(--y-padding-40);
}

.bottom-padding-40, .y-padding-40 {
  padding-bottom: var(--y-padding-40);
}

.top-padding-30, .y-padding-30 {
  padding-top: var(--y-padding-30);
}

.bottom-padding-30, .y-padding-30 {
  padding-bottom: var(--y-padding-30);
}

.top-padding-20, .y-padding-20 {
  padding-top: var(--y-padding-20);
}

.bottom-padding-20, .y-padding-20 {
  padding-bottom: var(--y-padding-20);
}

/*
|--------------------------------------------------------------------------
| margin Y
|--------------------------------------------------------------------------
*/
:root {
  --y-margin-210: 210px;
  --y-margin-190: 190px;
  --y-margin-160: 160px;
  --y-margin-110: 110px;
  --y-margin-90: 90px;
  --y-margin-70: 70px;
  --y-margin-65: 65px;
  --y-margin-60: 60px;
  --y-margin-50: 50px;
  --y-margin-40: 40px;
  --y-margin-30: 30px;
  --y-margin-20: 20px;
}
@media screen and (max-width: 1400px) {
  :root {
    --y-margin-210: 180px;
    --y-margin-190: 170px;
    --y-margin-160: 120px;
    --y-margin-110: 90px;
    --y-margin-90: 80px;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --y-margin-210: 120px;
    --y-margin-190: 100px;
    --y-margin-160: 90px;
    --y-margin-110: 80px;
    --y-margin-90: 70px;
    --y-margin-70: 60px;
    --y-margin-65: 60px;
    --y-margin-60: 50px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --y-margin-210: 60px;
    --y-margin-190: 60px;
    --y-margin-160: 60px;
    --y-margin-110: 50px;
    --y-margin-90: 50px;
    --y-margin-70: 50px;
    --y-margin-65: 50px;
    --y-margin-60: 40px;
    --y-margin-50: 40px;
    --y-margin-40: 30px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --y-margin-210: 50px;
    --y-margin-190: 50px;
    --y-margin-160: 50px;
    --y-margin-110: 40px;
    --y-margin-90: 40px;
    --y-margin-70: 40px;
    --y-margin-65: 40px;
    --y-margin-60: 30px;
    --y-margin-50: 30px;
    --y-margin-30: 25px;
    --y-margin-20: 15px;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --y-margin-210: 40px;
    --y-margin-190: 40px;
    --y-margin-160: 40px;
    --y-margin-110: 30px;
    --y-margin-90: 30px;
    --y-margin-70: 30px;
    --y-margin-65: 30px;
    --y-margin-60: 25px;
    --y-margin-50: 25px;
    --y-margin-40: 20px;
    --y-margin-30: 15px;
    --y-margin-20: 10px;
  }
}

.top-margin-210, .y-margin-210 {
  margin-top: var(--y-margin-210);
}

.bottom-margin-210, .y-margin-210 {
  margin-bottom: var(--y-margin-210);
}

.top-margin-190, .y-margin-190 {
  margin-top: var(--y-margin-190);
}

.bottom-margin-190, .y-margin-190 {
  margin-bottom: var(--y-margin-190);
}

.top-margin-160, .y-margin-160, .section__container--news-detail {
  margin-top: var(--y-margin-160);
}

.bottom-margin-160, .section__container--event-detail, .y-margin-160, .section__container--news-detail {
  margin-bottom: var(--y-margin-160);
}

.top-margin-110, .y-margin-110 {
  margin-top: var(--y-margin-110);
}

.bottom-margin-110, .jobs, .rubrics, .document-gallery, .y-margin-110 {
  margin-bottom: var(--y-margin-110);
}

.top-margin-90, .y-margin-90 {
  margin-top: var(--y-margin-90);
}

.bottom-margin-90, .y-margin-90 {
  margin-bottom: var(--y-margin-90);
}

.top-margin-70, .y-margin-70 {
  margin-top: var(--y-margin-70);
}

.bottom-margin-70, .y-margin-70 {
  margin-bottom: var(--y-margin-70);
}

.top-margin-65, .section__medium-title, .section__small-title, .y-margin-65 {
  margin-top: var(--y-margin-65);
}

.bottom-margin-65, .y-margin-65 {
  margin-bottom: var(--y-margin-65);
}

.top-margin-60, .y-margin-60 {
  margin-top: var(--y-margin-60);
}

.bottom-margin-60, .y-margin-60 {
  margin-bottom: var(--y-margin-60);
}

.top-margin-50, .y-margin-50 {
  margin-top: var(--y-margin-50);
}

.bottom-margin-50, .y-margin-50 {
  margin-bottom: var(--y-margin-50);
}

.top-margin-40, .y-margin-40 {
  margin-top: var(--y-margin-40);
}

.bottom-margin-40, .y-margin-40 {
  margin-bottom: var(--y-margin-40);
}

.top-margin-30, .y-margin-30 {
  margin-top: var(--y-margin-30);
}

.bottom-margin-30, .y-margin-30 {
  margin-bottom: var(--y-margin-30);
}

.top-margin-20, .y-margin-20 {
  margin-top: var(--y-margin-20);
}

.bottom-margin-20, .y-margin-20 {
  margin-bottom: var(--y-margin-20);
}

/*
|--------------------------------------------------------------------------
| margin Y - init theme
|--------------------------------------------------------------------------
*/
:root {
  --y-100: 100px;
  --y-90: 90px;
  --y-65: 65px;
  --y-60: 60px;
  --y-50: 50px;
  --y-45: 45px;
  --y-40: 40px;
  --y-35: 35px;
  --y-30: 30px;
  --y-25: 25px;
  --y-20: 20px;
}
@media screen and (max-width: 1440px) {
  :root {
    --y-100: calc(55px + (100 - 55) * ((100vw - 320px) / (1440 - 320)));
    --y-90: calc(50px + (90 - 50) * ((100vw - 600px) / (1440 - 600)));
    --y-65: calc(40px + (65 - 40) * ((100vw - 600px) / (1440 - 600)));
    --y-60: calc(40px + (60 - 40) * ((100vw - 600px) / (1440 - 600)));
    --y-50: calc(33px + (50 - 33) * ((100vw - 600px) / (1440 - 600)));
    --y-45: calc(29px + (45 - 29) * ((100vw - 600px) / (1440 - 600)));
    --y-40: calc(26px + (40 - 26) * ((100vw - 600px) / (1440 - 600)));
    --y-35: calc(23px + (35 - 23) * ((100vw - 600px) / (1440 - 600)));
    --y-30: calc(20px + (30 - 20) * ((100vw - 600px) / (1440 - 600)));
    --y-25: calc(17px + (25 - 17) * ((100vw - 600px) / (1440 - 600)));
    --y-20: calc(15px + (20 - 15) * ((100vw - 600px) / (1440 - 600)));
  }
}
@media screen and (max-width: 600px) {
  :root {
    --y-100: 55px;
    --y-90: 50px;
    --y-65: 40px;
    --y-60: 40px;
    --y-50: 33px;
    --y-45: 29px;
    --y-40: 26px;
    --y-35: 23px;
    --y-30: 20px;
    --y-25: 17px;
    --y-20: 15px;
  }
}

/*
|--------------------------------------------------------------------------
| margin X - init theme
|--------------------------------------------------------------------------
*/
:root {
  --x-180: 180px;
  --x-40: 40px;
}
@media screen and (max-width: 1440px) {
  :root {
    --x-180: calc(25px + (180 - 25) * ((100vw - 600px) / (1440 - 600)));
    --x-40: calc(25px + (40 - 25) * ((100vw - 600px) / (1440 - 600)));
  }
}
@media screen and (max-width: 600px) {
  :root {
    --x-180: 25px;
    --x-40: 25px;
  }
}
@media screen and (max-width: 320px) {
  :root {
    --x-180: 22px;
    --x-40: 22px;
  }
}

/*
|--------------------------------------------------------------------------
| IMAGES
|--------------------------------------------------------------------------
*/
picture {
  display: block;
  font-size: 0;
}
picture img {
  width: 100%;
  height: 100%;
}
picture [data-object-fit=cover] {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.huge-title, .home-banner__title {
  font-family: "Roboto", sans-serif;
  line-height: 1.15;
  font-weight: 700;
  color: #373737;
}
@media screen and (min-width: 1440px) {
  .huge-title, .home-banner__title {
    font-size: 4.375rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .huge-title, .home-banner__title {
    font-size: calc(3.125rem + (70 - 50) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .huge-title, .home-banner__title {
    font-size: 3.125rem;
  }
}
@media screen and (max-width: 400px) {
  .huge-title, .home-banner__title {
    font-size: 40px;
  }
}
@media screen and (max-width: 320px) {
  .huge-title, .home-banner__title {
    font-size: 30px;
  }
}

.smaller {
  font-size: 30px;
}

.large-title, .overlay-share__title, .green-block__title, .fast-links__title, .featured-events-list__title, .featured-events__title, .featured-news__title, .vitrine__title, .section__large-title, .dynamic h2 {
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
  font-weight: 700;
  color: #373737;
}
@media screen and (min-width: 1440px) {
  .large-title, .overlay-share__title, .green-block__title, .fast-links__title, .featured-events-list__title, .featured-events__title, .featured-news__title, .vitrine__title, .section__large-title, .dynamic h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .large-title, .overlay-share__title, .green-block__title, .fast-links__title, .featured-events-list__title, .featured-events__title, .featured-news__title, .vitrine__title, .section__large-title, .dynamic h2 {
    font-size: calc(2.1875rem + (40 - 35) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .large-title, .overlay-share__title, .green-block__title, .fast-links__title, .featured-events-list__title, .featured-events__title, .featured-news__title, .vitrine__title, .section__large-title, .dynamic h2 {
    font-size: 2.1875rem;
  }
}

.medium-title, .fast-links__link-text, .home-banner__medium-title, .section__medium-title, .field--newsletter-title, .dynamic h3 {
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  font-weight: 700;
  color: #373737;
}
@media screen and (min-width: 1440px) {
  .medium-title, .fast-links__link-text, .home-banner__medium-title, .section__medium-title, .field--newsletter-title, .dynamic h3 {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .medium-title, .fast-links__link-text, .home-banner__medium-title, .section__medium-title, .field--newsletter-title, .dynamic h3 {
    font-size: calc(1.25rem + (30 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .medium-title, .fast-links__link-text, .home-banner__medium-title, .section__medium-title, .field--newsletter-title, .dynamic h3 {
    font-size: 1.25rem;
  }
}

.small-title, .section__small-title, .form__title, .dynamic h4 {
  font-family: "Roboto", sans-serif;
  line-height: 1.4;
  font-weight: 500;
  color: #373737;
}
@media screen and (min-width: 1440px) {
  .small-title, .section__small-title, .form__title, .dynamic h4 {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .small-title, .section__small-title, .form__title, .dynamic h4 {
    font-size: calc(1.125rem + (25 - 18) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .small-title, .section__small-title, .form__title, .dynamic h4 {
    font-size: 1.125rem;
  }
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  color: #373737;
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  color: #373737;
}

/*
|--------------------------------------------------------------------------
| Dynamic
|--------------------------------------------------------------------------
|
| CSS du output du ckeditor provenant du back-end.
|
*/
.dynamic {
  /* ----- Image - With Caption ----- */
  /* ----- Image - With Caption ----- */
}
.dynamic > :first-child {
  margin-top: 0 !important;
}
.dynamic > :last-child {
  margin-bottom: 0 !important;
}
.dynamic h2 + h3,
.dynamic h3 + h4 {
  margin-top: 0;
}
.dynamic h2 {
  color: #373737;
  margin-top: var(--y-65);
  margin-bottom: var(--y-30);
}
.dynamic h3 {
  color: #373737;
  margin-top: var(--y-65);
  margin-bottom: var(--y-30);
}
.dynamic h4 {
  color: #373737;
  margin-top: var(--y-65);
  margin-bottom: var(--y-30);
}
.dynamic p {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  color: #373737;
  margin-top: var(--y-25);
  margin-bottom: var(--y-25);
}
.dynamic p.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 15px;
}
.dynamic p.buttons a.btn {
  margin: 0;
}
.dynamic p.buttons--first {
  margin-top: var(--y-40);
}
.dynamic p.buttons--last {
  margin-bottom: var(--y-40);
}
.dynamic pre {
  margin: var(--y-25) 0;
}
.dynamic .gallery + pre {
  margin-top: var(--y-25);
}
.dynamic a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image) {
  display: inline;
  padding-bottom: 2px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  box-shadow: inset 0px -3px 0 #7f8b5d;
  transition: box-shadow 300ms;
}
.dynamic a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image):hover {
  box-shadow: inset 0px -20px 0 rgba(127, 139, 93, 0.5);
}
.dynamic a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image) svg {
  width: 11px;
  height: 11px;
  fill: #373737;
  stroke: #373737;
  stroke-width: 2px;
  margin-left: 8px;
}
.dynamic a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image) .download {
  width: 10px;
}
.dynamic a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image) .extern {
  width: 9px;
}
.dynamic a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image)[href$=".pdf"] svg, .dynamic a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image)[href$=".doc"] svg, .dynamic a:not(.link):not(.btn-impact):not(.btn-simple):not(.contact__phone-link):not(.a-style-document):not(.link-image)[href$=".zip"] svg {
  width: 12px;
  height: 12px;
}
.dynamic h2 a:not(.btn-simple):not(.btn-impact):not(.a-style-document):not(.contact__phone-link),
.dynamic h3 a:not(.btn-simple):not(.btn-impact):not(.a-style-document):not(.contact__phone-link),
.dynamic h4 a:not(.btn-simple):not(.btn-impact):not(.a-style-document):not(.contact__phone-link),
.dynamic p a:not(.btn-simple):not(.btn-impact):not(.a-style-document):not(.contact__phone-link),
.dynamic pre a:not(.btn-simple):not(.btn-impact):not(.a-style-document):not(.contact__phone-link),
.dynamic blockquote a:not(.btn-simple):not(.btn-impact):not(.a-style-document):not(.contact__phone-link) {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
}
.dynamic .a-style-document {
  width: 100%;
  max-width: 920px;
  padding: 25px 90px 25px 20px;
  transition: padding 300ms cubic-bezier(0.76, 0, 0.24, 1), color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  border-bottom: 1px solid #373737;
  position: relative;
  display: block;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  line-height: 1.2;
}
@media screen and (max-width: 1024px) {
  .dynamic .a-style-document {
    padding-right: 80px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic .a-style-document {
    padding-right: 70px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic .a-style-document {
    padding-left: 0;
  }
}
.dynamic .a-style-document::before {
  content: "";
  width: 18px;
  height: 18px;
  background: url("../medias/images/icons/download.svg") no-repeat;
  opacity: 1;
  transition: opacity 300ms;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
@media screen and (max-width: 600px) {
  .dynamic .a-style-document::before {
    right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic .a-style-document::before {
    right: 15px;
  }
}
.dynamic .a-style-document::after {
  content: "";
  width: 18px;
  height: 18px;
  background: url("../medias/images/icons/download-2.svg") no-repeat;
  opacity: 0;
  transition: opacity 300ms;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
@media screen and (max-width: 600px) {
  .dynamic .a-style-document::after {
    right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic .a-style-document::after {
    right: 15px;
  }
}
.dynamic .a-style-document:hover {
  padding-left: 40px;
  color: #7f8b5d;
}
.dynamic .a-style-document:hover::before {
  opacity: 0;
}
.dynamic .a-style-document:hover::after {
  opacity: 1;
}
.dynamic p.link-document {
  margin-top: 0;
  margin-bottom: 0;
}
.dynamic .btn {
  margin: 10px 0;
}
.dynamic ol,
.dynamic ul {
  margin: var(--y-30) 0;
}
.dynamic blockquote {
  margin: var(--y-35) 0 !important;
}
.dynamic .tableWrapper {
  width: 100%;
  overflow-x: auto;
  margin-top: var(--y-30);
  margin-bottom: var(--y-65);
}
.dynamic .emphasis {
  width: 100%;
  overflow-x: auto;
  padding: var(--y-45) var(--x-40);
  margin-top: var(--y-30);
  margin-bottom: var(--y-65);
  border: 1px solid #b2b2b2;
  background: transparent;
}
.dynamic .emphasis > :first-child {
  margin-top: 0;
}
.dynamic .emphasis > :last-child {
  margin-bottom: 0;
}
.dynamic .tableWrapper + pre,
.dynamic .emphasis + pre {
  margin-top: calc(0px - var(--y-45));
}
.dynamic .tableWrapper + .table-wrapper, .dynamic .tableWrapper + .emphasis,
.dynamic .emphasis + .table-wrapper,
.dynamic .emphasis + .emphasis {
  margin-top: calc(0px - var(--y-35));
}
.dynamic strong,
.dynamic b {
  font-weight: 700;
}
.dynamic em,
.dynamic i {
  font-style: italic;
}
.dynamic u {
  text-decoration: underline;
}
.dynamic sup {
  position: relative;
  top: -0.3em;
  font-size: 1.2rem;
  vertical-align: baseline;
}
.dynamic sub {
  position: relative;
  top: 0.3em;
  font-size: 1.2rem;
  vertical-align: baseline;
}
.dynamic video {
  max-width: 560px;
  max-height: 315px;
  width: 100%;
}
.dynamic img {
  position: relative;
  max-width: 100%;
}
.dynamic img.fr-dib {
  display: block;
  margin: 30px auto;
  float: none;
  vertical-align: top;
}
.dynamic img.fr-dib.fr-fil {
  margin-left: 0;
}
.dynamic img.fr-dib.fr-fir {
  margin-right: 0;
}
.dynamic img.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin: 10px 20px;
  max-width: calc(100% - 40px);
}
.dynamic img.fr-dii.fr-fil {
  float: left;
  margin: 10px 20px 10px 0;
  max-width: calc(100% - 20px);
}
.dynamic img.fr-dii.fr-fir {
  float: right;
  margin: 10px 0 10px 20px;
  max-width: calc(100% - 20px);
}
.dynamic .fr-img-caption img {
  width: 100%;
}
.dynamic .fr-img-caption.fr-dib {
  display: block;
  margin-left: auto;
  margin-right: auto;
  float: none;
  vertical-align: middle;
  text-align: center;
}
.dynamic .fr-img-caption.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
.dynamic .fr-img-caption.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
.dynamic .fr-img-caption.fr-dib .fr-img-wrap .fr-inner {
  display: block;
  line-height: 1.5;
  white-space: normal;
  font: 0.875rem/1.7 "Roboto", sans-serif;
  color: #898989;
  white-space: normal;
}
.dynamic .fr-img-caption.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin: 10px 20px;
  max-width: calc(100% - 40px);
  text-align: center;
}
.dynamic .fr-img-caption.fr-dii.fr-fil {
  float: left;
  margin-left: 0;
  text-align: left;
  max-width: calc(100% - 20px);
}
.dynamic .fr-img-caption.fr-dii.fr-fir {
  float: right;
  margin-right: 0;
  text-align: right;
  max-width: calc(100% - 20px);
}
.dynamic .green {
  color: #7f8b5d;
}
.dynamic .orange {
  color: #e18545;
}

/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
|
| Déclaration de tous les titres, textes, boutons et liens réutilisables
| du projet.
|
*/
.note, .dynamic pre {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.7em;
  color: #898989;
  white-space: normal;
}

.quote, .dynamic blockquote {
  padding-left: 30px;
  border-left: 10px solid #7f8b5d;
}
@media screen and (max-width: 1024px) {
  .quote, .dynamic blockquote {
    padding-left: 25px;
    border-width: 8px;
  }
}
@media screen and (max-width: 768px) {
  .quote, .dynamic blockquote {
    padding-left: 20px;
    border-width: 6px;
  }
}
@media screen and (max-width: 500px) {
  .quote, .dynamic blockquote {
    padding-left: 15px;
    border-width: 4px;
  }
}
.quote, .dynamic blockquote, .quote p, .dynamic blockquote p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  line-height: 1.5em;
}
@media screen and (min-width: 1440px) {
  .quote, .dynamic blockquote, .quote p, .dynamic blockquote p {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .quote, .dynamic blockquote, .quote p, .dynamic blockquote p {
    font-size: calc(1.125rem + (22 - 18) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .quote, .dynamic blockquote, .quote p, .dynamic blockquote p {
    font-size: 1.125rem;
  }
}

.ordered-list li, .dynamic ol li, .unordered-list li, .dynamic ul li {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  --paragraph-line-height: 1.6em;
}
.ordered-list li:not(:first-child), .dynamic ol li:not(:first-child), .unordered-list li:not(:first-child), .dynamic ul li:not(:first-child) {
  margin-top: var(--y-25);
}
.ordered-list li:first-child > p:first-child, .dynamic ol li:first-child > p:first-child, .unordered-list li:first-child > p:first-child, .dynamic ul li:first-child > p:first-child {
  margin-top: 0;
}
.ordered-list li:last-child > p:last-child, .dynamic ol li:last-child > p:last-child, .unordered-list li:last-child > p:last-child, .dynamic ul li:last-child > p:last-child {
  margin-bottom: 0;
}
.ordered-list li ol, .dynamic ol li ol, .unordered-list li ol, .dynamic ul li ol,
.ordered-list li ul,
.dynamic ol li ul,
.unordered-list li ul,
.dynamic ul li ul {
  width: 100%;
  margin-top: var(--y-25);
  margin-bottom: 0;
}
.ordered-list li ol li:not(:first-child), .dynamic ol li ol li:not(:first-child), .unordered-list li ol li:not(:first-child), .dynamic ul li ol li:not(:first-child),
.ordered-list li ul li:not(:first-child),
.dynamic ol li ul li:not(:first-child),
.unordered-list li ul li:not(:first-child),
.dynamic ul li ul li:not(:first-child) {
  margin-top: var(--y-20);
}

.unordered-list > li, .dynamic ul > li {
  position: relative;
  padding-left: 25px;
}
@media screen and (max-width: 500px) {
  .unordered-list > li, .dynamic ul > li {
    padding-left: 15px;
  }
}
.unordered-list > li::before, .dynamic ul > li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  top: calc(var(--paragraph-line-height) / 2);
  left: 0;
  background-color: #7f8b5d;
  border: 1px solid #7f8b5d;
  border-radius: 50%;
  box-sizing: border-box;
  transform: translate(0, -50%);
}
.unordered-list > li > ul li::before, .dynamic ul > li > ul li::before {
  background-color: transparent;
}
.unordered-list > li > ul li > ul li::before, .dynamic ul > li > ul li > ul li::before {
  background-color: #7f8b5d;
}

.ordered-list, .dynamic ol {
  list-style: none;
  counter-reset: li;
}
.ordered-list > li, .dynamic ol > li {
  position: relative;
  padding-left: 45px;
}
@media screen and (max-width: 768px) {
  .ordered-list > li, .dynamic ol > li {
    padding-left: 25px;
  }
}
@media screen and (max-width: 500px) {
  .ordered-list > li, .dynamic ol > li {
    padding-left: 20px;
  }
}
.ordered-list > li::before, .dynamic ol > li::before {
  content: counter(li) ".";
  counter-increment: li;
  display: inline-block;
  position: absolute;
  top: calc(var(--paragraph-line-height) / 2);
  left: 0;
  font-size: 17px;
  font-weight: 700;
  color: #7f8b5d;
  transform: translate(0, -50%);
}
@media screen and (max-width: 768px) {
  .ordered-list > li::before, .dynamic ol > li::before {
    font-size: 16px;
  }
}
.ordered-list > li ol > li::before, .dynamic ol > li ol > li::before {
  content: counter(li, lower-alpha) ".";
}
@media screen and (min-width: 769px) {
  .ordered-list > li ol > li ol > li, .dynamic ol > li ol > li ol > li {
    padding-left: 40px;
  }
}
.ordered-list > li ol > li ol > li::before, .dynamic ol > li ol > li ol > li::before {
  content: counter(li, lower-roman) ".";
}

.table table, .dynamic .tableWrapper table {
  width: auto;
  white-space: nowrap;
  overflow-x: hidden;
  table-layout: fixed;
}
.table table thead tr th, .dynamic .tableWrapper table thead tr th, .table table thead tr td, .dynamic .tableWrapper table thead tr td {
  border: 1px solid #b2b2b2;
  border-bottom: none;
  padding: 17px 30px;
  color: #fff;
  background: #7f8b5d;
  white-space: normal;
  text-align: left;
  vertical-align: middle !important;
}
@media screen and (max-width: 768px) {
  .table table thead tr th, .dynamic .tableWrapper table thead tr th, .table table thead tr td, .dynamic .tableWrapper table thead tr td {
    font-size: 1rem;
  }
}
.table table thead tr th, .dynamic .tableWrapper table thead tr th, .table table thead tr th p, .dynamic .tableWrapper table thead tr th p, .table table thead tr th h4, .dynamic .tableWrapper table thead tr th h4, .table table thead tr th h3, .dynamic .tableWrapper table thead tr th h3, .table table thead tr th h2, .dynamic .tableWrapper table thead tr th h2, .table table thead tr td, .dynamic .tableWrapper table thead tr td, .table table thead tr td p, .dynamic .tableWrapper table thead tr td p, .table table thead tr td h4, .dynamic .tableWrapper table thead tr td h4, .table table thead tr td h3, .dynamic .tableWrapper table thead tr td h3, .table table thead tr td h2, .dynamic .tableWrapper table thead tr td h2 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 17px;
}
.table table tbody:first-child(), .dynamic .tableWrapper table tbody:first-child() {
  border-top: 1px solid #b2b2b2;
}
.table table tbody tr td.style1, .dynamic .tableWrapper table tbody tr td.style1, .table table tbody tr th.style1, .dynamic .tableWrapper table tbody tr th.style1 {
  background: #e6eaf2;
}
.table table tbody tr td.header, .dynamic .tableWrapper table tbody tr td.header, .table table tbody tr th.header, .dynamic .tableWrapper table tbody tr th.header {
  border: 1px solid #b2b2b2;
  border-bottom: none;
  padding: 10px 30px;
  color: #fff;
  background: #7f8b5d;
  white-space: normal;
  text-align: left;
}
.table table tbody tr td:first-child, .dynamic .tableWrapper table tbody tr td:first-child, .table table tbody tr th:first-child, .dynamic .tableWrapper table tbody tr th:first-child {
  border-left: 1px solid #b2b2b2;
}
.table table tbody tr:last-child td, .dynamic .tableWrapper table tbody tr:last-child td, .table table tbody tr:last-child th, .dynamic .tableWrapper table tbody tr:last-child th {
  border-bottom: 1px solid #b2b2b2;
}
.table table tbody tr td, .dynamic .tableWrapper table tbody tr td, .table table tbody tr th, .dynamic .tableWrapper table tbody tr th {
  padding: 17px 30px;
  margin: 0;
  font: 1.0625rem/1.5 "Roboto", sans-serif;
  font-weight: normal;
  color: #373737;
  white-space: normal;
  border-right: 1px solid #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
  vertical-align: middle !important;
}
@media screen and (max-width: 768px) {
  .table table tbody tr td, .dynamic .tableWrapper table tbody tr td, .table table tbody tr th, .dynamic .tableWrapper table tbody tr th {
    font-size: 1rem;
  }
}
.table table tbody tr td > :first-child, .dynamic .tableWrapper table tbody tr td > :first-child, .table table tbody tr th > :first-child, .dynamic .tableWrapper table tbody tr th > :first-child {
  margin-top: 0 !important;
}
.table table tbody tr td > :last-child, .dynamic .tableWrapper table tbody tr td > :last-child, .table table tbody tr th > :last-child, .dynamic .tableWrapper table tbody tr th > :last-child {
  margin-bottom: 0 !important;
}
.table table tbody tr td p, .dynamic .tableWrapper table tbody tr td p, .table table tbody tr th p, .dynamic .tableWrapper table tbody tr th p {
  font: 1.0625rem/1.5 "Roboto", sans-serif;
  color: #373737;
}
@media screen and (max-width: 768px) {
  .table table tbody tr td p, .dynamic .tableWrapper table tbody tr td p, .table table tbody tr th p, .dynamic .tableWrapper table tbody tr th p {
    font-size: 1rem;
  }
}
.page-container {
  position: relative;
}

.show-alerts .alerts {
  top: 0;
}
.show-alerts .header {
  margin-top: 45px;
}

.form__title {
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .form__title {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  .form__title {
    margin-bottom: 20px;
  }
}
.form__grid-gaps {
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
}
@media screen and (max-width: 1200px) {
  .form__grid-gaps {
    width: calc(100% + 50px);
    margin-right: -25px;
    margin-left: -25px;
  }
}
@media screen and (max-width: 1024px) {
  .form__grid-gaps {
    width: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media screen and (max-width: 900px) {
  .form__grid-gaps {
    width: calc(100% + 10px);
    margin-right: -5px;
    margin-left: -5px;
  }
}
.form__column {
  padding-right: 40px;
  padding-left: 40px;
}
@media screen and (max-width: 1200px) {
  .form__column {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .form__column {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 900px) {
  .form__column {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.form .oc-loading {
  pointer-events: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
  -webkit-text-fill-color: black;
}

#form-newsletter input:-webkit-autofill,
#form-newsletter input:-webkit-autofill:hover,
#form-newsletter input:-webkit-autofill:focus,
#form-newsletter input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #7f8b5d inset !important;
  caret-color: white;
}

#infolettrePage p {
  color: white;
}

.field {
  position: relative;
  margin-bottom: 60px;
}
@media screen and (max-width: 1024px) {
  .field {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .field {
    margin-bottom: 40px;
  }
}
.field--filter {
  margin-bottom: 0;
}
.field__error {
  margin-top: 5px;
  font: 0.8125rem/1 "Roboto", sans-serif;
  color: #e18545;
}
.field--input .field__input, .field--textarea .field__input, .field--newsletter .field__input {
  width: 100%;
  padding: 25px 20px;
  font: 1.0625rem/1.3 "Roboto", sans-serif;
  color: #373737;
  background: transparent;
  border: 1px solid #b2b2b2;
  transition: border 300ms;
}
@media screen and (max-width: 768px) {
  .field--input .field__input {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 500px) {
  .field--input .field__input {
    padding: 15px 25px;
  }
}
.field--textarea .field__input {
  min-height: 150px;
}
.field .errorInput {
  border: 1px solid #e18545;
  color: #e18545;
}
.field--input .field__label, .field--textarea .field__label, .field--newsletter .field__label {
  position: absolute;
  top: -20px;
  left: 0;
  transform: translateY(-50%);
  pointer-events: none;
  font: 0.875rem/1.3 "Roboto", sans-serif;
  color: #898989;
}
.field--input .field__label ::-webkit-input-placeholder, .field--textarea .field__label ::-webkit-input-placeholder, .field--newsletter .field__label ::-webkit-input-placeholder {
  font-size: 17px;
  color: #898989;
}
.field--input .field__label ::-moz-placeholder, .field--textarea .field__label ::-moz-placeholder, .field--newsletter .field__label ::-moz-placeholder {
  font-size: 17px;
  color: #898989;
}
.field--input .field__label :-ms-input-placeholder, .field--textarea .field__label :-ms-input-placeholder, .field--newsletter .field__label :-ms-input-placeholder {
  font-size: 17px;
  color: #898989;
}
.field--input .field__label :-moz-placeholder, .field--textarea .field__label :-moz-placeholder, .field--newsletter .field__label :-moz-placeholder {
  font-size: 17px;
  color: #898989;
}
.field .errorTrue {
  color: #e18545;
}
.field--textarea .field__input {
  box-shadow: none;
  outline: none;
  resize: none;
  overflow: hidden;
}
.field--checkbox .field__label, .field--radio .field__label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
}
.field--checkbox-inline .field__label, .field--radio-inline .field__label {
  display: inline-block;
  margin: 0 30px 20px 0;
}
.field--checkbox .field__text, .field--radio .field__text {
  font: 1.0625rem/1.3 "Roboto", sans-serif;
  color: #373737;
  user-select: none;
}
.field--checkbox .field__input, .field--radio .field__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.field--checkbox .field__input:checked ~ .field__box, .field--radio .field__input:checked ~ .field__box {
  background: #7f8b5d;
}
.field--checkbox .field__input:checked ~ .field__box:before, .field--radio .field__input:checked ~ .field__box:before {
  transform: translate(-50%, -50%) scale(1);
}
.field--checkbox .field__box, .field--radio .field__box {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 2px;
  left: 0;
  background: #fff;
  transition: background 225ms;
}
.field--checkbox .field__box:before, .field--radio .field__box:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms cubic-bezier(0.33, 1, 0.68, 1);
}
.field--checkbox .field__box {
  border: 1px solid #7f8b5d;
}
.field--checkbox .field__box:before {
  width: 12px;
  height: 12px;
  background: url("../medias/images/icons/check.svg") no-repeat center/contain;
}
.field--radio .field__box {
  border-radius: 50%;
  border: 1px solid #7f8b5d;
}
.field--radio .field__box:before {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
}
.field--file .field__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.field--file .field__label {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 60px 20px 0;
  color: #373737;
  line-height: 1.6;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  border: none;
  border-bottom: 1px solid #373737;
  cursor: pointer;
  transition: color 300ms, background 300ms;
}
.field--file .field__label:hover {
  color: #7f8b5d;
}
.field--file .field__label:hover .field__svg {
  fill: #7f8b5d;
}
.field--file .field__svg {
  position: absolute;
  width: 14px;
  height: 16px;
  top: 2px;
  right: 5px;
  fill: #373737;
  transition: fill 300ms, opacity 300ms;
}
.field--file .field__x {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  right: 5px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  pointer-events: none;
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
.field--file .field__x:hover:before, .field--file .field__x:hover:after {
  background: #7f8b5d;
}
.field--file .field__x:before, .field--file .field__x:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #373737;
  transition: background 300ms;
}
@media screen and (max-width: 600px) {
  .field--file .field__x:before, .field--file .field__x:after {
    width: 20px;
  }
}
.field--file .field__x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.field--file .field__x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.field--file[data-file]:not([data-file=""]) .field__label {
  pointer-events: none;
}
.field--file[data-file]:not([data-file=""]) .field__svg {
  opacity: 0;
}
.field--file[data-file]:not([data-file=""]) .field__x {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.field--select .field__label {
  display: block;
  font: 1.0625rem/1.3 "Roboto", sans-serif;
  font-weight: normal;
  color: #373737;
  padding-bottom: 10px;
}
.field--select .tail-select {
  position: relative;
  z-index: 1;
}
.field--select .select-label {
  border: 1px solid rgba(55, 55, 55, 0.2);
  padding: 25px 40px 25px 20px;
  transition: background 500ms cubic-bezier(0.76, 0, 0.24, 1), border-color 500ms cubic-bezier(0.76, 0, 0.24, 1), padding 500ms;
  cursor: pointer;
}
.field--select .select-label:hover {
  background: #fff;
}
@media screen and (max-width: 768px) {
  .field--select .select-label {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 500px) {
  .field--select .select-label {
    padding: 15px 25px;
  }
}
.field--select .label-inner {
  display: block;
  position: relative;
  font: 1.0625rem/1.3 "Roboto", sans-serif;
  color: #373737;
}
.field--select .label-inner:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 15px;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(90deg);
  background: url("../medias/images/icons/chevron-select.svg") no-repeat center/contain;
  transition: transform 500ms cubic-bezier(0.76, 0, 0.24, 1), background 500ms;
}
.field--select .select-dropdown {
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0;
  opacity: 0;
  background: #fff;
  border: 1px solid #b2b2b2;
  border-top: none;
  z-index: -1;
  visibility: hidden;
  pointer-events: none;
  transition: top 500ms cubic-bezier(0.76, 0, 0.24, 1), opacity 500ms cubic-bezier(0.76, 0, 0.24, 1), visibility 0ms ease 500ms;
}
.field--select .dropdown-inner {
  overflow: auto;
  max-height: 195px;
}
.field--select .dropdown-option {
  font: 0.9375rem/1.3 "Roboto", sans-serif;
  font-weight: 500;
  color: #373737;
  padding: 20px 50px 20px 20px;
  background: #fff;
  transition: color 500ms cubic-bezier(0.76, 0, 0.24, 1), background 500ms cubic-bezier(0.76, 0, 0.24, 1);
  cursor: pointer;
}
.field--select .dropdown-option:hover {
  background: #f2f4f8;
}
.field--select .dropdown-option.selected {
  position: relative;
  color: #898989;
}
.field--select .dropdown-option:last-child() {
  padding-bottom: 40px;
}
.field--select select {
  width: 100%;
  padding: 25px 40px;
  font: 1.0625rem/1.3 "Roboto", sans-serif;
  color: #373737;
  border: 1px solid #373737;
  background: transparent;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  background: url("../medias/images/icons/chevron-select-natif.svg") no-repeat calc(100% - 30px) 60%/25px 15px;
}
@media screen and (max-width: 768px) {
  .field--select select {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 500px) {
  .field--select select {
    padding: 15px 25px;
  }
}
.field select::-ms-expand {
  display: none;
}
.field--search {
  margin-bottom: 0;
}
.field--search ::-webkit-input-placeholder {
  color: #373737;
}
.field--search ::-moz-placeholder {
  color: #373737;
}
.field--search :-ms-input-placeholder {
  color: #373737;
}
.field--search :-moz-placeholder {
  color: #373737;
}
.field--search input:-webkit-autofill,
.field--search input:-webkit-autofill:hover,
.field--search input:-webkit-autofill:focus input:-webkit-autofill {
  -webkit-text-fill-color: #373737 !important;
  -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
  transition: background-color 5000s ease-in-out 0s;
}
.field--search input:focus::placeholder {
  color: transparent;
}
.field--search-desktop {
  width: 450px;
  position: relative;
}
@media screen and (max-width: 900px) {
  .field--search-desktop {
    display: none;
  }
}
.field--search-result {
  width: 100%;
}
.field--search .field__input {
  width: 100%;
  padding: 20px 0;
  border: none;
  border-bottom: 1px solid #373737;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #373737;
}
.field--search-desktop .field__input {
  padding: 20px 0 10px;
}
.field--search[data-inputvalue]:not([data-inputvalue=""]) .field__x {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms;
}
.field--search .field__x {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
@media screen and (max-width: 600px) {
  .field--search .field__x {
    top: 25px;
    right: 50px;
    transform: none;
  }
}
.field--search .field__x:before, .field--search .field__x:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #373737;
}
@media screen and (max-width: 600px) {
  .field--search .field__x:before, .field--search .field__x:after {
    width: 20px;
  }
}
.field--search .field__x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.field--search .field__x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.field--search-desktop .field__x {
  top: 60%;
}
.field--search .field__search-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: opacity 300ms cubic-bezier(0.76, 0, 0.24, 1);
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.field--search .field__search-btn:hover {
  opacity: 0.5;
}
.field--search .field__search-btn:focus {
  outline: 0;
}
.field--search-desktop .field__search-btn {
  top: 60%;
}
.field--search .field__svg {
  width: 25px;
  height: 20px;
  fill: none;
  stroke: #373737;
  stroke-width: 2px;
}
.field--newsletter {
  width: 50%;
  background: #7f8b5d;
  padding: 75px 95px;
  margin-bottom: 0;
}
.field--newsletter input:-webkit-autofill,
.field--newsletter input:-webkit-autofill:hover,
.field--newsletter input:-webkit-autofill:focus input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #7f8b5d inset;
  transition: background-color 5000s ease-in-out 0s;
}
.field--newsletter input:focus::placeholder {
  color: transparent;
}
@media screen and (max-width: 1300px) {
  .field--newsletter {
    padding: 75px 60px;
  }
}
@media screen and (max-width: 1100px) {
  .field--newsletter {
    padding: 75px 40px;
  }
}
@media screen and (max-width: 1000px) {
  .field--newsletter {
    padding: 75px 25px;
  }
}
@media screen and (max-width: 900px) {
  .field--newsletter {
    width: 100%;
    padding: 50px 120px;
  }
}
@media screen and (max-width: 768px) {
  .field--newsletter {
    padding: 50px 30px;
  }
}
@media screen and (max-width: 768px) {
  .field--newsletter {
    padding: 30px 25px;
  }
}
.field--newsletter-title {
  color: #fff;
  margin-bottom: 20px;
}
.field--newsletter-form {
  position: relative;
  margin-bottom: 0;
}
.field--newsletter-form ::-webkit-input-placeholder {
  color: white;
}
.field--newsletter-form ::-moz-placeholder {
  color: white;
}
.field--newsletter-form :-ms-input-placeholder {
  color: white;
}
.field--newsletter-form :-moz-placeholder {
  color: white;
}
.field--newsletter-input {
  width: 100%;
  padding: 20px 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
}
.field--newsletter-icon {
  width: 10px;
  height: 14px;
  fill: none;
  stroke: #fff;
  stroke-width: 3px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

:root {
  --header-height: 100px;
}
@media screen and (max-width: 600px) {
  :root {
    --header-height: 80px;
  }
}

.header {
  position: relative;
  margin-top: 0;
  transition: margin 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
.header__container {
  height: var(--header-height);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.header__logo-link {
  width: 130px;
  height: auto;
  position: relative;
}
@media screen and (max-width: 500px) {
  .header__logo-link {
    width: 120px;
  }
}
.header__logo-svg {
  width: 100%;
  height: 100%;
}
.header__logo-text {
  font-family: "Roboto", sans-serif;
  font-size: 8px;
  font-weight: 700;
  color: #659c62;
  white-space: nowrap;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 500px) {
  .header__logo-text {
    font-size: 6px;
    top: 50px;
  }
}
.header__right {
  padding-bottom: 25px;
  display: flex;
  align-items: center;
}

.btn-impact {
  padding: 25px 40px;
  background: #e18545;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1);
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  position: relative;
}
.btn-impact:hover {
  background: #bd7747;
}

.btn-impact:not(:first-child) {
  margin-left: 30px;
}

.btn-impact:not(.left-block__btn):not(.banner__btn):not(.btn-fast-links) svg {
  width: 11px;
  height: 15px;
  fill: #fff;
  margin-left: 15px;
  transform: translateY(2px);
}

.stylesheet {
  margin-top: 30px;
  margin-bottom: 70px;
}

.btn-simple {
  padding: 0;
  padding-bottom: 5px;
  background: transparent;
  color: #e18545;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  position: relative;
}
.btn-simple::after {
  content: "";
  width: 100%;
  transition: width 300ms cubic-bezier(0.76, 0, 0.24, 1);
  height: 3px;
  background: #e18545;
  position: absolute;
  bottom: 0;
  left: 0;
}
.btn-simple:hover::after {
  width: 50%;
}
.btn-simple svg {
  width: 10px;
  height: 15px;
  fill: #e18545;
  margin-left: 15px;
  transform: translateY(2px);
}

.btn-simple:not(:first-child) {
  margin-left: 30px;
}

.btn-menu {
  position: relative;
  width: 55px;
  height: 55px;
  transform: translateY(12px);
  border-radius: 50%;
  background: #e6eaf2;
  margin-left: 40px;
  cursor: pointer;
}
.btn-menu::after {
  content: "";
  width: 55px;
  height: 55px;
  transition: transform 300ms cubic-bezier(0.76, 0, 0.24, 1);
  border-radius: 50%;
  background: #e6eaf2;
  position: absolute;
}
.btn-menu:hover::after {
  transform: scale(1.2);
}
@media screen and (max-width: 900px) {
  .btn-menu {
    margin-left: 0;
  }
}
.btn-menu__lines {
  position: absolute;
  width: 25px;
  height: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.btn-menu__line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #373737;
  opacity: 1;
  left: 0%;
  transform: rotate(0deg);
  transition: 250ms;
}
.btn-menu__line1 {
  top: 0px;
}
.btn-menu__line2, .btn-menu__line3 {
  top: calc(50% - 1px);
}
.btn-menu__line4 {
  bottom: 0px;
}

.btn-search {
  display: none;
}
@media screen and (max-width: 900px) {
  .btn-search {
    display: block;
  }
}
.btn-search__svg-search {
  width: 25px;
  height: 25px;
  fill: none;
  stroke: #373737;
  stroke-width: 2px;
  position: absolute;
  top: 50%;
  right: 145px;
  transform: translateY(-50%);
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .btn-search__svg-search {
    right: 125px;
  }
}
@media screen and (max-width: 600px) {
  .btn-search__svg-search {
    right: 120px;
  }
}
@media screen and (max-width: 400px) {
  .btn-search__svg-search {
    right: 100px;
  }
}

.btn-fast-links {
  padding-right: 95px;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1), background 300ms cubic-bezier(0.76, 0, 0.24, 1);
  overflow-y: hidden;
}
@media screen and (max-width: 500px) {
  .btn-fast-links {
    padding: 15px 65px 15px 15px;
    font-size: 13px;
  }
}
.btn-fast-links:hover {
  background: #fff;
  color: #7f8b5d;
}
.btn-fast-links:hover svg {
  stroke: #7f8b5d;
}
.btn-fast-links__slider {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
@media screen and (max-width: 500px) {
  .btn-fast-links__slider {
    right: 20px;
  }
}
.btn-fast-links svg {
  width: 32px;
  height: 32px;
  fill: none;
  stroke: #fff;
  transition: stroke 300ms cubic-bezier(0.76, 0, 0.24, 1);
  stroke-width: 1px;
}
@media screen and (max-width: 500px) {
  .btn-fast-links svg {
    width: 25px;
    height: 25px;
  }
}
.btn-fast-links .slick-list {
  width: 32px;
  height: 32px;
}
@media screen and (max-width: 500px) {
  .btn-fast-links .slick-list {
    width: 25px;
    height: 25px;
  }
}

.btn-calendar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #373737;
  position: relative;
}
.btn-calendar svg {
  width: 20px;
  height: 20px;
  stroke: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-return {
  width: 65px;
  height: 65px;
  background: #fff;
  border: 1px solid #e6eaf2;
  position: fixed;
  transform: rotate(-90deg);
  right: 25px;
  bottom: 25px;
  z-index: 10;
}
@media screen and (max-width: 768px) {
  .btn-return {
    width: 45px;
    height: 45px;
  }
}
@media screen and (max-width: 500px) {
  .btn-return {
    width: 35px;
    height: 35px;
  }
}
.btn-return__arrow {
  width: 15px;
  height: 15px;
  stroke-width: 5px;
  stroke: #e18545;
  fill: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disable {
  display: none;
}

:root {
  --breadcrumb-height: 45px;
}

.breadcrumb__container {
  width: 100%;
  height: var(--breadcrumb-height);
  background: #fff;
  display: flex;
  align-items: center;
  border-top: 1px solid #e6eaf2;
  border-bottom: 1px solid #e6eaf2;
  position: relative;
  padding-right: 213px;
}
@media screen and (max-width: 1024px) {
  .breadcrumb__container {
    padding-right: 193px;
  }
}
@media screen and (max-width: 768px) {
  .breadcrumb__container {
    padding-right: 173px;
  }
}
@media screen and (max-width: 768px) {
  .breadcrumb__container {
    padding-right: 168px;
  }
}
@media screen and (max-width: 600px) {
  .breadcrumb__container {
    padding-right: var(--x-padding-70);
  }
}
.breadcrumb__link-home, .breadcrumb__link-share {
  font-size: 14px;
}
@media screen and (max-width: 500px) {
  .breadcrumb__link-home, .breadcrumb__link-share {
    font-size: 12px;
  }
}
.breadcrumb__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}
.breadcrumb__item {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  font-size: 14px;
  padding: 20px 0 20px 50px;
  position: relative;
}
.breadcrumb__item:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #373737;
  position: absolute;
  top: 50%;
  left: 23px;
  transform: translateY(-50%);
}
@media screen and (max-width: 500px) {
  .breadcrumb__item {
    font-size: 12px;
    padding: 15px 0 15px 50px;
  }
}
.breadcrumb__link-share {
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .breadcrumb__link-share {
    right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .breadcrumb__link-share {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .breadcrumb__link-share {
    display: none;
  }
}
.breadcrumb__svg-share {
  width: 14px;
  height: 14px;
  fill: #373737;
  stroke: #373737;
  stroke-width: 3px;
  margin-left: 15px;
  transform: translateY(3px);
}
@media screen and (max-width: 500px) {
  .breadcrumb__svg-share {
    width: 12px;
    height: 12px;
  }
}

.footer__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.footer__background {
  width: 100%;
  height: 445px;
}
@media screen and (max-width: 900px) {
  .footer__background {
    height: 225px;
  }
}
.footer__content {
  display: flex;
  position: relative;
}
@media screen and (max-width: 900px) {
  .footer__content {
    flex-direction: column;
  }
}
.footer__btn {
  background: #fff;
  color: #7f8b5d;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1), color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  margin-left: 0 !important;
}
.footer__btn:hover {
  color: #fff;
}
.footer__contact {
  width: 50%;
  display: flex;
  flex-direction: column;
  background: #e6eaf2;
  padding: 80px 120px 30px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}
@media screen and (max-width: 1300px) {
  .footer__contact {
    padding: 80px 80px 30px;
  }
}
@media screen and (max-width: 1100px) {
  .footer__contact {
    padding: 80px 60px 30px;
  }
}
@media screen and (max-width: 1000px) {
  .footer__contact {
    padding: 80px 40px 30px;
  }
}
@media screen and (max-width: 900px) {
  .footer__contact {
    width: 100%;
    padding: 80px 120px 30px;
    position: relative;
    right: auto;
    bottom: auto;
    z-index: auto;
  }
}
@media screen and (max-width: 768px) {
  .footer__contact {
    padding: 80px 30px 30px;
  }
}
@media screen and (max-width: 600px) {
  .footer__contact {
    padding: 80px 25px 30px;
  }
}
.footer__text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
@media screen and (max-width: 440px) {
  .footer__text {
    flex-direction: column;
    justify-content: initial;
  }
}
.footer__links {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 500px) {
  .footer__links {
    display: none;
  }
}
.footer__link {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.76, 0, 0.24, 1);
  margin-bottom: 25px;
}
.footer__link:hover {
  opacity: 0.5;
}
.footer__link:last-child() {
  margin-bottom: 0;
}
.footer__contact-infos {
  display: flex;
  flex-direction: column;
}
.footer__location {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.footer__infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px 0;
  margin-bottom: 30px;
}
.footer__info {
  display: flex;
  align-items: center;
}
.footer__info-label {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 1.5;
  color: #373737;
}
.footer__info-value {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 1.5;
  color: #373737;
  box-shadow: inset 0px -3px 0 #7f8b5d;
  transition: box-shadow 300ms;
}
.footer__info-value:hover {
  box-shadow: inset 0px -25px 0 rgba(127, 139, 93, 0.5);
}
.footer__social {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
.footer__facebook, .footer__youtube, .footer__instagram, .footer__seao {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.footer__facebook:hover, .footer__youtube:hover, .footer__instagram:hover, .footer__seao:hover {
  opacity: 0.5;
}
.footer__facebook, .footer__youtube {
  margin-right: 20px;
}
.footer__facebook {
  width: 10px;
  height: 18px;
}
.footer__youtube {
  width: 22px;
  height: 14px;
}
.footer__instagram {
  width: 17px;
  height: 17px;
  margin-right: 35px;
}
.footer__picture-seao {
  width: 71px;
  height: 20px;
}
.footer__social-icon {
  width: 100%;
  height: 100%;
  fill: #373737;
}
.footer__copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 440px) {
  .footer__copyright {
    flex-direction: column;
  }
}
.footer__copyright-item {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
}
@media screen and (max-width: 440px) {
  .footer__copyright-item:first-child {
    margin-bottom: 10px;
  }
}

.sopfeu-widget {
  width: 100%;
  position: relative;
}
.sopfeu-widget__alert {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.sopfeu-widget__link {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  padding-left: 35px;
}
.sopfeu-widget__low {
  background: #294D9D;
}
.sopfeu-widget__medium {
  background: #58B14A;
}
.sopfeu-widget__high {
  background: #FFCB0F;
}
.sopfeu-widget__veryhigh {
  background: #F18825;
}
.sopfeu-widget__extreme {
  background: #E5272B;
}
.sopfeu-widget__taux {
  font-weight: 700;
}

.page-title__container {
  padding-left: var(--x-padding-400);
  margin-top: -300px;
}
@media screen and (max-width: 768px) {
  .page-title__container {
    margin-top: 0;
  }
}
.page-title__container--no-banner {
  margin-top: 0;
}
.page-title__container--style {
  background: transparent;
  padding-left: var(--x-padding-260);
  padding-right: var(--x-padding-260);
  margin-top: 0;
  display: flex;
  flex-direction: column;
}
.page-title__content {
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  z-index: -1;
}
.page-title__content::after {
  content: "";
  width: 100%;
  height: 100px;
  background: #fff;
  position: absolute;
  bottom: -60px;
  left: 0;
}
.page-title__content--no-banner::after {
  display: none;
}
.page-title__eventinfos {
  padding-top: 10px;
  z-index: 2;
}
.page-title__surtitle {
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
  font-weight: 700;
}
@media screen and (min-width: 1440px) {
  .page-title__surtitle {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .page-title__surtitle {
    font-size: calc(2.1875rem + (40 - 35) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .page-title__surtitle {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 1440px) {
  .page-title__surtitle {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .page-title__surtitle {
    font-size: calc(1.875rem + (50 - 30) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .page-title__surtitle {
    font-size: 1.875rem;
  }
}
.page-title__title {
  color: #373737;
  font-family: "Roboto", sans-serif;
  line-height: 1.15;
  font-weight: 700;
}
@media screen and (min-width: 1440px) {
  .page-title__title {
    font-size: 4.375rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .page-title__title {
    font-size: calc(3.125rem + (70 - 50) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .page-title__title {
    font-size: 3.125rem;
  }
}
@media screen and (max-width: 400px) {
  .page-title__title {
    font-size: 40px;
  }
}
@media screen and (max-width: 320px) {
  .page-title__title {
    font-size: 30px;
  }
}
.page-title__title--news {
  margin-top: -15px;
}
.page-title__title--green {
  color: #7f8b5d;
}
.page-title__separator {
  width: 115px;
  height: 8px;
  background: #7f8b5d;
  margin: 40px 0;
}

.gallery {
  display: flex;
  flex-flow: row nowrap;
  max-width: 880px;
  height: 350px;
}
@media screen and (max-width: 768px) {
  .gallery {
    height: 335px;
  }
}
@media screen and (max-width: 500px) {
  .gallery {
    height: 200px;
  }
}
.gallery__left {
  flex: 1;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .gallery__left {
    margin-right: 15px;
  }
}
@media screen and (max-width: 500px) {
  .gallery__left {
    margin-right: 10px;
  }
}
.gallery__right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 160px;
}
@media screen and (max-width: 500px) {
  .gallery__right {
    width: 95px;
  }
}
.gallery__block {
  position: relative;
  height: 100%;
}
.gallery__block:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #373737;
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;
}
.gallery__link {
  display: block;
  height: 100%;
}
.gallery__block {
  display: none;
}
.gallery__block-1, .gallery__block-2, .gallery__block-3 {
  display: block;
}
.gallery__block-1 .gallery__link, .gallery__block-2 .gallery__link, .gallery__block-3 .gallery__link {
  display: block;
  height: 100%;
}
.gallery__block-2, .gallery__block-3 {
  height: 160px;
}
@media screen and (max-width: 500px) {
  .gallery__block-2, .gallery__block-3 {
    height: 95px;
  }
}
.gallery__block-3:before {
  opacity: 0.8;
}
.gallery__block:hover:before {
  opacity: 0.5;
}
.gallery__picture {
  width: 100%;
  height: 100%;
}
.gallery__icon {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 50%;
  left: 50%;
  z-index: 1;
  pointer-events: none;
  transform: translate(-50%, -50%) translateZ(0);
}
.gallery__icon-lines {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gallery__icon-line-1 {
  width: 100%;
  height: 3px;
}
.gallery__icon-line-2 {
  width: 3px;
  height: 100%;
}

.intro__container {
  padding-left: var(--x-padding-400);
}
.intro__container--style {
  background: transparent;
  margin-bottom: 65px;
  padding-left: var(--x-padding-260);
  padding-right: var(--x-padding-260);
}
.intro__content {
  background: #fff;
  margin-bottom: 65px;
}
.intro__text {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}
@media screen and (min-width: 1440px) {
  .intro__text {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .intro__text {
    font-size: calc(1.25rem + (28 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .intro__text {
    font-size: 1.25rem;
  }
}

.section__container--style > .gallery {
  margin: 65px 0;
}
.section__container--banner {
  padding: 0;
}
.section__container--component-none {
  padding-left: 0;
}
.section__content {
  max-width: 320px;
}
.section__center-title {
  text-align: center;
}
.section__small-title {
  margin-bottom: 25px;
  color: #373737;
}
.section__medium-title {
  margin-bottom: 110px;
  color: #373737;
}
.section__large-title {
  margin-top: 65px;
  margin-bottom: 30px;
  color: #373737;
}
.section__large-title--jobs {
  margin-bottom: var(--y-margin-60);
}
.section__large-title--event {
  margin-top: var(--y-margin-190);
  margin-bottom: var(--y-margin-90);
}
.section__paragraph {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  margin-bottom: 60px;
}
.section__no-result {
  margin-bottom: 100px;
}

.documents {
  margin-top: 50px;
  margin-bottom: 65px;
}
.documents__document-link {
  width: 100%;
  max-width: 920px;
  padding: 25px 90px 25px 20px;
  transition: padding 300ms cubic-bezier(0.76, 0, 0.24, 1);
  border-bottom: 1px solid #373737;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .documents__document-link {
    padding-right: 80px;
  }
}
@media screen and (max-width: 768px) {
  .documents__document-link {
    padding-right: 70px;
  }
}
@media screen and (max-width: 600px) {
  .documents__document-link {
    padding-left: 0;
  }
}
.documents__document-link:hover {
  padding-left: 40px;
}
.documents__document-link--plus {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.documents__document-picture {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
}
@media screen and (max-width: 600px) {
  .documents__document-picture {
    display: none;
  }
}
.documents__document-text {
  padding-left: 30px;
}
@media screen and (max-width: 600px) {
  .documents__document-text {
    padding-left: 0;
  }
}
.documents__document-surtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.documents__document-link:hover .documents__document-surtitle {
  color: #7f8b5d;
}
.documents__document-title {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  line-height: 1.2;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.documents__document-link:hover .documents__document-title {
  color: #7f8b5d;
}
.documents__document-icon {
  width: 14px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: var(--x-padding-40);
  transform: translateY(-50%);
}
.documents__document-svg {
  width: 100%;
  height: 100%;
  fill: #373737;
  transition: fill 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.documents__document-link:hover .documents__document-svg {
  fill: #7f8b5d;
}

.document-gallery > :last-child() {
  border-bottom: 1px solid #373737;
}
.document-gallery__document {
  display: block;
  position: relative;
  border-top: 1px solid #373737;
}
.document-gallery__link {
  display: block;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media screen and (max-width: 1200px) {
  .document-gallery__link {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .document-gallery__link {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .document-gallery__link {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.document-gallery__link--plus {
  display: flex;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;
}
@media screen and (max-width: 600px) {
  .document-gallery__link--plus {
    padding-left: 0;
  }
}
.document-gallery__link--plus .document-gallery__title {
  padding-left: 0;
}
.document-gallery__picture {
  width: 90px;
  height: 90px;
}
@media screen and (max-width: 600px) {
  .document-gallery__picture {
    display: none;
  }
}
.document-gallery__text {
  padding-left: 30px;
  padding-right: 90px;
}
@media screen and (max-width: 1024px) {
  .document-gallery__text {
    padding-right: 80px;
  }
}
@media screen and (max-width: 768px) {
  .document-gallery__text {
    padding-right: 70px;
  }
}
@media screen and (max-width: 600px) {
  .document-gallery__text {
    padding-left: 0;
  }
}
.document-gallery__surtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.document-gallery__link:hover .document-gallery__surtitle {
  color: #7f8b5d;
}
.document-gallery__title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1), padding 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
@media screen and (min-width: 1440px) {
  .document-gallery__title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .document-gallery__title {
    font-size: calc(1.25rem + (30 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .document-gallery__title {
    font-size: 1.25rem;
  }
}
.document-gallery__link:hover .document-gallery__title {
  color: #7f8b5d;
}
.document-gallery__icon {
  width: 18px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: var(--x-padding-40);
  transform: translateY(-50%);
}
.document-gallery__icon--extern {
  width: 14px;
}
.document-gallery__svg {
  width: 100%;
  height: 100%;
  fill: #373737;
  transition: fill 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.document-gallery__link:hover .document-gallery__svg {
  fill: #7f8b5d;
}

.rubrics {
  margin-top: 30px;
}

.rubric {
  position: relative;
  border-bottom: 1px solid #373737;
  background: transparent;
  overflow: hidden;
  transition: background 700ms;
}
.rubric:first-child {
  border-top: 1px solid #373737;
}
.rubric__transition > :last-child {
  margin-bottom: 0;
}
.rubric__transition--no-share {
  padding-bottom: 90px;
}
.rubric__header {
  display: block;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .rubric__header {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .rubric__header {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .rubric__header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.rubric__title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
@media screen and (min-width: 1440px) {
  .rubric__title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .rubric__title {
    font-size: calc(1.25rem + (30 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .rubric__title {
    font-size: 1.25rem;
  }
}
.rubric__header:hover .rubric__title {
  color: #7f8b5d;
}
.rubric__icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: var(--x-padding-40);
  transform: translateY(-50%);
  transition: transform 700ms cubic-bezier(0.65, 0, 0.35, 1);
}
@media screen and (max-width: 600px) {
  .rubric__icon {
    width: 19px;
    height: 19px;
  }
}
.rubric__icon-lines {
  background: #373737;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rubric__header:hover .rubric__icon-lines {
  background: #7f8b5d;
}
.rubric__icon-line-1 {
  width: 100%;
  height: 2px;
}
.rubric__icon-line-2 {
  width: 2px;
  height: 100%;
}
.rubric__content {
  height: 0;
  will-change: height;
  overflow: hidden;
}
.rubric__transition {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 700ms cubic-bezier(0.65, 0, 0.35, 1), transform 700ms cubic-bezier(0.65, 0, 0.35, 1);
}
.rubric__transition > :first-child {
  margin-top: 0;
}
.rubric__dynamic h3 {
  margin-top: 50px;
  margin-bottom: 25px;
  line-height: 1.45;
  font-weight: 500;
}
@media screen and (min-width: 1440px) {
  .rubric__dynamic h3 {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .rubric__dynamic h3 {
    font-size: calc(1.25rem + (22 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .rubric__dynamic h3 {
    font-size: 1.25rem;
  }
}
.rubric__dynamic h3 + ul {
  margin-bottom: 50px;
}
.rubric__dynamic h3 + h4 {
  margin-top: 25px;
}
.rubric__dynamic h4 {
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
}
.rubric__dynamic p {
  margin: 17.5px 0;
}
.rubric__dynamic p + h4 {
  margin-top: 50px;
}
.rubric__dynamic ul {
  padding-left: 17px;
}
.rubric__share {
  margin-top: 35px;
  padding: 25px 0;
}
.rubric__share-link {
  font-size: 15px;
  font-weight: 700;
}
.rubric__share-icon {
  width: 17px;
  height: 17px;
  fill: #373737;
  stroke: #373737;
  stroke-width: 3px;
  margin-left: 15px;
  transform: translateY(3px);
}
.rubric__gallery {
  margin: 65px 0;
}

.rubric--plus__header {
  display: flex;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;
}
.rubric--plus__picture {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
}
@media screen and (max-width: 600px) {
  .rubric--plus__picture {
    display: none;
  }
}
.rubric--plus__text {
  padding: 13px 90px 13px 30px;
  transition: padding 700ms cubic-bezier(0.76, 0, 0.24, 1);
}
@media screen and (max-width: 1024px) {
  .rubric--plus__text {
    padding-right: 80px;
  }
}
@media screen and (max-width: 768px) {
  .rubric--plus__text {
    padding-right: 70px;
  }
}
@media screen and (max-width: 600px) {
  .rubric--plus__text {
    padding-left: 0;
  }
}
.rubric--plus__text--no-image {
  padding-left: 0;
}
.rubric--plus__surtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.rubric--plus__header:hover .rubric--plus__surtitle {
  color: #7f8b5d;
}

.jobs > :last-child() {
  border-bottom: 1px solid #373737;
}
.jobs__job {
  display: block;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #373737;
}
@media screen and (max-width: 1200px) {
  .jobs__job {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .jobs__job {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .jobs__job {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.jobs__job--plus {
  display: flex;
  align-items: center;
}
.jobs__text {
  padding-right: 90px;
}
@media screen and (max-width: 1024px) {
  .jobs__text {
    padding-right: 80px;
  }
}
@media screen and (max-width: 768px) {
  .jobs__text {
    padding-right: 70px;
  }
}
.jobs__surtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.jobs__job:hover .jobs__surtitle {
  color: #7f8b5d;
}
.jobs__title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
@media screen and (min-width: 1440px) {
  .jobs__title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .jobs__title {
    font-size: calc(1.25rem + (30 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .jobs__title {
    font-size: 1.25rem;
  }
}
.jobs__job:hover .jobs__title {
  color: #7f8b5d;
}
.jobs__icon {
  width: 14px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: var(--x-padding-40);
  transform: translateY(-50%) rotate(45deg);
}
.jobs__svg {
  width: 100%;
  height: 100%;
  fill: #373737;
  transition: fill 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.jobs__job:hover .jobs__svg {
  fill: #7f8b5d;
}

:root {
  --bouton-calendar-width: 0;
}

.filter {
  position: -webkit-sticky;
  position: sticky;
  top: 45px;
  bottom: 0;
  z-index: 10;
  align-self: flex-start;
}
@media screen and (max-width: 1024px) {
  .filter {
    display: none;
  }
}
.filter__container {
  width: 320px;
  border: 1px solid rgba(55, 55, 55, 0.2);
  padding: 40px 0 40px;
}
.filter__title {
  font: 1.375rem/1.3 "Roboto", sans-serif;
  font-weight: 700;
  color: #373737;
  padding: 0 50px 30px;
}
.filter sup {
  font-size: 10px;
  vertical-align: super;
}
.filter__items {
  margin-bottom: 20px;
}
.filter__items:last-child() {
  margin-bottom: 0;
}
.filter__item {
  font: 0.9375rem/1.3 "Roboto", sans-serif;
  font-weight: 500;
  color: #898989;
  transition: color 500ms cubic-bezier(0.76, 0, 0.24, 1);
  padding-left: 50px;
  position: relative;
}
.filter__item:hover {
  color: #373737;
}
.filter .active-category {
  color: #e18545;
}
.filter .active-category::before {
  content: "";
  width: 20px;
  height: 1px;
  background: #e18545;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.filter-calendar__container-calendar {
  margin-top: 30px;
}

.filter-form {
  display: none;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 45px;
  bottom: 0;
  align-self: flex-start;
}
@media screen and (max-width: 1024px) {
  .filter-form {
    display: block;
  }
}

.filter-form--mobile {
  width: calc(100% - var(--bouton-calendar-width));
  position: relative;
  top: auto;
  bottom: auto;
  align-self: auto;
  display: none;
}
@media screen and (max-width: 768px) {
  .filter-form--mobile {
    display: block;
  }
}
@media screen and (max-width: 400px) {
  .filter-form--mobile {
    width: 100%;
    margin-bottom: 30px;
  }
}
.filter-form--mobile__search-results {
  margin-top: 40px;
}

.filter-form-calendar .form {
  margin-top: 30px;
}

:root {
  --theadHeight: 68px;
}

.datepicker {
  background: #fff;
  position: relative;
  width: 100%;
  direction: ltr;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker {
  position: relative;
}
.datepicker::before {
  content: "";
  width: 100%;
  height: var(--theadHeight);
  background: #7f8b5d;
  position: absolute;
  top: 1px;
  left: 0;
}
.datepicker table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
  border: 1px solid #e6eaf2;
  border-collapse: initial;
  padding: 0 20px 20px 20px;
  position: relative;
  z-index: 2;
}
.datepicker thead > tr:nth-child(2) {
  height: var(--theadHeight);
  background: #7f8b5d;
}
.datepicker .datepicker-switch, .datepicker .prev, .datepicker .next {
  transition: opacity 300ms;
}
.datepicker .prev, .datepicker .next {
  width: 25px;
  font-size: 0;
  background-image: url("../medias/images/icons/chevron-calendar.svg");
  background-repeat: no-repeat;
  background-size: 15px 12px;
  cursor: pointer;
}
.datepicker .prev.disabled, .datepicker .next.disabled {
  visibility: hidden;
}
.datepicker .datepicker-switch {
  width: calc(100% - 50px);
  font: 700 1rem/1 "Roboto", sans-serif;
  color: #fff;
  padding: 25px 0;
}
@media screen and (max-width: 425px) {
  .datepicker .datepicker-switch {
    font-size: 1.125rem;
  }
}
.datepicker .prev {
  transform: rotate(180deg);
  background-position: 50% 50%;
  transition: background 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
.datepicker .prev:hover {
  background-position: 60% 50%;
}
.datepicker .next {
  background-position: 50% 50%;
  transition: background 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
.datepicker .next:hover {
  background-position: 60% 50%;
}
.datepicker .dow {
  width: 38px;
  padding-top: 30px;
  padding-bottom: 10px;
  font: 700 0.75rem/1 "Roboto", sans-serif;
  font-weight: 700;
  color: #373737;
  text-align: center;
  cursor: default;
}
@media screen and (max-width: 1024px) {
  .datepicker .dow {
    padding-top: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .datepicker .dow {
    width: 34px;
  }
}
.datepicker .day {
  position: relative;
  width: 35px;
  height: 39px;
  font: 500 0.75rem/1 "Roboto", sans-serif;
  color: #373737;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  z-index: 1;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.datepicker .day.disabled, .datepicker .day.disabled:hover {
  background: none;
  cursor: default;
}
.datepicker .day.disabled:after, .datepicker .day.disabled:hover:after {
  display: none;
}
.datepicker .day:not(.disabled):after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: 10px;
  right: 6px;
  transform: translate(-50%, -50%);
  background-color: #e18545;
  z-index: 1;
  border-radius: 50%;
}
@media screen and (max-width: 1024px) {
  .datepicker .day:not(.disabled):after {
    width: 4px;
    height: 4px;
    right: 6px;
  }
}
.datepicker .day:not(.disabled):hover {
  background: #e5e8df;
}
.datepicker .old, .datepicker .new {
  color: #96a3a5;
}
.datepicker .old:nth-child(5):after, .datepicker .new:nth-child(5):after {
  background-color: #e18545;
}
.datepicker .old:nth-child(1):after, .datepicker .new:nth-child(1):after {
  background-color: #e18545;
}
.datepicker .old.day.active:before, .datepicker .new.day.active:before {
  background: #fff !important;
}
.datepicker .old.day.today:before, .datepicker .new.day.today:before {
  background: #fff !important;
}
.datepicker .day.today {
  background: #e6eaf2;
}
.datepicker .day.active {
  color: #fff;
  background: #7f8b5d;
  cursor: default;
}
.datepicker .day.active::after {
  background: #fff;
}
.datepicker .month, .datepicker .year, .datepicker .decade, .datepicker .century {
  float: left;
  width: 25%;
  height: 30px;
  font: 0.75rem/30px "Roboto", sans-serif;
  color: #7f8b5d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.datepicker .month:hover, .datepicker .year:hover, .datepicker .decade:hover, .datepicker .century:hover {
  background: #e9eaeb;
}
.datepicker .month.focused, .datepicker .year.focused, .datepicker .decade.focused, .datepicker .century.focused {
  color: #fff;
  background: #373737;
}

.vitrine {
  position: relative;
}
.vitrine__container {
  display: block;
  padding-right: 200px;
  margin: 140px 0 120px 0;
}
@media screen and (max-width: 1000px) {
  .vitrine__container {
    padding-right: 100px;
  }
}
@media screen and (max-width: 900px) {
  .vitrine__container {
    padding-right: 0;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .vitrine__container {
    margin-top: 120px;
  }
}
.vitrine__left {
  padding: 130px 0 130px 130px;
  border: 1px solid #b2b2b2;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1200px) {
  .vitrine__left {
    padding: 100px 0 100px 100px;
  }
}
@media screen and (max-width: 1000px) {
  .vitrine__left {
    padding: 50px 0 50px 50px;
  }
}
@media screen and (max-width: 900px) {
  .vitrine__left {
    padding: 50px;
  }
}
@media screen and (max-width: 768px) {
  .vitrine__left {
    padding: 40px;
  }
}
@media screen and (max-width: 600px) {
  .vitrine__left {
    padding: 30px;
  }
}
@media screen and (max-width: 500px) {
  .vitrine__left {
    padding: 25px;
  }
}
.vitrine__title {
  color: #373737;
  margin-bottom: 10px;
  max-width: 280px;
}
@media screen and (max-width: 900px) {
  .vitrine__title {
    max-width: 100%;
  }
}
.vitrine__text {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #373737;
  margin-bottom: 45px;
  max-width: 280px;
}
@media screen and (max-width: 900px) {
  .vitrine__text {
    max-width: 100%;
  }
}
.vitrine__btn {
  margin-left: 0 !important;
  align-self: flex-start;
  pointer-events: none;
}
.vitrine__container:hover .vitrine__btn {
  background: #bd7747;
}
.vitrine__right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .vitrine__right {
    position: relative;
    top: auto;
    right: auto;
    transform: translateY(0);
  }
}
.vitrine__picture {
  width: 640px;
  height: auto;
  overflow: hidden;
}
@media screen and (max-width: 1300px) {
  .vitrine__picture {
    width: 540px;
  }
}
@media screen and (max-width: 1200px) {
  .vitrine__picture {
    width: 440px;
  }
}
@media screen and (max-width: 900px) {
  .vitrine__picture {
    width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 768px) {
  .vitrine__picture {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  .vitrine__picture {
    height: auto;
  }
}
.vitrine__img {
  transition: transform 600ms cubic-bezier(0.25, 1, 0.5, 1);
}
.vitrine__container:hover .vitrine__img {
  transform: scale(1.1);
}

:root {
  --featured-left-box-width: 500px;
}
@media screen and (max-width: 1000px) {
  :root {
    --featured-left-box-width: 400px;
  }
}
@media screen and (max-width: 900px) {
  :root {
    --featured-left-box-width: 100%;
  }
}

.featured-news__slider-container {
  display: flex;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .featured-news__slider-container {
    flex-direction: column;
  }
}
.featured-news__left-box {
  width: var(--featured-left-box-width);
}
@media screen and (max-width: 900px) {
  .featured-news__left-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: var(--x-padding-100);
  }
}
.featured-news__title {
  color: #373737;
  margin-bottom: 60px;
}
@media screen and (max-width: 900px) {
  .featured-news__title {
    margin-bottom: 0;
    margin-right: 25px;
  }
}
.featured-news__btn-desktop {
  margin-bottom: 115px;
}
@media screen and (max-width: 900px) {
  .featured-news__btn-desktop {
    display: none;
  }
}
.featured-news__slider {
  width: calc(100% - (var(--featured-left-box-width)));
  padding-top: 80px;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 900px) {
  .featured-news__slider {
    width: 100%;
    padding-top: 30px;
  }
}
.featured-news__link {
  padding-top: 30px;
  max-width: 370px;
  margin-right: 60px;
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (max-width: 500px) {
  .featured-news__link {
    max-width: 250px;
    margin-right: 30px;
  }
}
.featured-news__text {
  border-top: 1px solid #e6eaf2;
  transition: border 300ms cubic-bezier(0.76, 0, 0.24, 1);
  padding-top: 30px;
  margin-top: 30px;
}
.featured-news__link:hover .featured-news__text {
  border-top: 1px solid #303030;
}
.featured-news__date-and-category {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #e18545;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.featured-news__news-title {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  line-height: 1.3;
}
@media screen and (max-width: 500px) {
  .featured-news__news-title {
    font-size: 20px;
  }
}
.featured-news__picture {
  width: 370px;
  height: 210px;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 500px) {
  .featured-news__picture {
    width: 250px;
    height: 140px;
  }
}
.featured-news__img {
  transition: transform 600ms cubic-bezier(0.25, 1, 0.5, 1);
}
.featured-news__link:hover .featured-news__img {
  transform: scale(1.1);
}
.featured-news__background {
  background: rgba(55, 55, 55, 0.5);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.76, 0, 0.24, 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.featured-news__link:hover .featured-news__background {
  opacity: 1;
}
.featured-news__background-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.featured-news__btn-mobile {
  margin-top: 60px;
  margin-left: 0 !important;
  align-self: flex-start;
  display: none;
}
@media screen and (max-width: 900px) {
  .featured-news__btn-mobile {
    display: block;
  }
}

.slider-nav {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .slider-nav {
    display: none;
  }
}
.slider-nav__link {
  width: 80px;
  height: 80px;
  border: 1px solid #e6eaf2;
  position: relative;
}
.slider-nav__prev {
  transform: rotate(180deg);
  margin-right: 25px;
}
.slider-nav__svg {
  fill: none;
  stroke: #e18545;
  stroke-width: 5px;
  width: 10px;
  height: 14px;
  position: absolute;
  top: 49%;
  left: 50%;
  transition: left 300ms cubic-bezier(0.76, 0, 0.24, 1);
  transform: translate(-50%, -50%);
}
.slider-nav__link:hover .slider-nav__svg {
  left: 70%;
}
.slider-nav .slideNumber {
  margin-right: 40px;
}
.slider-nav .slideNumber .current, .slider-nav .slideNumber .total, .slider-nav .slideNumber .slash {
  font-family: "Roboto", sans-serif;
  color: #373737;
}
.slider-nav .slideNumber .current {
  font-size: 25px;
  font-weight: 700;
}
.slider-nav .slideNumber .total {
  font-size: 20px;
}

.featured-news-list__link {
  margin-top: 90px;
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (max-width: 600px) {
  .featured-news-list__link {
    margin-top: 50px;
  }
}
@media screen and (max-width: 400px) {
  .featured-news-list__link {
    margin-top: 40px;
  }
}
.featured-news-list__link:nth-child(2) {
  margin-top: 40px;
}
@media screen and (max-width: 600px) {
  .featured-news-list__link:nth-child(2) {
    margin-top: 30px;
  }
}
@media screen and (max-width: 400px) {
  .featured-news-list__link:nth-child(2) {
    margin-top: 20px;
  }
}
.featured-news-list__link:last-child {
  margin-bottom: 100px;
}
@media screen and (max-width: 600px) {
  .featured-news-list__link:last-child {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 400px) {
  .featured-news-list__link:last-child {
    margin-bottom: 40px;
  }
}
.featured-news-list__text {
  border-top: 1px solid #e6eaf2;
  transition: border 300ms cubic-bezier(0.76, 0, 0.24, 1);
  padding-top: 30px;
  margin-top: 30px;
}
.featured-news-list__link:hover .featured-news-list__text {
  border-top: 1px solid #303030;
}
.featured-news-list__date-and-category {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #e18545;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.featured-news-list__news-title {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  line-height: 1.3;
}
.featured-news-list__picture {
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 56%;
}
.featured-news-list__background {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.76, 0, 0.24, 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.featured-news-list__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.featured-news-list__link:hover .featured-news-list__background {
  opacity: 1;
}
.featured-news-list__background-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.featured-events__container {
  padding-left: 200px;
  position: relative;
}
@media screen and (max-width: 1000px) {
  .featured-events__container {
    padding-left: 100px;
  }
}
@media screen and (max-width: 900px) {
  .featured-events__container {
    padding-left: 0;
    display: flex;
    flex-direction: column-reverse;
  }
}
.featured-events__link {
  width: 100%;
}
.featured-events__right {
  max-width: 1120px;
  min-height: 515px;
  padding: 0 215px 0 500px;
  background: #e6eaf2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1700px) {
  .featured-events__right {
    padding: 0 115px 0 500px;
  }
}
@media screen and (max-width: 1600px) {
  .featured-events__right {
    padding: 0 215px 0 500px;
  }
}
@media screen and (max-width: 1400px) {
  .featured-events__right {
    padding: 0 115px 0 500px;
  }
}
@media screen and (max-width: 1300px) {
  .featured-events__right {
    padding: 0 115px 0 400px;
  }
}
@media screen and (max-width: 1200px) {
  .featured-events__right {
    min-height: 415px;
    padding: 0 115px 0 300px;
  }
}
@media screen and (max-width: 1100px) {
  .featured-events__right {
    padding: 0 75px 0 300px;
  }
}
@media screen and (max-width: 1000px) {
  .featured-events__right {
    padding: 0 25px 0 400px;
  }
}
@media screen and (max-width: 900px) {
  .featured-events__right {
    min-height: 0;
    padding: 50px;
    justify-content: initial;
    align-items: initial;
  }
}
@media screen and (max-width: 500px) {
  .featured-events__right {
    padding: 25px;
  }
}
.featured-events__content {
  display: flex;
  flex-direction: column;
}
.featured-events__category {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #e18545;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.featured-events__title {
  font-weight: normal;
  margin-bottom: 30px;
}
@media screen and (max-width: 900px) {
  .featured-events__title {
    max-width: 100%;
  }
}
.featured-events__date, .featured-events__hours, .featured-events__place {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 1.5;
  color: #373737;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.featured-events__infos > :last-child() {
  margin-bottom: 0;
}
.featured-events__svg-calendar, .featured-events__svg-clock, .featured-events__svg-marker {
  width: 15px;
  height: 18px;
  min-width: 15px;
  min-height: 18px;
  fill: none;
  stroke: #373737;
  stroke-width: 2px;
  margin-right: 20px;
  transform: translateY(3px);
}
.featured-events__left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .featured-events__left {
    position: relative;
    top: auto;
    right: auto;
    transform: translateY(0);
  }
}
.featured-events__picture {
  width: 640px;
  overflow: hidden;
  position: relative;
  padding-top: 48%;
}
@media screen and (max-width: 1300px) {
  .featured-events__picture {
    width: 540px;
  }
}
@media screen and (max-width: 1200px) {
  .featured-events__picture {
    width: 440px;
  }
}
@media screen and (max-width: 900px) {
  .featured-events__picture {
    width: 100%;
  }
}
.featured-events__img {
  transition: transform 600ms cubic-bezier(0.25, 1, 0.5, 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.featured-events__link:hover .featured-events__img {
  transform: scale(1.1);
}
.featured-events__background {
  background: rgba(55, 55, 55, 0.5);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.76, 0, 0.24, 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.featured-events__link:hover .featured-events__background {
  opacity: 1;
}
.featured-events__background-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.featured-events__btn-container {
  display: flex;
  justify-content: center;
}
.featured-events__btn {
  margin-top: 60px;
  margin-left: 0 !important;
}

.featured-events-list {
  --bouton-calendar-width: 95px;
}
.featured-events-list__container {
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.featured-events-list__button-and-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
@media screen and (max-width: 900px) {
  .featured-events-list__button-and-date {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .featured-events-list__button-and-date {
    display: none;
  }
}
.featured-events-list__filter-and-calendar {
  display: none;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .featured-events-list__filter-and-calendar {
    display: flex;
  }
}
@media screen and (max-width: 400px) {
  .featured-events-list__filter-and-calendar {
    flex-direction: column;
    align-items: flex-start;
  }
}
.featured-events-list__date-title {
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  font-weight: 700;
}
@media screen and (min-width: 1440px) {
  .featured-events-list__date-title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .featured-events-list__date-title {
    font-size: calc(1.25rem + (30 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .featured-events-list__date-title {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 900px) {
  .featured-events-list__date-title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .featured-events-list__date-title {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 500px) {
  .featured-events-list__date-title {
    margin-bottom: 30px;
  }
}
.featured-events-list__btn {
  margin-left: 0 !important;
}
.featured-events-list__link {
  width: 100%;
}
.featured-events-list__right {
  padding: 50px 120px 70px 100px;
  background: #e6eaf2;
}
@media screen and (max-width: 900px) {
  .featured-events-list__right {
    padding: 50px;
  }
}
@media screen and (max-width: 500px) {
  .featured-events-list__right {
    padding: 25px;
  }
}
.featured-events-list__content {
  display: flex;
  flex-direction: column;
}
.featured-events-list__category {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #e18545;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.featured-events-list__title {
  margin-bottom: 30px;
  font-weight: normal;
}
@media screen and (max-width: 900px) {
  .featured-events-list__title {
    max-width: 100%;
  }
}
.featured-events-list__date, .featured-events-list__hours, .featured-events-list__place {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 1.5;
  color: #373737;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.featured-events-list__infos > :last-child() {
  margin-bottom: 0;
}
.featured-events-list__svg-calendar, .featured-events-list__svg-clock, .featured-events-list__svg-marker {
  width: 15px;
  height: 18px;
  min-width: 15px;
  min-height: 18px;
  fill: none;
  stroke: #373737;
  stroke-width: 2px;
  margin-right: 20px;
  transform: translateY(3px);
}
.featured-events-list__picture {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 48%;
}
.featured-events-list__background {
  background: rgba(55, 55, 55, 0.5);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.76, 0, 0.24, 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.featured-events-list__img {
  transition: transform 600ms cubic-bezier(0.25, 1, 0.5, 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.featured-events-list__link:hover .featured-events-list__img {
  transform: scale(1.1);
}
.featured-events-list__link:hover .featured-events-list__background {
  opacity: 1;
}
.featured-events-list__background-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.featured-events-list:last-child {
  margin-bottom: var(--y-margin-90);
}

:root {
  --banner-height: calc((var(--vh, 1vh) * 100) - var(--header-height));
}
@media screen and (max-width: 768px) {
  :root {
    --banner-height: 300px;
  }
}
@media screen and (max-width: 400px) {
  :root {
    --banner-height: 200px;
  }
}

.banner {
  position: relative;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .banner {
    z-index: auto;
  }
}
.banner__btn {
  background: #fff;
  color: #7f8b5d;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1), color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  position: absolute;
  bottom: 50px;
  left: 80px;
  display: none;
}
.banner__btn:hover {
  background: #7f8b5d;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .banner__btn {
    left: 30px;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .banner__btn {
    left: 25px;
    bottom: 25px;
  }
}
@media screen and (max-width: 500px) {
  .banner__btn {
    padding: 20px;
  }
}
@media screen and (max-width: 400px) {
  .banner__btn {
    padding: 15px;
  }
}
.banner__btn-svg {
  fill: none;
  stroke: #7f8b5d;
  transition: stroke 300ms cubic-bezier(0.76, 0, 0.24, 1);
  stroke-width: 5px;
  width: 15px;
  height: 12px;
  margin-left: 20px;
  transform: rotate(90deg);
}
.banner__btn:hover .banner__btn-svg {
  stroke: #fff;
}
.banner__copyright {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 768px) {
  .banner__copyright {
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .banner__copyright {
    font-size: 10px;
  }
}
@media screen and (max-width: 500px) {
  .banner__copyright {
    font-size: 8px;
  }
}
.banner__picture {
  width: 100%;
  height: var(--banner-height);
}
.banner__img {
  min-height: 200px;
}
@media screen and (max-height: 1000px) {
  .banner__img {
    object-position: 50% 0%;
  }
}

@media screen and (max-width: 768px) {
  .left-block {
    display: none;
  }
}
.left-block__btn-container {
  position: absolute;
  top: calc(var(--banner-height) - var(--header-height) - var(--alert-height));
  left: 80px;
}
@media screen and (max-width: 1024px) {
  .left-block__btn-container {
    left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .left-block__btn-container {
    display: none;
  }
}
.left-block__btn {
  background: #fff;
  color: #7f8b5d;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1), color 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.left-block__btn:hover {
  background: #7f8b5d;
  color: #fff;
}
.left-block__btn-svg {
  fill: none;
  stroke: #7f8b5d;
  transition: stroke 300ms cubic-bezier(0.76, 0, 0.24, 1);
  stroke-width: 5px;
  width: 15px;
  height: 12px;
  margin-left: 20px;
  transform: rotate(90deg);
  transition: transform cubic-bezier(0.76, 0, 0.24, 1);
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes bounce {
  0% {
    transform: translateY(0) rotate(90deg);
  }
  50% {
    transform: translateY(25%) rotate(90deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
.left-block__btn:hover .left-block__btn-svg {
  stroke: #fff;
}
.left-block__container {
  width: var(--x-padding-400);
  padding-top: 45px;
  padding-left: 80px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: var(--banner-height);
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 1024px) {
  .left-block__container {
    padding-left: 25px;
  }
}
.left-block__container--no-banner {
  padding-top: 0;
  top: var(--y-padding-90);
}

.no-similar {
  min-height: 400px;
}

:root {
  --text-block-height: 425px;
}
@media screen and (max-height: 800px) {
  :root {
    --text-block-height: 375px;
  }
}
@media screen and (max-height: 700px) {
  :root {
    --text-block-height: 275px;
  }
}
@media screen and (max-height: 600px) {
  :root {
    --text-block-height: 250px;
  }
}

.home-banner__container {
  position: relative;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height));
}
@media screen and (max-width: 900px) {
  .home-banner__container {
    height: auto;
  }
}
.home-banner__slider-container {
  padding-right: 360px;
  position: relative;
}
@media screen and (max-width: 900px) {
  .home-banner__slider-container {
    padding-right: 0;
  }
}
.home-banner__slider {
  margin-bottom: 0 !important;
  height: 100%;
}
.home-banner__slider .slick-slide {
  height: 0;
}
.home-banner__slider .slick-active {
  height: auto;
}
.home-banner__slider-slide {
  height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  min-height: calc(500px - var(--header-height));
}
@media screen and (max-width: 900px) {
  .home-banner__slider-slide {
    height: calc(var(--vh, 1vh) * 100 - var(--header-height) - 200px);
  }
}
.home-banner__content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .home-banner__content {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.home-banner__filter {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(43, 43, 43, 0.4);
}
.home-banner__picture {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}
.home-banner__text {
  padding-left: 90px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .home-banner__text {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .home-banner__text {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .home-banner__text {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.home-banner__title {
  color: #fff;
  margin-bottom: 60px;
  padding-right: 50px;
}
@media screen and (max-width: 768px) {
  .home-banner__title {
    padding-right: 0;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  .home-banner__title {
    margin-bottom: 30px;
  }
}
@media screen and (max-height: 768px) {
  .home-banner__title {
    margin-bottom: 30px;
  }
}
.home-banner__btn {
  background: #fff;
  color: #7f8b5d;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1), color 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.home-banner__btn:hover {
  background: #7f8b5d;
  color: #fff;
}
.home-banner__dots {
  position: absolute;
  bottom: 65px;
  left: 90px;
}
@media screen and (max-width: 1024px) {
  .home-banner__dots {
    left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .home-banner__dots {
    left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .home-banner__dots {
    left: 25px;
  }
}
.home-banner__dots ul {
  display: flex;
  align-items: center;
  position: relative;
  bottom: auto;
}
.home-banner__dots li {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  margin-right: 25px;
}
.home-banner__dots li button::before {
  display: none;
}
@media screen and (max-width: 500px) {
  .home-banner__dots li {
    width: 6px;
    height: 6px;
  }
}
.home-banner__dots li:last-child() {
  margin-right: 0;
}
.home-banner__dots .slick-active {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 500px) {
  .home-banner__dots .slick-active {
    width: 10px;
    height: 10px;
  }
}
.home-banner__btn-fast-links {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin-left: 0 !important;
  position: absolute;
  right: 405px;
  bottom: 50px;
}
@media screen and (max-width: 900px) {
  .home-banner__btn-fast-links {
    right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .home-banner__btn-fast-links {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .home-banner__btn-fast-links {
    right: 25px;
  }
}
.home-banner__right-block {
  width: 360px;
  height: 100%;
  min-height: calc(500px - var(--header-height));
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 900px) {
  .home-banner__right-block {
    width: 100%;
    min-height: 0;
    position: relative;
    top: auto;
    right: auto;
  }
}
.home-banner__showcase-image-slider .slick-list,
.home-banner__showcase-image-slider .slick-track,
.home-banner__showcase-image-slider .slick-slide {
  height: 100% !important;
}
.home-banner__showcase-image-slider .slick-slide > div {
  height: 100% !important;
}
.home-banner__showcase-text-slider-container {
  width: 100%;
  position: relative;
}
.home-banner__showcase-text-slider {
  height: 100%;
}
.home-banner .slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}
.home-banner__showcase-image-slider {
  height: calc(100% - var(--text-block-height));
}
@media screen and (max-width: 900px) {
  .home-banner__showcase-image-slider {
    display: none;
  }
}
.home-banner__showcase-text-slide, .home-banner__showcase-image-slide {
  height: 100%;
  width: 100%;
}
.home-banner__link {
  width: 100%;
  height: 100%;
  display: block;
}
.home-banner__text-block {
  height: var(--text-block-height);
  padding: 80px 50px;
  display: flex;
  align-items: center;
}
.home-banner__text-block--green-background {
  background: #7f8b5d;
}
.home-banner__text-block--light-blue-background {
  background: #7ba3b4;
}
.home-banner__text-block--marine-background {
  background: #1c2b49;
}
.home-banner__text-block--orange-background {
  background: #d3854f;
}
@media screen and (max-height: 800px) {
  .home-banner__text-block {
    padding: 40px;
  }
}
@media screen and (max-height: 700px) {
  .home-banner__text-block {
    padding: 35px 40px;
  }
}
@media screen and (max-width: 900px) {
  .home-banner__text-block {
    height: 200px;
    padding: 35px 50px 40px;
  }
}
@media screen and (max-width: 768px) {
  .home-banner__text-block {
    padding: 35px 30px 40px;
  }
}
@media screen and (max-width: 600px) {
  .home-banner__text-block {
    padding: 35px 25px 40px;
  }
}
@media screen and (max-width: 400px) {
  .home-banner__text-block {
    padding: 25px 25px 40px;
  }
}
.home-banner__text-block-content,
.home-banner .slickAnimate .home-banner__text-block-content {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 700ms 450ms, transform 700ms 450ms;
}
.home-banner .slick-active .home-banner__text-block-content,
.home-banner .slickAnimate .home-banner__text-block-content {
  opacity: 1;
  transform: none;
}
.home-banner__medium-title {
  color: #fff;
  margin-bottom: 25px;
}
@media screen and (max-height: 800px) {
  .home-banner__medium-title {
    margin-bottom: 15px;
  }
}
@media screen and (max-height: 700px) {
  .home-banner__medium-title {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 900px) {
  .home-banner__medium-title {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 500px) {
  .home-banner__medium-title {
    margin-bottom: 10px;
  }
}
.home-banner__btn-simple {
  color: #fff;
  pointer-events: none;
}
.home-banner__btn-simple::after {
  background: #fff;
}
.home-banner__right-block:hover .home-banner__btn-simple::after {
  width: 50%;
}
.home-banner__right-block-picture {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.home-banner__right-block-img {
  transition: transform 600ms cubic-bezier(0.25, 1, 0.5, 1);
}
.home-banner__right-block:hover .home-banner__right-block-img {
  transform: scale(1.1);
}
.home-banner__showcase-dots {
  position: absolute;
  bottom: 30px;
  left: 50px;
  z-index: 9;
}
.home-banner__showcase-dots::after {
  content: "";
  width: calc(100% + 40px);
  height: 50px;
  position: absolute;
  top: -18px;
  left: -17px;
  z-index: -1;
}
@media screen and (max-width: 500px) {
  .home-banner__showcase-dots::after {
    height: 30px;
    top: -10px;
  }
}
@media screen and (max-height: 800px) {
  .home-banner__showcase-dots {
    bottom: 20px;
  }
}
@media screen and (max-width: 900px) {
  .home-banner__showcase-dots {
    right: 50px;
    left: auto;
  }
}
@media screen and (max-width: 768px) {
  .home-banner__showcase-dots {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .home-banner__showcase-dots {
    right: 25px;
  }
}
.home-banner__showcase-dots ul {
  display: flex;
  align-items: center;
  position: relative;
  bottom: auto;
}
.home-banner__showcase-dots li {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  margin-right: 25px;
}
.home-banner__showcase-dots li button::before {
  display: none;
}
@media screen and (max-width: 500px) {
  .home-banner__showcase-dots li {
    width: 6px;
    height: 6px;
  }
}
.home-banner__showcase-dots li:last-child() {
  margin-right: 0;
}
.home-banner__showcase-dots .slick-active {
  width: 14px;
  height: 14px;
  pointer-events: none;
}
@media screen and (max-width: 500px) {
  .home-banner__showcase-dots .slick-active {
    width: 10px;
    height: 10px;
  }
}
.home-banner__showcase-dots .slick-dots li button:before {
  color: #fff;
}
.home-banner .slick-dots li button:before {
  color: #fff;
}

.show-alerts .home-banner__container {
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - var(--alert-height));
}
.show-alerts .home-banner__slider-slide {
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - var(--alert-height));
}
@media screen and (max-width: 900px) {
  .show-alerts .home-banner__container {
    height: auto;
  }
}
@media screen and (max-width: 900px) {
  .show-alerts .home-banner__slider-slide {
    height: calc(var(--vh, 1vh) * 100 - var(--header-height) - var(--alert-height) - 200px);
  }
}

.fast-links__title {
  width: 100%;
  color: #373737;
  margin-bottom: 15px;
}
.fast-links__text {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  margin-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .fast-links__text {
    margin-bottom: 30px;
  }
}
.fast-links__links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .fast-links__links {
    flex-direction: column;
    justify-content: initial;
    flex-wrap: initial;
  }
}
.fast-links__link-container {
  width: 500px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1400px) {
  .fast-links__link-container {
    width: 400px;
  }
}
@media screen and (max-width: 1100px) {
  .fast-links__link-container {
    width: 350px;
  }
}
@media screen and (max-width: 900px) {
  .fast-links__link-container {
    width: 325px;
  }
}
@media screen and (max-width: 768px) {
  .fast-links__link-container {
    width: 100%;
  }
}
.fast-links__link-container:last-child(), .fast-links__link-container:nth-last-child(2) {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .fast-links__link-container:last-child(), .fast-links__link-container:nth-last-child(2) {
    margin-bottom: 30px;
  }
}
.fast-links__link {
  display: flex;
  align-items: center;
}
.fast-links__circle {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  background: #e6eaf2;
  margin-right: 30px;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1), margin-right 300ms cubic-bezier(0.76, 0, 0.24, 1);
  position: relative;
}
@media screen and (max-width: 1100px) {
  .fast-links__circle {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  }
}
@media screen and (max-width: 900px) {
  .fast-links__circle {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
  }
}
@media screen and (max-width: 500px) {
  .fast-links__circle {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
}
.fast-links__link:hover .fast-links__circle {
  background: #7f8b5d;
  margin-right: 50px;
}
.fast-links__icon {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: none;
  stroke: #373737;
  transition: stroke 300ms cubic-bezier(0.76, 0, 0.24, 1);
  stroke-width: 1.5px;
}
@media screen and (max-width: 500px) {
  .fast-links__icon {
    width: 22px;
    height: 22px;
  }
}
.fast-links__link:hover .fast-links__icon {
  stroke: #fff;
}
.fast-links__link-text {
  color: #373737;
  font-weight: normal;
}

@media screen and (max-width: 500px) {
  .home-news__container {
    padding-bottom: 80px;
  }
}

.green-block {
  --image-width: 650px;
}
@media screen and (max-width: 1400px) {
  .green-block {
    --image-width: 625px;
  }
}
@media screen and (max-width: 1350px) {
  .green-block {
    --image-width: 600px;
  }
}
@media screen and (max-width: 1300px) {
  .green-block {
    --image-width: 575px;
  }
}
@media screen and (max-width: 1250px) {
  .green-block {
    --image-width: 550px;
  }
}
@media screen and (max-width: 1200px) {
  .green-block {
    --image-width: 525px;
  }
}
@media screen and (max-width: 1150px) {
  .green-block {
    --image-width: 500px;
  }
}
@media screen and (max-width: 1100px) {
  .green-block {
    --image-width: 475px;
  }
}
@media screen and (max-width: 1050px) {
  .green-block {
    --image-width: 450px;
  }
}
@media screen and (max-width: 1000px) {
  .green-block {
    --image-width: 425px;
  }
}
@media screen and (max-width: 950px) {
  .green-block {
    --image-width: 400px;
  }
}
.green-block__container {
  background: #7f8b5d;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 900px) {
  .green-block__container {
    padding-left: var(--x-padding-160);
    flex-direction: column;
  }
}
.green-block__left {
  padding: 90px 0;
  position: absolute;
  top: 50%;
  left: var(--x-padding-180);
  transform: translateY(-50%);
  z-index: 4;
}
@media screen and (max-width: 900px) {
  .green-block__left {
    padding: 25px 0 75px;
    position: relative;
    top: auto;
    left: auto;
    transform: translateY(0);
  }
}
.green-block__title {
  max-width: 630px;
  line-height: 1.1;
  color: #fff;
  margin-bottom: 50px;
}
@media screen and (min-width: 1440px) {
  .green-block__title {
    font-size: 5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .green-block__title {
    font-size: calc(2.5rem + (80 - 40) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .green-block__title {
    font-size: 2.5rem;
  }
}
.green-block__btn {
  background: #fff;
  color: #7f8b5d;
  transition: background 300ms cubic-bezier(0.76, 0, 0.24, 1), color 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.green-block__btn:hover {
  color: #fff;
}
.green-block__right-content {
  width: 100%;
}
.green-block__right-link-container {
  width: var(--image-width);
  position: absolute;
  top: var(--y-padding-160);
  right: var(--x-padding-160);
  bottom: var(--y-padding-160);
  z-index: 10;
  pointer-events: none;
}
@media screen and (max-width: 900px) {
  .green-block__right-link-container {
    display: none;
  }
}
.green-block__right-link {
  padding-top: 87%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: all;
}
.green-block__right-content {
  position: relative;
  overflow: hidden;
}
.green-block__picture-container {
  width: var(--image-width);
  position: relative;
}
@media screen and (max-width: 900px) {
  .green-block__picture-container {
    width: 100%;
  }
}
.green-block__picture {
  width: 100%;
  padding-top: 87%;
  position: relative;
}
.green-block__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.green-block__filter {
  background: transparent;
  background: linear-gradient(90deg, rgba(55, 55, 55, 0.7) 0%, rgba(55, 55, 55, 0) 100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.green-block__shape {
  width: 100%;
  height: auto;
  min-height: 150px;
  fill: #7f8b5d;
  position: absolute;
  bottom: -55px;
  left: 0;
  z-index: 3;
}
@media screen and (max-width: 900px) {
  .green-block__shape {
    width: calc(100% + 1px);
  }
}
@media screen and (max-width: 500px) {
  .green-block__shape {
    min-height: 125px;
    bottom: -50px;
  }
}
@media screen and (max-width: 500px) {
  .green-block__shape {
    min-height: 110px;
  }
}
.green-block__play-button {
  width: 185px;
  height: 185px;
  border: 1px solid white;
  border-radius: 50%;
  transition: background 300ms;
  position: absolute;
  top: calc(50% - 30px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
@media screen and (max-width: 1300px) {
  .green-block__play-button {
    width: 150px;
    height: 150px;
  }
}
@media screen and (max-width: 1024px) {
  .green-block__play-button {
    width: 125px;
    height: 125px;
  }
}
@media screen and (max-width: 900px) {
  .green-block__play-button {
    width: 185px;
    height: 185px;
  }
}
@media screen and (max-width: 800px) {
  .green-block__play-button {
    width: 150px;
    height: 150px;
  }
}
@media screen and (max-width: 700px) {
  .green-block__play-button {
    width: 125px;
    height: 125px;
  }
}
@media screen and (max-width: 500px) {
  .green-block__play-button {
    width: 100px;
    height: 100px;
  }
}
@media screen and (max-width: 400px) {
  .green-block__play-button {
    width: 90px;
    height: 90px;
  }
}
.green-block__right:hover .green-block__play-button {
  background: white;
}
.green-block__svg {
  fill: white;
  stroke: white;
  transition: fill 300ms, stroke 300ms;
}
.green-block__svg--play {
  width: 24px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1024px) {
  .green-block__svg--play {
    width: 19px;
    height: 27px;
  }
}
@media screen and (max-width: 900px) {
  .green-block__svg--play {
    width: 24px;
    height: 32px;
  }
}
@media screen and (max-width: 700px) {
  .green-block__svg--play {
    width: 19px;
    height: 27px;
  }
}
@media screen and (max-width: 700px) {
  .green-block__svg--play {
    width: 17px;
    height: 25px;
  }
}
.green-block__svg--i {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 0;
}
.green-block__right:hover .green-block__svg--play {
  fill: #7f8b5d;
  stroke: #7f8b5d;
}
.green-block__note {
  max-width: var(--image-width);
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: white;
  padding-left: 30px;
  position: relative;
}
@media screen and (max-width: 900px) {
  .green-block__note {
    max-width: 100%;
  }
}

.pagination {
  padding-left: var(--x-padding-400);
}
.pagination__container {
  margin-bottom: var(--y-margin-110);
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .pagination__container {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .pagination__container {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
.pagination__content {
  display: flex;
  position: relative;
}
.pagination__left-arrow, .pagination__right-arrow {
  fill: none;
  stroke: #acacac;
  stroke-width: 4px;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 20px;
}
.pagination__left-arrow {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}
.pagination__right-arrow {
  transform: translateY(-50%);
  right: 0;
}
.pagination__numbers {
  padding: 0 65px;
  display: flex;
}
.pagination__number {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #acacac;
  margin-right: 30px;
}
.pagination__number--active {
  color: #e18545;
}
.pagination__number:last-child {
  margin-right: 0;
}

.pagination-event {
  display: none;
}
@media screen and (max-width: 768px) {
  .pagination-event {
    display: block;
  }
}
.pagination-event__container {
  margin-top: 50px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media screen and (max-width: 600px) {
  .pagination-event__container {
    padding: 0 25px;
  }
}
.pagination-event__return, .pagination-event__next {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  margin-bottom: 40px;
}
.pagination-event__return:hover, .pagination-event__next:hover {
  color: #e18545;
}
.pagination-event__next {
  padding-right: 15px;
}
.pagination-event__left-arrow, .pagination-event__right-arrow {
  fill: none;
  stroke: #373737;
  transition: stroke 300ms cubic-bezier(0.76, 0, 0.24, 1);
  stroke-width: 5px;
  width: 15px;
  height: 12px;
}
.pagination-event__return:not(.no-more-page):hover .pagination-event__left-arrow {
  stroke: #e18545;
}
.pagination-event__next:not(.no-more-page):hover .pagination-event__right-arrow {
  stroke: #e18545;
}
.pagination-event__left-arrow {
  transform: rotate(180deg);
  margin-right: 10px;
}
.pagination-event__right-arrow {
  margin-left: 10px;
}
.pagination-event .no-more-page {
  color: #acacac;
  pointer-events: none;
}
.pagination-event .no-more-page svg {
  stroke: #acacac;
}
@media screen and (max-width: 500px) {
  .pagination-event .larger {
    display: none;
  }
}
.pagination-event .shorter {
  display: none;
}
@media screen and (max-width: 500px) {
  .pagination-event .shorter {
    display: block;
  }
}
.pagination-event__btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.pagination-event__btn {
  display: none;
}
@media screen and (max-width: 768px) {
  .pagination-event__btn {
    display: block;
  }
}

.pager {
  position: -webkit-sticky;
  position: sticky;
  top: 45px;
  bottom: 0;
  z-index: 10;
  align-self: flex-start;
}
.pager__container {
  width: 320px;
  border: 1px solid rgba(55, 55, 55, 0.2);
  padding: 40px 0 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .pager__container {
    width: 275px;
  }
}
.pager__return, .pager__next {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
  margin-bottom: 40px;
}
.pager__return:hover, .pager__next:hover {
  color: #e18545;
}
.pager__next {
  padding-right: 15px;
}
.pager__left-arrow, .pager__right-arrow {
  fill: none;
  stroke: #373737;
  transition: stroke 300ms cubic-bezier(0.76, 0, 0.24, 1);
  stroke-width: 5px;
  width: 15px;
  height: 12px;
}
.pager__return:not(.no-more-page):hover .pager__left-arrow {
  stroke: #e18545;
}
.pager__next:not(.no-more-page):hover .pager__right-arrow {
  stroke: #e18545;
}
.pager__left-arrow {
  transform: rotate(180deg);
  margin-right: 10px;
}
.pager__right-arrow {
  margin-left: 10px;
}
.pager__btn {
  margin-left: 0 !important;
}
.pager .no-more-page {
  color: #acacac;
  pointer-events: none;
}
.pager .no-more-page svg {
  stroke: #acacac;
}

.contact__container {
  padding-left: var(--x-padding-400);
}
.contact__content {
  background: #fff;
}
.contact__infos {
  margin-bottom: 75px;
}
.contact__phone {
  margin-bottom: 40px;
}
.contact__phone-link {
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
  font-weight: 700;
  color: #7f8b5d;
}
@media screen and (min-width: 1440px) {
  .contact__phone-link {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .contact__phone-link {
    font-size: calc(2.1875rem + (40 - 35) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .contact__phone-link {
    font-size: 2.1875rem;
  }
}
.contact__address-part-1, .contact__address-part-2, .contact__fax, .contact__email {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  color: #373737;
}
.contact__link {
  display: inline;
  padding-bottom: 3px;
  box-shadow: inset 0px -3px 0 #7f8b5d;
  transition: box-shadow 300ms;
}
.contact__link:hover {
  box-shadow: inset 0px -20px 0 rgba(127, 139, 93, 0.5);
}
.contact__address-part-2 {
  margin-bottom: 30px;
}
.contact__title, .contact h3 {
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
  font-weight: 700;
  color: #373737;
  margin-bottom: 30px;
}
@media screen and (min-width: 1440px) {
  .contact__title, .contact h3 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .contact__title, .contact h3 {
    font-size: calc(2.1875rem + (40 - 35) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .contact__title, .contact h3 {
    font-size: 2.1875rem;
  }
}
.contact__schedule-box {
  margin-bottom: 45px;
}
.contact__schedule-box:last-child {
  margin-bottom: 0;
}
.contact__place, .contact h4 {
  font-size: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}
.contact__hours {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  margin-bottom: 15px;
}
.contact__hours:last-child {
  margin-bottom: 0;
}

.result-box__container {
  padding-bottom: 40px;
  border-bottom: 1px solid #373737;
  margin-bottom: 50px;
}
.result-box__surtitle {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #373737;
  margin-bottom: 20px;
}
.result-box__title {
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  font-weight: 700;
  color: #373737;
  margin-bottom: 30px;
}
@media screen and (min-width: 1440px) {
  .result-box__title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .result-box__title {
    font-size: calc(1.25rem + (30 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .result-box__title {
    font-size: 1.25rem;
  }
}
.result-box__text {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  color: #373737;
  margin-bottom: 40px;
}
.result-box__btn {
  margin-left: 0 !important;
}

.errors {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
}
.errors__container {
  background: rgba(242, 244, 248, 0.5);
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .errors__container {
    width: 100%;
    padding: 50px;
  }
}
@media screen and (max-width: 768px) {
  .errors__container {
    width: 100%;
    padding: 50px 25px;
  }
}
.errors__home {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.errors__link {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 15px;
  transform: translateY(-2px);
}
.errors__svg {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #373737;
  stroke-width: 3px;
  transition: stroke 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
.errors__svg:hover {
  stroke: #e6eaf2;
}
.errors__title {
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
  font-weight: 700;
  text-align: center;
  padding-bottom: 50px;
}
@media screen and (min-width: 1440px) {
  .errors__title {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .errors__title {
    font-size: calc(2.1875rem + (40 - 35) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .errors__title {
    font-size: 2.1875rem;
  }
}
.errors__text {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
}
.errors__btn-home {
  margin-top: 50px;
  margin-left: 0 !important;
}

:root {
  --alert-height: 45px;
  --alerts-side-padding: 40px;
  --alerts-inside-padding: 25px;
  --alerts-left-side-width: 200px;
  --alerts-right-side-width: calc(var(--alerts-side-padding) + var(--alerts-inside-padding) + var(--alerts-close-width));
  --alerts-close-width: 110px;
  --alert-x-dimensions: 14px;
}
@media screen and (max-width: 1200px) {
  :root {
    --alerts-inside-padding: 20px;
    --alerts-left-side-width: 170px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --alerts-side-padding: 30px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --alerts-side-padding: var(--alerts-inside-padding);
    --alert-x-dimensions: 20px;
    --alerts-left-side-width: 115px;
    --alerts-right-side-width: calc(var(--alert-x-dimensions) + (var(--alerts-inside-padding) * 2));
  }
}
@media screen and (max-width: 500px) {
  :root {
    --alerts-left-side-width: 50px;
    --alerts-inside-padding: 10px;
  }
}

.alerts {
  position: absolute;
  width: 100%;
  top: calc(0px - var(--alert-height));
  left: 0;
  transition: top 300ms cubic-bezier(0.33, 1, 0.68, 1);
  z-index: 1;
}
.alerts__container {
  display: flex;
  height: var(--alert-height);
  background: #e18545;
}
.alerts__left {
  display: flex;
  align-items: center;
  padding-right: var(--alerts-inside-padding);
  padding-left: var(--alerts-side-padding);
  width: var(--alerts-left-side-width);
  height: 100%;
  font: 0.8125rem/1 "Roboto", sans-serif;
  color: #fff;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 500px) {
  .alerts__left {
    font-size: 0.75rem;
  }
}
.alerts__icon {
  width: 18px;
  height: 18px;
  fill: #fff;
}
@media screen and (max-width: 768px) {
  .alerts__icon {
    display: none;
  }
}
.alerts__counter {
  flex: 1;
  text-align: center;
}
@media screen and (max-width: 768px) and (min-width: 501px) {
  .alerts__counter {
    text-align: left;
  }
}
.alerts__prev-next {
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 30px;
  position: relative;
}
@media screen and (max-width: 500px) {
  .alerts__prev-next {
    display: none;
  }
}
.alerts__prev-next-link {
  font-size: 0;
}
.alerts__arrow {
  width: 12px;
  height: 12px;
  stroke-width: 2;
  stroke: #fff;
  fill: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.alerts__arrow-prev {
  transform: translateY(-45%) rotate(180deg);
  margin-right: 20px;
}
.alerts__slider {
  display: flex;
  align-items: center;
  width: calc(100% - var(--alerts-left-side-width) - var(--alerts-right-side-width));
  padding: 0 var(--alerts-inside-padding);
}
.alerts__slider .slick-track, .alerts__slider .slick-slide {
  font-size: 0;
}
.alerts__text {
  display: block;
  margin-bottom: 0;
  width: 100%;
  font: 1rem/1 "Roboto", sans-serif;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 500px) {
  .alerts__text {
    font-size: 0.8125rem;
  }
}
.alerts__link {
  opacity: 1;
  transition: opacity 300ms;
  height: var(--alert-height);
  display: flex;
  align-items: center;
}
.alerts__link:hover {
  opacity: 0.6;
}
.alerts__right {
  display: flex;
  align-items: center;
  width: var(--alerts-right-side-width);
  height: 100%;
  padding-right: var(--alerts-side-padding);
  padding-left: var(--alerts-inside-padding);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.alerts__close {
  display: flex;
  align-items: center;
  width: var(--alerts-close-width);
}
.alerts__close-text {
  white-space: nowrap;
  position: relative;
  margin-right: 20px;
  font: 700 0.8125rem/1 "Roboto", sans-serif;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .alerts__close-text {
    display: none;
  }
}
.alerts__close-text:after {
  content: "";
  position: absolute;
  height: 1px;
  right: 0;
  bottom: -2px;
  left: 0;
  background: #fff;
  opacity: 0;
  transition: opacity 300ms;
}
.alerts__close-x {
  position: relative;
  width: var(--alert-x-dimensions);
  height: var(--alert-x-dimensions);
  transition: opacity 300ms;
}
.alerts__close-x:before, .alerts__close-x:after {
  content: "";
  position: absolute;
  width: 2px;
  height: var(--alert-x-dimensions);
  top: 50%;
  left: 50%;
  background: #fff;
}
.alerts__close-x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.alerts__close-x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.alerts__close:hover .alerts__close-text:after {
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .alerts__close:hover .alerts__close-x {
    opacity: 0.6;
  }
}

.first-loading {
  display: none;
}

.default-transition {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.default-transition__container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

:root {
  --scrollfire-fade-up__opacity: 0;
  --scrollfire-fade-up__transform: translateY(50px);
  --scrollfire-fade-up__transition: opacity 800ms, transform 800ms cubic-bezier(.215,.61,.355,1);
  --scrollfire-fade-up__opacity-fire: 1;
  --scrollfire-fade-up__transform-fire: none;
}
@media screen and (max-width: 1024px) {
  :root {
    --scrollfire-fade-up__transform: translateY(25px);
  }
}

.js-scrollfire.fade-up,
.js-premiumScrollfire > .fade-up {
  opacity: var(--scrollfire-fade-up__opacity);
  transform: var(--scrollfire-fade-up__transform);
  transition: var(--scrollfire-fade-up__transition);
}

.js-scrollfire[data-fire].fade-up,
.js-premiumScrollfire[data-fire] > .fade-up {
  opacity: var(--scrollfire-fade-up__opacity-fire);
  transform: var(--scrollfire-fade-up__transform-fire);
}

.js-scrollfire.curtain-top, .js-premiumScrollfire.curtain-top,
.js-scrollfire.curtain-right, .js-premiumScrollfire.curtain-right {
  overflow: hidden;
}
.js-scrollfire.curtain-top:after, .js-premiumScrollfire.curtain-top:after,
.js-scrollfire.curtain-right:after, .js-premiumScrollfire.curtain-right:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 10;
}
.js-scrollfire.curtain-top img, .js-premiumScrollfire.curtain-top img,
.js-scrollfire.curtain-right img, .js-premiumScrollfire.curtain-right img {
  transform: scale(1.1);
  transition: transform 1000ms cubic-bezier(0.83, 0, 0.17, 1);
}
.js-scrollfire.curtain-top[data-fire] img, .js-premiumScrollfire.curtain-top[data-fire] img,
.js-scrollfire.curtain-right[data-fire] img, .js-premiumScrollfire.curtain-right[data-fire] img {
  transform: none;
}

.js-scrollfire.curtain-top:after, .js-premiumScrollfire.curtain-top:after {
  transition: top 1000ms cubic-bezier(0.83, 0, 0.17, 1);
}
.js-scrollfire.curtain-top[data-fire]:after, .js-premiumScrollfire.curtain-top[data-fire]:after {
  top: 100%;
}

.js-scrollfire.curtain-right:after, .js-premiumScrollfire.curtain-right:after {
  transition: right 1000ms cubic-bezier(0.83, 0, 0.17, 1);
}
.js-scrollfire.curtain-right[data-fire]:after, .js-premiumScrollfire.curtain-right[data-fire]:after {
  right: 100%;
}

.js-scrollfire-delay-enabled .delay1, .js-scrollfire-delay-enabled .delay1:after, .js-scrollfire-delay-enabled .delay1 > img {
  transition-delay: 400ms;
}
.js-scrollfire-delay-enabled .delay2, .js-scrollfire-delay-enabled .delay2:after, .js-scrollfire-delay-enabled .delay2 > img {
  transition-delay: 500ms;
}
.js-scrollfire-delay-enabled .delay3, .js-scrollfire-delay-enabled .delay3:after, .js-scrollfire-delay-enabled .delay3 > img {
  transition-delay: 600ms;
}
.js-scrollfire-delay-enabled .delay4, .js-scrollfire-delay-enabled .delay4:after, .js-scrollfire-delay-enabled .delay4 > img {
  transition-delay: 700ms;
}
.js-scrollfire-delay-enabled .delay5, .js-scrollfire-delay-enabled .delay5:after, .js-scrollfire-delay-enabled .delay5 > img {
  transition-delay: 800ms;
}
.js-scrollfire-delay-enabled .delay6, .js-scrollfire-delay-enabled .delay6:after, .js-scrollfire-delay-enabled .delay6 > img {
  transition-delay: 900ms;
}
.js-scrollfire-delay-enabled .delay7, .js-scrollfire-delay-enabled .delay7:after, .js-scrollfire-delay-enabled .delay7 > img {
  transition-delay: 1000ms;
}

.overlay-menu {
  position: fixed;
  top: calc(var(--header-height));
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 10;
}
.overlay-menu__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 800ms;
  z-index: -1;
}
.overlay-menu__container {
  position: absolute;
  width: 520px;
  padding: 60px 100px;
  top: 0;
  right: -500px;
  bottom: 0;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  transition: right 800ms cubic-bezier(0.65, 0, 0.35, 1);
}
@media screen and (max-width: 600px) {
  .overlay-menu__container {
    width: 100%;
    right: -100%;
    padding-right: var(--x-padding-70);
    padding-left: var(--x-padding-70);
  }
}
.overlay-menu__container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
  background-color: transparent;
}
.overlay-menu__container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}
.overlay-menu__main-level {
  opacity: 0;
  transform: translateX(200px);
  transition: opacity 1000ms cubic-bezier(0.65, 0, 0.35, 1), transform 1000ms cubic-bezier(0.65, 0, 0.35, 1);
}
.overlay-menu__main-level-li {
  padding-bottom: 50px;
}
.overlay-menu__main-level-button {
  padding-right: 30px;
  display: block;
  position: relative;
  pointer-events: none;
}
.overlay-menu__main-level-title {
  font: 1.875rem/1 "Roboto", sans-serif;
  color: #373737;
  transition: color 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
@media screen and (max-width: 600px) {
  .overlay-menu__main-level-title {
    font-size: 1.625rem;
  }
}
@media screen and (max-width: 425px) {
  .overlay-menu__main-level-title {
    font-size: 1.5rem;
  }
}
.overlay-menu__main-level-button:hover .overlay-menu__main-level-title {
  color: #e18545;
}
.overlay-menu__main-level-svg, .overlay-menu__second-level-svg {
  fill: none;
  stroke: #373737;
  transition: stroke 300ms cubic-bezier(0.76, 0, 0.24, 1);
  stroke-width: 5px;
  position: absolute;
  top: 50%;
  right: 0;
  width: 10px;
  height: 15px;
  transform: translateY(-50%) rotate(90deg);
  transition: transform 700ms cubic-bezier(0.65, 0, 0.35, 1);
}
.overlay-menu__main-level-svg {
  display: none;
}
.overlay-menu__second-level-svg {
  width: 14px;
}
.overlay-menu__main-level-button:hover .overlay-menu__main-level-svg {
  stroke: #e18545;
}
.overlay-menu__second-level {
  height: 100%;
  overflow: hidden;
  opacity: 1;
  margin-top: 30px;
  transition: opacity 700ms cubic-bezier(0.65, 0, 0.35, 1), margin 700ms cubic-bezier(0.65, 0, 0.35, 1);
}
.overlay-menu__second-level-li {
  padding: 15px 0;
  transition: padding 700ms cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;
}
.overlay-menu__second-level-li::after {
  content: "";
  width: 100%;
  height: 1px;
  opacity: 1;
  transition: opacity 1200ms cubic-bezier(0.76, 0, 0.24, 1);
  background: #373737;
  position: absolute;
  left: 0;
  bottom: 0;
}
.overlay-menu__second-level-li:first-child {
  padding-top: 0;
}
.overlay-menu__second-level-button {
  display: block;
  font: 1.25rem/1.5 "Roboto", sans-serif;
  color: #373737;
  padding-right: 30px;
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.76, 0, 0.24, 1);
  position: relative;
}
.overlay-menu__second-level-button:hover {
  opacity: 0.5;
}
.overlay-menu__third-level {
  height: 0;
  overflow: hidden;
  opacity: 0;
  margin-top: 0px;
  transition: opacity 700ms cubic-bezier(0.65, 0, 0.35, 1), margin 700ms cubic-bezier(0.65, 0, 0.35, 1);
}
.overlay-menu__third-level-li {
  padding-right: 30px;
  padding-bottom: 15px;
}
.overlay-menu__third-level-li:last-child {
  padding-bottom: 20px;
}
.overlay-menu__third-level-link {
  display: block;
  font: 1rem/1.5 "Roboto", sans-serif;
  color: #373737;
  transition: padding 300ms cubic-bezier(0.76, 0, 0.24, 1);
}
@media screen and (max-width: 600px) {
  .overlay-menu__third-level-link {
    font-size: 1rem;
  }
}
@media screen and (max-width: 425px) {
  .overlay-menu__third-level-link {
    font-size: 0.9375rem;
  }
}
.overlay-menu__third-level-link:hover {
  padding-left: 20px;
}

.show-alerts .overlay-menu {
  top: calc(var(--header-height) + var(--alert-height));
}

.overlay-search {
  position: absolute;
  width: 0%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10;
}
.overlay-search__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 800ms cubic-bezier(0.65, 0, 0.35, 1);
  z-index: -1;
}
.overlay-search__container {
  position: absolute;
  width: 100%;
  height: 0px;
  top: 0;
  left: 0;
  background: #fff;
  overflow: hidden;
}
.overlay-search__form {
  opacity: 0;
  transform: translateY(100px);
  transition: transform 800ms cubic-bezier(0.65, 0, 0.35, 1), opacity 800ms cubic-bezier(0.65, 0, 0.35, 1);
  padding-top: 25px;
  padding-bottom: 35px;
}

.overlay-calendar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 20;
}
.overlay-calendar::-webkit-scrollbar {
  display: none;
}
.overlay-calendar__close {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 100px;
  right: 50px;
  cursor: pointer;
}
.overlay-calendar__close:hover:before, .overlay-calendar__close:hover:after {
  background: #fff;
}
.overlay-calendar__close:before, .overlay-calendar__close:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #fff;
  transition: background 300ms;
}
.overlay-calendar__close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.overlay-calendar__close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
@media screen and (max-width: 768px) {
  .overlay-calendar__close {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .overlay-calendar__close {
    right: 25px;
  }
}
.overlay-calendar__container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  text-align: center;
  z-index: 5;
  pointer-events: none;
  transition: opacity 800ms cubic-bezier(0.25, 1, 0.5, 1);
}
.overlay-calendar__content {
  width: 100%;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 800ms cubic-bezier(0.25, 1, 0.5, 1), opacity 800ms cubic-bezier(0.25, 1, 0.5, 1);
  position: absolute;
  top: 100px;
}
.overlay-calendar__calendar {
  padding: 30px 50px;
}
@media screen and (max-width: 768px) {
  .overlay-calendar__calendar {
    padding: 30px;
  }
}
@media screen and (max-width: 600px) {
  .overlay-calendar__calendar {
    padding: 30px 25px;
  }
}
.overlay-calendar__calendar .day {
  height: 50px;
}

.overlay-share {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 15;
}
.overlay-share__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 800ms;
  z-index: -1;
}
.overlay-share__container {
  position: absolute;
  padding: 85px;
  width: 520px;
  top: 50%;
  left: 50%;
  background: #fff;
  text-align: center;
  opacity: 0;
  z-index: 151;
  pointer-events: none;
  transform: translateX(-50%);
  transition: opacity 800ms cubic-bezier(0.65, 0, 0.35, 1), transform 800ms cubic-bezier(0.65, 0, 0.35, 1);
}
@media screen and (max-width: 1200px) {
  .overlay-share__container {
    width: 490px;
  }
}
@media screen and (max-width: 1024px) {
  .overlay-share__container {
    width: 400px;
    padding: 50px;
  }
}
@media screen and (max-width: 600px) {
  .overlay-share__container {
    width: auto;
    right: 20px;
    left: 20px;
    padding: 50px 20px;
    transform: none;
  }
}
.overlay-share__title {
  margin-bottom: 20px;
}
.overlay-share__close {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.overlay-share__close:hover:before, .overlay-share__close:hover:after {
  background: #898989;
}
.overlay-share__close:before, .overlay-share__close:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #373737;
  transition: background 300ms;
}
.overlay-share__close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.overlay-share__close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.overlay-share__list {
  position: relative;
  margin-top: 16px;
}
.overlay-share__list-li {
  display: inline-block;
}
.overlay-share__list-li:not(:last-child) {
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  .overlay-share__list-li:not(:last-child) {
    margin-right: 10px;
  }
}
.overlay-share__link {
  position: relative;
  width: 45px;
  height: 45px;
  background: #373737;
  border-radius: 50%;
  transition: background 300ms;
}
.overlay-share__link:hover {
  background: #898989;
}
.overlay-share__svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  fill: #fff;
  transform: translate(-50%, -50%);
}

/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {
  background: #fff;
  border-bottom: 5px solid #7f8b5d;
  transition: border 700ms cubic-bezier(0.65, 0, 0.35, 1);
}
.rubric.js-accordion-opened .rubric__title {
  color: #7f8b5d;
}
.rubric.js-accordion-opened .rubric__icon {
  transform: translateY(-50%) rotate(-45deg);
  will-change: transform;
}
.rubric.js-accordion-opened .rubric__icon-lines {
  background: #7f8b5d;
}
.rubric.js-accordion-opened .rubric__transition {
  opacity: 1;
  transform: none;
  transition: opacity 700ms cubic-bezier(0.65, 0, 0.35, 1) 100ms, transform 700ms cubic-bezier(0.65, 0, 0.35, 1) 100ms;
}
.rubric.js-accordion-opened .rubric--plus__surtitle {
  color: #7f8b5d;
}
.rubric.js-accordion-opened .rubric--plus__text--no-image {
  padding: 13px 0 0 0;
}

/*
|--------------------------------------------------------------------------
| RUBRIQUE FERMÉ
|--------------------------------------------------------------------------
*/
.rubric:not(.js-accordion-opened) iframe {
  pointer-events: none;
}

/*
|--------------------------------------------------------------------------
| SELECT DROPDOWN ACTIF
|--------------------------------------------------------------------------
*/
.field--select .tail-select.active .select-label {
  background: #373737;
  border-bottom: #fff;
}
.field--select .tail-select.active .label-inner {
  color: #898989;
}
.field--select .tail-select.active .label-inner:before {
  transform: translateY(-50%) rotate(-90deg);
  background: url("../medias/images/icons/chevron-select-actif.svg") no-repeat center/contain;
}
.field--select .tail-select.active .select-dropdown {
  opacity: 1;
  top: 100%;
  visibility: visible;
  pointer-events: all;
  transition: top 500ms cubic-bezier(0.76, 0, 0.24, 1), opacity 500ms cubic-bezier(0.76, 0, 0.24, 1);
}

.rubric .field--select .tail-select.active .select-label {
  background: #e18545;
  border-color: #e18545;
}

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-menu .header-mobile {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.show-overlay-menu .btn-menu__line1 {
  width: 0%;
  top: 16px;
  left: 50%;
  opacity: 0;
}
.show-overlay-menu .btn-menu__line2 {
  transform: rotate(45deg);
  width: 100%;
}
.show-overlay-menu .btn-menu__line3 {
  transform: rotate(-45deg);
  width: 100%;
}
.show-overlay-menu .btn-menu__line4 {
  width: 0%;
  top: 16px;
  left: 50%;
  opacity: 0;
}
.show-overlay-menu .overlay-menu {
  visibility: visible;
  pointer-events: all;
}
.show-overlay-menu .overlay-menu__background {
  background: rgba(0, 0, 0, 0.4);
}
.show-overlay-menu .overlay-menu__container {
  right: 0px;
}
@media screen and (max-width: 600px) {
  .show-overlay-menu .overlay-menu__container {
    right: 0%;
  }
}
.show-overlay-menu .overlay-menu__main-level {
  opacity: 1;
  transform: none;
}

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-menu.closing-overlay-menu .btn-menu__line1 {
  width: 100%;
  top: 0px;
  left: 0%;
  opacity: 1;
}
.show-overlay-menu.closing-overlay-menu .btn-menu__line2, .show-overlay-menu.closing-overlay-menu .btn-menu__line3 {
  top: calc(50% - 1px);
  transform: none;
}
.show-overlay-menu.closing-overlay-menu .btn-menu__line4 {
  width: 100%;
  bottom: 0px;
  left: 0%;
  opacity: 1;
}
.show-overlay-menu.closing-overlay-menu .overlay-menu__background {
  background: rgba(0, 0, 0, 0);
}
.show-overlay-menu.closing-overlay-menu .overlay-menu__container {
  right: -500px;
}
@media screen and (max-width: 600px) {
  .show-overlay-menu.closing-overlay-menu .overlay-menu__container {
    right: -100%;
  }
}
.show-overlay-menu.closing-overlay-menu .overlay-menu__main-level {
  opacity: 0;
  transform: translateX(200px);
}

/*
|--------------------------------------------------------------------------
| SOUS-MENU OUVERT
|--------------------------------------------------------------------------
*/
.overlay-menu .js-accordion-opened-main-level .overlay-menu__main-level-svg {
  transform: translateY(-50%) rotate(-90deg);
}
.overlay-menu .js-accordion-opened-main-level .overlay-menu__main-level-svg {
  stroke: #e18545;
}
.overlay-menu .js-accordion-opened-main-level .overlay-menu__main-level-title {
  color: #e18545;
}
.overlay-menu .js-accordion-opened-second-level .overlay-menu__second-level-svg {
  transform: translateY(-50%) rotate(-90deg);
}
.js-accordion-opened-second-level .overlay-menu__third-level {
  opacity: 1;
}
.js-accordion-opened-second-level .overlay-menu__third-level:nth-child(2) {
  margin-top: 20px;
}

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-search .btn-search__x {
  opacity: 1;
}
.show-overlay-search .btn-search__svg {
  opacity: 0;
}
.show-overlay-search .overlay-search {
  width: 100%;
  border-top: solid 1px #e6eaf2;
  top: var(--header-height);
  bottom: 0;
}
.show-overlay-search .overlay-search__background {
  background: rgba(0, 0, 0, 0.4);
}
.show-overlay-search .overlay-search__form {
  opacity: 1;
  transform: none;
}

.show-alerts .overlay-search {
  top: calc(var(--header-height) + var(--alert-height));
}

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-search.closing-overlay-search .btn-search__x {
  opacity: 0;
}
.show-overlay-search.closing-overlay-search .btn-search__svg {
  opacity: 1;
}
.show-overlay-search.closing-overlay-search .overlay-search__background {
  background: rgba(0, 0, 0, 0);
}
.show-overlay-search.closing-overlay-search .overlay-search__form {
  opacity: 0;
  transform: translateY(100px);
}

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-calendar .overlay-calendar {
  pointer-events: all;
  visibility: visible;
}
.show-overlay-calendar .overlay-calendar__container {
  opacity: 1;
  pointer-events: all;
}
.show-overlay-calendar .overlay-calendar__content {
  opacity: 1;
  transform: none;
}

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-calendar.closing-overlay-calendar .overlay-calendar__container {
  opacity: 0;
}
.show-overlay-calendar.closing-overlay-calendar .overlay-calendar__content {
  opacity: 0;
  transform: translateY(100px);
}

/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-share .overlay-share {
  pointer-events: all;
  visibility: visible;
}
.show-overlay-share .overlay-share__background {
  background: rgba(0, 0, 0, 0.4);
}
.show-overlay-share .overlay-share__container {
  opacity: 1;
  pointer-events: all;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  .show-overlay-share .overlay-share__container {
    transform: translateY(-50%);
  }
}

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-share.closing-overlay-share .overlay-share__background {
  background: rgba(0, 0, 0, 0);
}
.show-overlay-share.closing-overlay-share .overlay-share__container {
  opacity: 0;
  transform: translateX(-50%);
}
@media screen and (max-width: 600px) {
  .show-overlay-share.closing-overlay-share .overlay-share__container {
    transform: none;
  }
}